import React, { useCallback, useEffect, useMemo, useState } from "react";
import Layout from "../../../Components/Layout";
import { useNavigate, useParams } from "react-router-dom";
import ProTextInput from "../../../Components/Inputs/TextField";
import { useForm } from "react-hook-form";
import { Grid } from "@mui/material";
import SettingFormsLayout from "../../../Components/ProForms/ProFormsLayout/SettingFormsLayout";
import ProSelectField from "../../../Components/Inputs/SelectField";
import {
  changeMode,
  changeModeForField,
  checkEditEnabled,
  fieldTruePermission,
  prepareDefaultValues,
  prepareInitialConfig
} from "../../../../utils/formHelper";
import { getApi, postApi } from "../../../../utils/services";
import { ADD_LEAD_SUBSOURCE, DYNAMIC_DROPDOWN, GET_LEAD_SUBSOURCE_ID, UPDATE_LEAD_SUBSOURCE } from "../../../../utils/services/apiPath";
import { errorToast, successToast } from "../../../../utils/toastHelper";
import { STAUTES } from "../../../../utils/constant";

export const leadSubSourceFormConfig = {
  leadSourceTypeId: {
    label: "Lead Source Tpye",
    perKey: "leadSourceTypeId",
    rules: { required: "Lead Source is required!" },
  },
  leadSubSourceType: {
    label: "Lead Sub Source",
    rules: { required: "Lead Sub Source is required!" },
    perKey: "leadSubSourceType"
  }
};

export default function LeadSourceForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const isAddMode = useMemo(() => id === "add", [id]);
  const [status, setStatus] = useState(STAUTES.IDLE);
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(leadSubSourceFormConfig, isAddMode ? "edit" : "read"));
  const { handleSubmit, control, setValue } = useForm({ defaultValues: prepareDefaultValues(leadSubSourceFormConfig) });
  const [leadSubSourceData, setLeadSubSourceData] = useState({});
  const [leadSourceType, setLeadSourceType] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const isEditModeOn = useMemo(() => checkEditEnabled(isAddMode, formConfig), [isAddMode, formConfig]);

  const backAction = () => navigate("/settings/lead-subsource-type");

  const getDetailById = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const { data, error } = await getApi(`${GET_LEAD_SUBSOURCE_ID}/${id}`);
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setLeadSubSourceData(data);
    Object.keys(leadSubSourceFormConfig).forEach(field => setValue(field, data[field]));
  }, [setValue, id]);

  const onEdit = () => {
    setFormConfig(changeMode("edit"));
  };

  const onCancel = () => {
    if (isAddMode) backAction();
    else setFormConfig(changeMode());
  };

  const handleReadValueclick = name => setFormConfig(changeModeForField(name, "edit"));

  const onSubmit = async data => {
    if (isSaving) return; 
    setIsSaving(true);
    const payload = {
      ...leadSubSourceData,
      ...data
    };
    setStatus(STAUTES.LOADING);
    const { error } = await postApi(isAddMode ? ADD_LEAD_SUBSOURCE : UPDATE_LEAD_SUBSOURCE, payload);
    setStatus(STAUTES.IDLE);
    setIsSaving(false); 
    if (error) return errorToast(error);
    successToast(`Lead Sub Source ${isAddMode ? "added" : "updated"} successfully.`);
    backAction();
  };

  useEffect(() => {
    if (!isAddMode) getDetailById(id);
  }, [id, isAddMode, getDetailById]);

function filterItem(item) {
  return item.value !== "00000000-0000-0000-0000-000000000000";
}

function mapSourceType(sourceType) {
  return {
    ...sourceType,
    typeDropDowns: sourceType.typeDropDowns.filter(filterItem)
  };
}

function mapLeadSourceType(filteredData) {
  return filteredData[0]?.typeDropDowns?.map(item => ({ label: item.label, value: item.value }));
}

useEffect(() => {
  const getLeadSource = async () => {
    const payload = ["CLIENTSOURCETYPE"];
    const data = await postApi(DYNAMIC_DROPDOWN, payload);
    const filteredData = data.map(mapSourceType);
    setLeadSourceType(mapLeadSourceType(filteredData));
  };
  getLeadSource();
}, []);


  const defFormProps = { control, formValues: leadSubSourceData, isAddMode, handleReadValueclick };
  return (
    <Layout status={status} canView={true}>
      <SettingFormsLayout
        backAction={backAction}
        title={`${isAddMode ? "Add" : "Edit"} Lead Sub Source`}
        backLabel="Go Back to All Lead Sub Source"
        beingEdited={isEditModeOn}
        hideEdit={false}
        onEdit={onEdit}
        onCancel={onCancel}
        onSave={handleSubmit(onSubmit)}
        isSaving={isSaving}
      >
        <Grid container spacing={2}>
          <ProSelectField {...defFormProps} {...formConfig.leadSourceTypeId} permission={fieldTruePermission} options={leadSourceType} />
          <ProTextInput {...defFormProps} {...formConfig.leadSubSourceType} permission={fieldTruePermission} />
        </Grid>
      </SettingFormsLayout>
    </Layout>
  );
}
