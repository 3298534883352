import React from "react";
import Header from "../ModuleComponent/Header/Header";
import Loading from "../Loading";
import { STAUTES } from "../../../utils/constant";
import { useSelector } from "react-redux";
import { allPermissionSelector } from "../../store/features/permissions/permissionsSlice";
import { ErrorBox } from "../Helpers/ErrorBox";
import PropTypes from "prop-types";

export default function Layout({ children, status, canView = true }) {
  const { permissionStatus, permissionLoaded } = useSelector(allPermissionSelector);

  if (permissionStatus === STAUTES.ERROR) return <ErrorBox message="Something went wrong. Please try again later." />;
  if (!permissionLoaded) return <Loading />;
  return (
    <>
      {permissionStatus === STAUTES.LOADING && <Loading />}
      <Header />
      {canView ? children : <ErrorBox message="You don't have permission to view this page." />}
      {status === STAUTES.LOADING && <Loading />}
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired, 
  status: PropTypes.string,
  canView: PropTypes.bool,
};