import React, { useCallback, useEffect, useMemo, useState } from "react";
import { TableRow, TableCell, CircularProgress, Typography } from "@mui/material";
import ProjectCertifiedPayrollTableRow from "./ProjectCertifiedPayrollTableRow"; // Adjust the import path as necessary
import Loading from "../../../Components/Loading";
import { postApi } from "../../../../utils/services";
import {
  GET_PROJECT_EMPLOYEE_DAILY_CERTIFIED_PAYROLL,
  GET_PROJECT_EMPLOYEE_DAILY_PAYROLL,
  GET_PROJECT_EMPLOYEE_DAILY_TIMESHEET
} from "../../../../utils/services/apiPath";
import { formatCurrency } from "../../../Components/ProGrid/components/FormatCell";
import DownloadCell from "../../../Components/ProGrid/components/DownloadCell";
import PropTypes from "prop-types";


const ProjectCertifiedPayrollTable = ({
  id,
  loading,
  weeklyReportData,
  projectCertifiedPayrollPermission,
  STAUTES,
  setPageState,
  status,
  setStatus,
  setPagination,
  searchString,
  setSearchString,
  sortModel,
  setSortModel,
  rowCount,
  pageSize,
  currentPage,
  tokenData,
  dailyTimesheet
}) => {
  const [expandedRowId, setExpandedRowId] = useState(null);
  const [colValue, setColValue] = useState(null);
  const [isAssigned, setIsAssigned] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [selectedVal, setSelectedVal] = useState({
    id: 1,
    bool: true
  });
  const [activeDailySheet, setActiveDailySheet] = useState({ name: "", date: "" });

  const handleStatus = () => {
    if (status) {
      return <Loading />;
    } else {
      return <Typography>No Data available</Typography>;
    }
  };

  const CurrencyCell = value => {
    const formatedValue = useMemo(() => {
      const numberValue = value ?? "N/A";
      if (isNaN(numberValue)) return numberValue;
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
      }).format(numberValue);
    }, [value]);
    return formatedValue;
  };

  const rowData = dailyTimesheet?.map((item, index) => ({
    ...item,
    id: index + 1,
    overTimePayRate: formatCurrency(item?.overTimePayRate),
    regularPayRate: formatCurrency(item?.regularPayRate),
    totalPay: formatCurrency(item?.totalPay),
    standardPayRate: formatCurrency(item?.standardPayRate),
    otPayRate: formatCurrency(item?.otPayRate),
    perDiem: formatCurrency(item?.perDiem),
    regularTotal: formatCurrency(item?.regularTotal),
    overTimeTotal: formatCurrency(item?.overTimeTotal)
  }));

  const handleDailyTimeSheet = useCallback(
    async (row, valid, val) => {
      setSelectedRow(row);
      setSelectedVal({ id: 1, bool: valid });
      setActiveDailySheet({ name: "TimeSheet", date: row?.weekEndingDate });
      if (id) {
        setStatus(STAUTES.LOADING);
        const payload = {
          pageIndex: val || (searchString ? 1 : currentPage + 1),
          pageSize: pageSize,
          searchString: searchString,
          orderByAscending: false,
          orderCol: "workedDate",
          ...sortModel
        };
        let { data, totalRecords, error } = await postApi(
          `${GET_PROJECT_EMPLOYEE_DAILY_TIMESHEET}?projectId=${id}&weekEndingDate=${row?.weekEndingDate}&isValid=${valid}&clientGuid=${row?.clientGuid}`,
          payload
        );
        data = data === null ? null : data;
        const formattedData = data?.map((item, index) => ({ ...item, id: index + 1 }));
        setStatus(STAUTES.IDLE);
        if (error) return errorToast(error);
        setPageState(prevPageInfo => ({ ...prevPageInfo, data: formattedData || [], rowCount: totalRecords }));
      }
    },
    [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]
  );

  const handleDailyPayroll = useCallback(
    async (row, valid, val) => {
      setSelectedRow(row);
      setSelectedVal({ id: 2, bool: valid });
      setActiveDailySheet({ name: "Payroll Summary", date: row.weekEndingDate });
      if (id) {
        setStatus(STAUTES.LOADING);
        const payload = {
          pageIndex: val || (searchString ? 1 : currentPage + 1),
          pageSize: pageSize,
          searchString: searchString,
          orderByAscending: false,
          orderCol: "weekEndingDate",
          ...sortModel
        };
        let { data, totalRecords, error } = await postApi(
          `${GET_PROJECT_EMPLOYEE_DAILY_PAYROLL}?projectId=${id}&weekEndingDate=${row?.weekEndingDate}&isValid=${valid}&clientGuid=${row?.clientGuid}`,
          payload
        );
        data = data === null ? null : data;
        const formattedData = data?.map((item, index) => ({ ...item, id: index + 1 }));
        setStatus(STAUTES.IDLE);
        if (error) return errorToast(error);
        setPageState(prevPageInfo => ({ ...prevPageInfo, data: formattedData || [], rowCount: totalRecords }));
      }
    },
    [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]
  );

  const handleDailyCertifiedPayroll = useCallback(
    async (row, valid, val) => {
      setSelectedRow(row);
      setSelectedVal({ id: 3, bool: valid });
      setActiveDailySheet({ name: "Certified Payroll", date: row.weekEndingDate });
      if (id) {
        setStatus(STAUTES.LOADING);
        const payload = {
          pageIndex: val || (searchString ? 1 : currentPage + 1),
          pageSize: pageSize,
          searchString: searchString,
          orderByAscending: false,
          orderCol: "weekEndDate",
          ...sortModel
        };
        let { data, totalRecords, error } = await postApi(
          `${GET_PROJECT_EMPLOYEE_DAILY_CERTIFIED_PAYROLL}?projectId=${id}&weekEndingDate=${row?.weekEndingDate}&isSigned=${valid}&isManual=${isAssigned}&clientGuid=${row?.clientGuid}`,
          payload
        );

        data = data === null ? null : data;
        const formattedData = data?.map((item, index) => ({ ...item, id: index + 1 }));
        setStatus(STAUTES.IDLE);
        if (error) return errorToast(error);
        setPageState(prevPageInfo => ({ ...prevPageInfo, data: formattedData || [], rowCount: totalRecords }));
      }
    },
    [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]
  );

  const handleDownload = params => {
    const file = params.row.path;
    const link = document.createElement("a");
    link.href = file;
    link.target = "_blank";
    link.rel = "noopener noreferrer";
    link.click();
  };

  useEffect(() => {
    if (selectedRow && selectedVal) {
      if (selectedVal?.id === 1) {
        handleDailyTimeSheet(selectedRow, selectedVal?.bool);
      } else if (selectedVal?.id === 2) {
        handleDailyPayroll(selectedRow, selectedVal?.bool);
      } else {
        handleDailyCertifiedPayroll(selectedRow, selectedVal?.bool);
      }
    }
  }, [currentPage]);

  useEffect(() => {
    if (sortModel && selectedRow) {
      if (selectedVal?.id === 1) {
        handleDailyTimeSheet(selectedRow, selectedVal?.bool, 1);
      } else if (selectedVal?.id === 2) {
        handleDailyPayroll(selectedRow, selectedVal?.bool, 1);
      } else if (selectedVal?.id === 3) {
        handleDailyCertifiedPayroll(selectedRow, selectedVal?.bool, 1);
      }
    }
  }, [sortModel]);

  const columns = dailyTimesheet?.[0]
    ? Object.keys(dailyTimesheet[0])
        .filter(key => !["id", "payrollTimeSheetId", "partnerName", "payrollNumber", "employeeId"].includes(key))
        .map(key => {
          let headerName = key.charAt(0).toUpperCase() + key.slice(1);
          if (key === "otPayRate") {
            headerName = "OT Pay Rate";
          }
          let modifiedHeaderName = headerName.replace(/([a-z])([A-Z])/g, "$1 $2").replace(/([A-Z]+)([A-Z][a-z])/g, "$1 $2");
          let columnDefinition = { field: key, headerName: modifiedHeaderName };
          if (key === "path") {
            columnDefinition.renderCell = params => <DownloadCell params={params} onOpen={() => handleDownload(params)} />;
          }

          if (
            [
              "perDiem",
              "otPayRate",
              "totalStandardHours",
              "totalOTHours",
              "standardPayRate",
              "regularHours",
              "regularTotal",
              "overTimeHours",
              "totalHours"
            ].includes(key)
          ) {
            columnDefinition.align = "center";
            columnDefinition.headerAlign = "center";
          }
          return columnDefinition;
        })
    : [];

  const pathColumnIndex = columns.findIndex(column => column.field === "path");
  if (pathColumnIndex !== -1) {
    const pathColumn = columns.splice(pathColumnIndex, 1)[0];
    columns.push(pathColumn);
  }

  function renderContent() {
    if (loading) {
      return (
        <TableRow>
          <TableCell colSpan={12} align="center">
            <CircularProgress />
          </TableCell>
        </TableRow>
      );
    } else if (weeklyReportData?.length === 0) {
      return (
        <TableRow>
          <TableCell className="no-data" colSpan={12} align="center">
            No Rows
          </TableCell>
        </TableRow>
      );
    } else {
      return weeklyReportData?.map(row => (
        <ProjectCertifiedPayrollTableRow
          key={row.id}
          row={row}
          expandedRowId={expandedRowId}
          setExpandedRowId={setExpandedRowId}
          permission={projectCertifiedPayrollPermission?.fieldPerObj?.TimeSheet?.canView}
          currencyCell={CurrencyCell}
          rowData={rowData}
          colValue={colValue}
          setColValue={setColValue}
          setIsAssigned={setIsAssigned}
          status={status === STAUTES.LOADING}
          handleStatus={handleStatus}
          setPagination={setPagination}
          setSearchString={setSearchString}
          setSortModel={setSortModel}
          rowCount={rowCount}
          activeDailySheet={activeDailySheet}
          handleDailyTimeSheet={handleDailyTimeSheet}
          handleDailyPayroll={handleDailyPayroll}
          handleDailyCertifiedPayroll={handleDailyCertifiedPayroll}
          columns={columns}
          searchString={searchString}
          pageSize={pageSize}
          currentPage={currentPage}
          tokenData={tokenData}
        />
      ));
    }
  }

  return <>{renderContent()}</>;
};

ProjectCertifiedPayrollTable.propTypes = {
    id: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    weeklyReportData: PropTypes.array,
    projectCertifiedPayrollPermission: PropTypes.object,
    STAUTES: PropTypes.object.isRequired,
    setPageState: PropTypes.func.isRequired,
    status: PropTypes.bool.isRequired,
    setStatus: PropTypes.func.isRequired,
    setPagination: PropTypes.func.isRequired,
    searchString: PropTypes.string,
    setSearchString: PropTypes.func.isRequired,
    sortModel: PropTypes.object,
    setSortModel: PropTypes.func.isRequired,
    rowCount: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    tokenData: PropTypes.object.isRequired,
    dailyTimesheet: PropTypes.array
  };

export default ProjectCertifiedPayrollTable;
