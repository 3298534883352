export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const BASE_API_URI = process.env.REACT_APP_API_URL;
export const BASE_PASS_KEY = process.env.REACT_APP_PASS_KEY;
export const BASE_SSN_KEY = process.env.REACT_APP_SSN_KEY;
export const BASE_SOCKET_URI = process.env.REACT_APP_SOCKET_URL;
export const BASE_MUI_KEY = process.env.REACT_APP_MUI_KEY;
export const BASE_WS_API_TOKEN = process.env.REACT_APP_WS_API_TOKEN;
export const BASE_PWC_API_URL = process.env.REACT_APP_PWC_API_URL;
export const BASE_PWC_WSS_API_URL = process.env.REACT_APP_PWC_WSS_API_URL;

export const DEFAULT_SIZE = 4;
export const STAUTES = Object.freeze({
  PENDING: "pending",
  SUCCESS: "success",
  ERROR: "error",
  LOADING: "loading",
  IDLE: "idle"
});
export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGE = 1;
export const DETAULT_ROLE = "Admin";
export const EMPLOYEE_ROLE = "Employee";
export const DEFAULT_DATE_FORMAT = "MM/DD/YYYY";
export const DEFAULT_TIME_FORMAT = "hh:mm A";
export const DEFAULT_DATE_PAYLOAD_FORMAT = "YYYY-MM-DD";
export const DEFAULT_TIME_ZONE = "Europe/London";
export const CONTRUCTION_TYPE = "Heavy";
