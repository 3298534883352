import * as React from "react";
import "./ReportsDetails.scss";
import { Box, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, CircularProgress } from "@mui/material";
import { useTheme } from "../../../ContextStore/ThemeContext";
import PropTypes from 'prop-types';

const SummaryGrid = ({ workerDetailSummary, loading }) => {
  const { theme } = useTheme();
  const cellStyles = theme === "dark" ? { color: "#ffffff" } : { color: "#00050B" };

  return (
    <div className="report-details">
      {workerDetailSummary.length != 0 && (
        <Box>
          <Stack p={1} direction="row" justifyContent="space-between" flexWrap="wrap" alignItems="flex-start">
            <Typography fontWeight="700" fontSize={18} sx={cellStyles}>
              Summary
            </Typography>
          </Stack>
          <TableContainer>
            {loading ? (
                <CircularProgress size="3rem" sx={{ display: "flex", margin: "0 auto", my: 5 }} />
            ) : (
              <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ position: "sticky", top: 0, zIndex: 1 }} className="table-head-row" >
                    <TableCell align="center" sx={cellStyles}>Active Apprentices</TableCell>
                    <TableCell align="center" sx={cellStyles}>Active Journey Workers</TableCell>
                    <TableCell align="center" sx={cellStyles}>Journey to Apprentice Ratio</TableCell>
                    <TableCell align="center" sx={cellStyles}>Apprentices Hours</TableCell>
                    <TableCell align="center" sx={cellStyles}>Journey Worker Hours</TableCell>
                    <TableCell align="center" sx={cellStyles}>Average Appretnice Hours</TableCell>
                    <TableCell align="center" sx={cellStyles}>Lost OTJ</TableCell>
                    <TableCell align="center" sx={cellStyles}>RTI Hours</TableCell>
                    <TableCell align="center" sx={cellStyles}>Weekly OTJ Hours</TableCell>  
                  </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow className="table-row" sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell align="center" sx={cellStyles}>{workerDetailSummary?.activeApprentices}</TableCell>
                      <TableCell align="center" sx={cellStyles}>{workerDetailSummary?.activeJourneyWorkers}</TableCell>
                      <TableCell align="center" sx={cellStyles}>{workerDetailSummary?.apprenticeRatio}%</TableCell>
                      <TableCell align="center" sx={cellStyles}>{workerDetailSummary?.totalApprenticeHours}</TableCell>
                      <TableCell align="center" sx={cellStyles}>{workerDetailSummary?.totalJourneyWorkerHours}</TableCell>
                      <TableCell align="center" sx={cellStyles}>{workerDetailSummary?.averageApprenticeHoursPerWeek}</TableCell>
                      <TableCell align="center" sx={cellStyles}>{workerDetailSummary?.lostOTJHoursPerWeek}</TableCell>
                      <TableCell align="center" sx={cellStyles}>{workerDetailSummary?.rtiHoursPerWeek}</TableCell>
                      <TableCell align="center" sx={cellStyles}>{workerDetailSummary?.averageOTJHoursPerWeek}</TableCell>
                    </TableRow>
                </TableBody>
              </Table>
            )}
          </TableContainer>
        </Box>
      )}
    </div>
  );
};

SummaryGrid.propTypes = {
  workerDetailSummary: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default SummaryGrid;
