import React from "react";
import InputWrapper from "./InputWrapper";
import { Checkbox, FormControl, FormControlLabel, FormHelperText } from "@mui/material";
import { Controller } from "react-hook-form";
import { DEFAULT_SIZE } from "../../../utils/constant";
import PropTypes from "prop-types";

export default function ProCheckbox({
  name,
  mode,
  label,
  rules,
  control,
  isAddMode,
  handleReadValueclick,
  fieldProps = {},
  permission = {},
  size = DEFAULT_SIZE
}) {
  const { canView = false, canEdit = false, canAdd = false } = permission;
  if (!canView || (isAddMode && !canAdd)) return null;

  const handleValueClick = () => {
    if (mode === "read" && canEdit) handleReadValueclick(name);
  };
  const editModeAllowed = isAddMode ? canAdd : canEdit;

  return (
    <InputWrapper size={size}>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <FormControl error={error} variant="standard" className="proCheckbox">
            <FormControlLabel
              control={
                <Checkbox
                  onChange={onChange}
                  onBlur={onBlur}
                  disabled={!editModeAllowed}
                  checked={value}
                  className="custom-checkbox"
                  {...fieldProps}
                />
              }
              label={label}
              onClick={handleValueClick}
            />
            <FormHelperText>{error?.message || null}</FormHelperText>
          </FormControl>
        )}
      />
    </InputWrapper>
  );
}

ProCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(["read", "edit"]).isRequired,
  label: PropTypes.string.isRequired,
  rules: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,
  isAddMode: PropTypes.bool,
  handleReadValueclick: PropTypes.func,
  size: PropTypes.string,
  fieldProps: PropTypes.object,
  permission: PropTypes.shape({
    canView: PropTypes.bool,
    canAdd: PropTypes.bool,
    canEdit: PropTypes.bool
  })
};
