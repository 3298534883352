import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import TabGridAndFormLayout from "../../../Components/ProForms/ProFormsLayout/TabGridAndFormLayout";
import ProGrid from "../../../Components/ProGrid/v2";
import NameCell from "../../../Components/ProGrid/components/NameCell";
import DeleteCell from "../../../Components/ProGrid/components/DeleteCell";
import usePageState from "../../../../utils/customHooks/usePageState";
import { errorToast, successToast } from "../../../../utils/toastHelper";
import { Box } from "@mui/material";
import {
  GET_ALL_PROJECT_JOB,
  ADD_PROJECT_JOB,
  UPDATE_PROJECT_JOB,
  DELETE_PROJECT_JOB,
  GET_PROJECT_JOB,
  GET_ALL_JOB_TITLE,
  GET_ALL_ASSIGNRAPDROPDOWN,
  GET_ALL_ASSIGNRAPWAGESCALEDROPDOWN,
  GET_ALL_ASSIGNRAPJOBINFO,
  GET_PROJECTWISE_COUNTRYANDSTATE,
  GET_PWC_FILE_PATH
} from "../../../../utils/services/apiPath";
import { deleteApi, postApi, getApi } from "../../../../utils/services";
import {
  changeMode,
  changeModeForField,
  checkEditEnabled,
  prepareDefaultValues,
  prepareInitialConfig,
  projectJobsFormName
} from "../../../../utils/formHelper";
import { defaultDropdownConfig } from "../../../../utils/dropdownHelper";
import { projectsJobsPermissionSelector } from "../../../store/features/permissions/permissionsSlice";
import usePermission from "../../../../utils/customHooks/usePermission";
import { currencyColumnDef, percentageColumnDef } from "../../../Components/ProGrid/components/FormatCell";
import useColumn from "../../../../utils/customHooks/useColumns";
import { projectJobFormConfing, constructionType } from "../projectData";
import { BASE_WS_API_TOKEN, BASE_PWC_API_URL, STAUTES } from "../../../../utils/constant";
import "./ProjectJobs.scss";
import axios from "axios";
import ProjectJobsPwc from "./ProjectJobsPwc";

export default function ProjectJobs() {
  const {
    currentPage,
    pageSize,
    data: jobData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();
  const { id } = useParams();

  const [showGrid, setShowGrid] = useState(true);
  const [projectJobData, setProjectJobData] = useState({});
  const [jobTitle, setJobTitle] = useState([]);
  const [jobTitleDescription, setJobTitleDescription] = useState({});
  const [assignRAPInfoGuids, setAssignRAPInfoGuids] = useState([]);
  const [assignRAPWageScaleGuids, setAssignRAPWageScaleGuids] = useState([]);
  const [wageRateConcented, setWageRateConcented] = useState(false);
  const [wsPayload, setWsPayload] = useState(null);
  const [jobSearchOptions, setJobSearchOptions] = useState([]);
  const [pauseUpdate, setPauseUpdate] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isFetchLabels, setIsFetchLabels] = useState(false);

  const [formConfig, setFormConfig] = useState(prepareInitialConfig(projectJobFormConfing));
  const { handleSubmit, control, setValue, getValues, watch, setError, clearErrors, trigger } = useForm({
    defaultValues: prepareDefaultValues(projectJobFormConfing)
  });
  const isEditModeOn = useMemo(() => checkEditEnabled(false, formConfig), [formConfig]);
  const { "Add Job Detail or Job Detail": addJobPermisison, "Job List": jobListPermission } = usePermission(
    projectsJobsPermissionSelector,
    projectJobsFormName
  );

  const actualFringe = watch("actualFringe");
  const wageRate = watch("wageRate");
  const actualWageRate = watch("actualWageRate");
  const isCraftTrade = watch("IsCraftTradeLaborPosition");
  const isAdminRole = watch("IsRole");
  const isExternalJobTitle = watch("isExternalJobTitle");
  const assignRAPInfoGuid = watch("assignRAPInfoGuid");
  const jobDescription = watch("jobDescription");
  const jobTitleId = watch("jobTitleId");

  const resetForm = () => {
    setProjectJobData({});
    setShowGrid(true);
    setFormConfig(changeMode("read"));
    setPauseUpdate(false);
  };

  const onCancel = () => {
    if (projectJobData?.projectJobTitleId) setFormConfig(changeMode("read"));
    else resetForm();
  };

  const onJobTitleClick = params => {
    setShowGrid(false);
    setPauseUpdate(true);
    getProjectJobData(params.id);
  };

  const addButtonAction = () => {
    Object.keys(formConfig)?.forEach(field => setValue(field, null));
    setProjectJobData({});
    onEdit();
    setShowGrid(false);
  };

  const onSave = async data => {
    if (isSaving) return;
    setIsSaving(true);
    const payload = {
      ...data,
      projectId: id,
      perDiem: data?.perDiem || 0,
      compliant: "Yes",
      isCraftOrTradeLabor: data?.IsCraftTradeLaborPosition,
      isAdministrativeRole: data?.IsRole,
      typeOfEquipment: getJobTitleComma(data?.typeOfEquipment || []),
      typeOfJob: getJobTitleComma(data?.typeOfJob || []),
      wageRateConcented
    };
    const isAddMode = !projectJobData?.projectJobTitleId;
    const methodUrl = isAddMode ? ADD_PROJECT_JOB : UPDATE_PROJECT_JOB;
    if (!isAddMode) payload["projectJobTitleId"] = projectJobData?.projectJobTitleId;
    if (!data.isExternalJobTitle) payload["jobTitleId"] = data?.jobTitleId;
    const { error, data: jobInfo } = await postApi(methodUrl, payload);
    if (error) return errorToast(error);
    setIsSaving(false);
    getAllProjectJobTitles();
    setFormConfig(changeMode("read"));
    successToast(`Job Detail ${isAddMode ? "Added" : "Updated"} Successfully`);
    const jobTitleId = projectJobData?.projectJobTitleId || jobInfo?.guidId;
    if (jobTitleId) getProjectJobData(jobTitleId);
    else setShowGrid(true);
  };

  const getTypeOfEquipment = projectJob => {
    if (projectJob?.typeOfEquipment) {
      if (projectJob.typeOfEquipment.includes(",")) {
        return projectJob.typeOfEquipment.split(",");
      } else {
        return [projectJob.typeOfEquipment];
      }
    }
    return "";
  };

  const getTypeOfJob = projectJob => {
    if (projectJob?.typeOfJob) {
      if (projectJob.typeOfJob.includes(",")) {
        return projectJob.typeOfJob.split(",");
      } else {
        return [projectJob.typeOfJob];
      }
    }
    return "";
  };

  const getProjectJobData = async projectJobTitleId => {
    if (projectJobTitleId) {
      const { data: projectJob } = await getApi(`${GET_PROJECT_JOB}/${projectJobTitleId}`);
      if (projectJob) {
        if (projectJob?.assignRAPInfoDropdown) {
          fetchAllAssignRAPWageScaleDropDown(projectJob?.assignRAPInfoDropdown?.value);
          fetchAssignedRAPJobInfo(projectJob?.assignRAPInfoDropdown?.value);
        }
        const data = {
          ...projectJob,
          projectId: id,
          projectJobTitleId: projectJobTitleId,
          jobTitleId: projectJob.jobTitleDropdown?.value,
          IsCraftTradeLaborPosition: projectJob.isCraftOrTradeLabor,
          IsRole: projectJob.isAdministrativeRole,
          assignRAPInfoGuid: projectJob?.assignRAPInfoDropdown?.value,
          assignRAPWageScaleGuid: projectJob?.assignRAPWageScaleDropdown?.value,
          typeOfEquipment: getTypeOfEquipment(projectJob),
          typeOfJob: getTypeOfJob(projectJob)
        };
        setProjectJobData(data);
        Object.keys(formConfig)?.forEach(field => setValue(field, data[field]));
      }
    }
  };

  const getPWCFilePath = async s3Url => {
    if (s3Url) {
      const data = await getApi(`${GET_PWC_FILE_PATH}?path=${s3Url}`);
      if (data?.data) {
        window.open(data.data, "_blank");
      }
    }
  };

  const onDelete = async params => {
    const payload = [{ projectJobTitleId: params }];
    const data = {
      headers: { "Content-Type": "application/json" },
      data: payload
    };
    const { error } = await deleteApi(DELETE_PROJECT_JOB, data);
    if (error) return errorToast(error);
    getAllProjectJobTitles();
    setShowGrid(true);
  };

  const getJobTitleComma = jobTitleList => {
    let stringJob = "";
    jobTitleList?.map((job, index) => {
      if (index === 0) stringJob = job;
      else stringJob = `${stringJob}, ${job}`;
    });
    return stringJob;
  };

  const columns = useColumn([
    {
      field: "jobTitle",
      headerName: "Job Title",
      renderCell: params => (
        <NameCell params={params} onClick={onJobTitleClick}>
          {params.row.isExternalJobTitle === true ? params.row.externalJobTitle : params.row.jobTitle}
        </NameCell>
      )
    },
    {
      field: "onetCode",
      headerName: "ONET Code",
      renderCell: params => <Box>{params.row.onetCode || "N/A"}</Box>
    },
    {
      field: "numberOfPositions",
      headerName: "Number Of Positions"
    },
    currencyColumnDef({ field: "wageRate", headerName: "PW Wage Rate" }),
    currencyColumnDef({ field: "fringe", headerName: "PW Fringe" }),
    currencyColumnDef({ field: "pwTotalHourlyComp", headerName: "PW Total Hourly Comp" }),
    currencyColumnDef({ field: "actualWageRate", headerName: "Actual Wage Rate" }),
    currencyColumnDef({ field: "actualFringe", headerName: "Actual Fringe" }),
    currencyColumnDef({ field: "actualTotalHourlyComp", headerName: "Actual Total Hourly Comp" }),
    currencyColumnDef({ field: "marketRate", headerName: "Market Rate" }),
    {
      field: "compliant",
      headerName: "Compliant"
    },
    percentageColumnDef({ field: "pwAiConfidenceMatchScore", headerName: "PW AI % Confidence Match Score" }),
    {
      field: "projectJobTitleId",
      headerName: "Actions",
      isDeleteColumn: true,
      disableExport: true,
      width: 80,
      renderCell: params => <DeleteCell params={params} onDelete={onDelete} />
    }
  ]);

  const onEdit = () => {
    setFormConfig(changeMode("edit"));
    setPauseUpdate(false);
  };

  const handleReadValueclick = name => setFormConfig(changeModeForField(name, "edit"));

  const getAllProjectJobTitles = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const payload = {
      pageIndex: searchString ? 1 : currentPage + 1,
      pageSize: pageSize,
      searchString: searchString,
      orderByAscending: true,
      orderCol: "jobTitle",
      ...sortModel
    };
    const { data, totalRecords, error } = await postApi(`${GET_ALL_PROJECT_JOB}?projectId=${id}`, payload);
    if (error) return errorToast(error);
    setStatus(STAUTES.IDLE);
    const jobTitles = (data || []).map(item => ({ ...item, ...(item.isExternalJobTitle ? { jobTitle: item.externalJobTitle } : {}) }));
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: jobTitles, rowCount: totalRecords }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]);

  const fetchJobTitles = async () => {
    const { data } = await postApi(GET_ALL_JOB_TITLE, defaultDropdownConfig);
    let descriptionMap = {};
    data?.map(item => {
      descriptionMap[item.jobTitleId] = item.jobTitleDescription;
    });
    setJobTitleDescription(descriptionMap);
    setJobTitle(data?.map(item => ({ label: item.jobTitleName, value: item.jobTitleId })));
  };

  const fetchAllAssignRAPDropDown = async () => {
    const { data } = await getApi(`${GET_ALL_ASSIGNRAPDROPDOWN}`);
    setAssignRAPInfoGuids(data?.map(item => ({ label: item.jobTitleName, value: item.assignRAPInfoId })).filter(item => item.label));
  };

  const fetchAllAssignRAPWageScaleDropDown = async rapInfoId => {
    const { data } = await getApi(`${GET_ALL_ASSIGNRAPWAGESCALEDROPDOWN}?rapInfoId=${rapInfoId}`);
    setAssignRAPWageScaleGuids(data?.map(item => ({ label: item.wageScaleTitle, value: item.assignRAPWageScaleId })));
  };

  const fetchAssignedRAPJobInfo = async rapInfoId => {
    const assignRAPInfo = await getApi(`${GET_ALL_ASSIGNRAPJOBINFO}/${rapInfoId}`);
    if (assignRAPInfo?.data) {
      setValue("onetCode", assignRAPInfo.data?.onetCode);
      setValue("occupationTitle", assignRAPInfo.data?.occupationTitle);
      setProjectJobData(data => ({ ...data, occupationTitle: assignRAPInfo.data?.occupationTitle }));
    }
  };

  const GetProjectWiseCountryAndState = async () => {
    const { data, error } = await getApi(`${GET_PROJECTWISE_COUNTRYANDSTATE}/${id}`);
    if (error) return errorToast(error);
    setWsPayload(data);
  };

  const getJobSearchOption = async descriptions => {
    setValue("typeOfJob", null);
    setJobSearchOptions([]);
    clearErrors("jobDescription");
    const descriptionWordLength = descriptions?.trim().split(/\s+/).length;
    if (descriptionWordLength > 1) {
      setIsFetchLabels(true);
      const body = {
        state: wsPayload?.state,
        county: wsPayload?.county,
        constructionType: constructionType,
        job_description: descriptions
      };
      try {
        const result = await axios.post(`${BASE_PWC_API_URL}get-job-names`, body, {
          headers: {
            Authorization: `Bearer ${BASE_WS_API_TOKEN}`
          }
        });
        setJobSearchOptions(result?.list_of_job_names || []);
        setIsFetchLabels(false);
      } catch (error) {
        console.log(error);
        setIsFetchLabels(false);
      }
    } else {
      setError("jobDescription", { type: "manual", message: "Please enter at least 2 words" });
    }
  };

  useEffect(() => {
    fetchJobTitles();
    fetchAllAssignRAPDropDown();
    GetProjectWiseCountryAndState();
  }, []);

  useEffect(() => {
    if (isAdminRole) setValue("IsCraftTradeLaborPosition", false);
  }, [isAdminRole]);

  useEffect(() => {
    if (isCraftTrade) setValue("IsRole", false);
  }, [isCraftTrade]);

  useEffect(() => {
    getAllProjectJobTitles();
  }, [getAllProjectJobTitles]);

  useEffect(() => {
    setValue("actualTotalHourlyComp", (parseFloat(actualFringe || 0) + parseFloat(actualWageRate || 0)).toFixed(2));
  }, [actualFringe, actualWageRate]);

  useEffect(() => {
    if (assignRAPInfoGuid) {
      fetchAllAssignRAPWageScaleDropDown(assignRAPInfoGuid);
      fetchAssignedRAPJobInfo(assignRAPInfoGuid);
    } else {
      setAssignRAPWageScaleGuids([]);
    }
  }, [assignRAPInfoGuid]);

  useEffect(() => {
    if (!pauseUpdate) {
      setValue("externalJobTitle", null);
      setValue("jobTitleId", null);
      setValue("typeOfJob", null);
      setValue("jobDescription", "");
    }
  }, [isExternalJobTitle]);

  useEffect(() => {
    if (!pauseUpdate && jobTitleId) {
      setValue("jobDescription", jobTitleDescription[jobTitleId]);
      getJobSearchOption(jobTitleDescription[jobTitleId]);
    }
  }, [jobTitleId]);

  const defaultFormProps = { control, formValues: projectJobData, handleReadValueclick };

  return (
    <TabGridAndFormLayout
      showGrid={showGrid}
      title={"Job Detail"}
      backLabel="Go back to Job Detail List"
      backAction={() => resetForm()}
      beingEdited={isEditModeOn}
      onEdit={onEdit}
      canView={jobListPermission.canView}
      hideEdit={!addJobPermisison.canEdit}
      onSave={handleSubmit(onSave)}
      onCancel={onCancel}
      isSaving={isSaving}
    >
      {showGrid && (
        <ProGrid
          title="Job Detail"
          columns={columns}
          loading={status === STAUTES.LOADING}
          rows={jobData}
          searchMode={!!searchString}
          searchModel={{ defaultValue: searchString, handleDebounce: setSearchString }}
          addButtonLabel="+ Add Job Detail"
          addButtonAction={addButtonAction}
          hideAddButton={!jobListPermission.canAdd}
          hideExport={!jobListPermission.canExport}
          options={{
            getRowId: row => row.projectJobTitleId,
            rowCount: rowCount,
            paginationMode: "server",
            paginationModel: { pageSize: pageSize, page: currentPage },
            onPaginationModelChange: setPagination,
            sortingMode: "server",
            onSortModelChange: setSortModel
          }}
        />
      )}
      {!showGrid && (
        <ProjectJobsPwc
          formConfig={formConfig}
          defaultFormProps={defaultFormProps}
          jobTitle={jobTitle}
          addJobPermisison={addJobPermisison}
          wsPayload={wsPayload}
          assignRAPInfoGuids={assignRAPInfoGuids}
          assignRAPWageScaleGuids={assignRAPWageScaleGuids}
          watch={watch}
          getValues={getValues}
          setValue={setValue}
          wageRate={wageRate}
          jobSearchOptions={jobSearchOptions}
          setWageRateConcented={setWageRateConcented}
          isEditModeOn={isEditModeOn}
          getPWCFilePath={getPWCFilePath}
          jobTitleDescription={jobTitleDescription}
          getJobSearchOption={() => getJobSearchOption(jobDescription)}
          isFetchLabels={isFetchLabels}
          trigger={trigger}
          setError={setError}
          clearErrors={clearErrors}
        />
      )}
    </TabGridAndFormLayout>
  );
}
