import React, { useCallback, useEffect, useState } from "react";
import { Box, CircularProgress, Grid } from "@mui/material";
import ReportsLayout from "../../../Components/ProForms/ProFormsLayout/ReportsFormsLayout";
import { fieldTruePermission, formatPayloadDate, prepareDefaultValues, prepareInitialConfig } from "../../../../utils/formHelper";
import ProButton from "../../../Components/ProButton";
import DateField from "../../../Components/Inputs/DateField";
import { useForm } from "react-hook-form";
import ProSelectField from "../../../Components/Inputs/SelectField";
import { getApi, postApi } from "../../../../utils/services";
import {
  APPRENTICE_MENTOR_HISTORY_BY_PROJECT,
  ASSIGN_APPRENTICE_MENTOR,
  DELETE_APPRENTICE_MENTOR_HISTORY,
  GET_APPRENTICE_MENTOR_DROPDOWN
} from "../../../../utils/services/apiPath";
import { useParams } from "react-router-dom";
import usePageState from "../../../../utils/customHooks/usePageState";
import { errorToast, successToast } from "../../../../utils/toastHelper";
import useColumn from "../../../../utils/customHooks/useColumns";
import ProGrid from "../../../Components/ProGrid/v2";
import { STAUTES } from "../../../../utils/constant";
import DateRangeSelect from "../../../Components/ProGrid/components/DateRangeSelect";
import DeleteCell from "../../../Components/ProGrid/components/DeleteCell";
import DialogModal from "../../../Components/DialogModel";
import { dateColumnDef } from "../../../Components/ProGrid/components/FormatCell";
import { subModPermissionSelector } from "../../../store/features/permissions/permissionsSlice";
import { R_PROJECTS, R_PROJECTS_MENTOR_APPRENTICE } from "../../../../utils/permissionReferenceConfig";
import usePermission from "../../../../utils/customHooks/usePermission";
import { ErrorBox } from "../../../Components/Helpers/ErrorBox";
import dayjs from "dayjs";

const MentorAndApprenticeConfig = {
  mentorId: {
    label: "Select Mentor",
    rules: { required: "Mentor is required!" }
  },
  apprenticeId: {
    label: "Select Apprentice",
    rules: { required: "Apprentice is required!" }
  },
  todayDate: {
    label: "Date",
    defaultValue: dayjs(),
    rules: { required: "Date is required!" }
  },
  fromDate: {
    label: "From Date",
    defaultValue: dayjs()
  },
  toDate: {
    label: "To Date",
    defaultValue: dayjs()
  }
};

const MentorAndApprentice = () => {
  const {
    currentPage,
    pageSize,
    data: employeeData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();

  const { id } = useParams();
  const [apprenticeData, setApprenticeData] = useState([]);
  const [mentorData, setMentorData] = useState([]);
  const [formConfig] = useState(prepareInitialConfig(MentorAndApprenticeConfig, "edit"));
  const [isError, setIsError] = useState(false);
  const { handleSubmit, control, watch, reset } = useForm({ defaultValues: prepareDefaultValues(MentorAndApprenticeConfig) });
  const fromDate = watch("fromDate");
  const toDate = watch("toDate");
  const todayDate = watch("todayDate");
  const apprenticeId = watch("apprenticeId");
  const mentorId = watch("mentorId");
  const [isSaving, setIsSaving] = useState(false);
  const { "Mentor-Apprentice": projectMentorApprenticePermission } = usePermission(
    subModPermissionSelector(R_PROJECTS, R_PROJECTS_MENTOR_APPRENTICE),
    ["Mentor-Apprentice"]
  );

  const onSave = async () => {
    setStatus(STAUTES.LOADING);
    if (isSaving) return;
    setIsSaving(true);
    const payload = {
      apprenticeGuid: apprenticeId,
      mentorGuid: mentorId,
      projectGuid: id,
      workDate: formatPayloadDate(todayDate),
      concentedReassign: !!isError
    };
    const { error } = await postApi(ASSIGN_APPRENTICE_MENTOR, payload);
    setIsSaving(false);
    if (error) {
      setStatus(STAUTES.IDLE);
      if (error === "Please Provide Concent to ReAssign") setIsError(true);
      else errorToast(error);
    } else {
      resetForm();
      getAllApprenticeHistory();
      successToast(`Apprentice Mentor Added Successfully`);
    }
  };

  const getAllApprenticeHistory = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const payload = {
      projectGuid: id,
      dateFrom: formatPayloadDate(fromDate),
      dateTo: formatPayloadDate(toDate),
      pageIndex: searchString ? 1 : currentPage + 1,
      pageSize: pageSize,
      searchString: searchString,
      orderByAscending: false,
      orderCol: "workDate",
      ...sortModel
    };
    const { data, totalRecords, error } = await postApi(APPRENTICE_MENTOR_HISTORY_BY_PROJECT, payload);
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    const formattedData = (data || [])?.map((item, index) => ({ ...item, id: index + 1 }));
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: formattedData, rowCount: totalRecords }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus, fromDate, toDate]);

  useEffect(() => {
    getAllApprenticeHistory();
  }, [getAllApprenticeHistory]);

  const columns = useColumn(
    [
      {
        field: "mentorName",
        headerName: "Mentor",
        renderCell: params => (
          <Box>{params.row.mentorFirstName && params.row.mentorLastName ? `${params.row.mentorFirstName} ${params.row.mentorLastName}` : "N/A"}</Box>
        )
      },
      {
        field: "apprenticeName",
        headerName: "Apprentice",
        renderCell: params => (
          <Box>
            {params.row.apprenticeFirstName && params.row.apprenticeLastName
              ? `${params.row.apprenticeFirstName} ${params.row.apprenticeLastName}`
              : "N/A"}
          </Box>
        )
      },
      dateColumnDef({
        field: "workDate",
        headerName: "Work Date"
      }),
      {
        field: "createdBy",
        headerName: "Created By"
      },
      dateColumnDef({
        field: "createdDate",
        headerName: "Created Date"
      }),
      {
        field: "modifiedBy",
        headerName: "Updated By"
      },
      dateColumnDef({
        field: "modifiedDate",
        headerName: "Updated Date"
      }),
      {
        field: "mentorGuid",
        headerName: "Actions",
        isDeleteColumn: false,
        disableExport: true,
        width: 80,
        renderCell: params => <DeleteCell params={params} onDelete={() => onDelete(params?.row)} disabled={params?.row?.isValidated} />
      }
    ],
    false
  );

  const getAprenticeMentorDropdown = useCallback(async () => {
    const workDate = formatPayloadDate(todayDate);
    const { data } = await getApi(`${GET_APPRENTICE_MENTOR_DROPDOWN}?projectGuid=${id}&workDate=${workDate}`);
    setApprenticeData(data?.apprentices);
    setMentorData(data?.mentors);
  }, [todayDate]);

  useEffect(() => {
    getAprenticeMentorDropdown();
  }, [getAprenticeMentorDropdown]);

  const resetForm = () => {
    reset();
  };

  const onDelete = async row => {
    const { error } = await postApi(`${DELETE_APPRENTICE_MENTOR_HISTORY}?projectApprenticeMentorId=${row?.projectApprenticeMentorGuid}`);
    if (error) return errorToast(error);
    successToast("Mentor Apprentice deleted successfully.");
    getAllApprenticeHistory();
  };

  const handleConfirmModal = () => {
    onSave();
    setIsError(false);
  };

  const handleCancelModal = () => {
    setIsError(false);
  };

  return (
    <ReportsLayout>
      {projectMentorApprenticePermission?.canView ? (
        <Box>
          {projectMentorApprenticePermission?.canAdd && (
            <>
              <Grid sx={{ pt: 1 }} container spacing={2}>
                <ProSelectField control={control} {...formConfig.mentorId} options={mentorData} permission={fieldTruePermission} />
                <ProSelectField control={control} {...formConfig.apprenticeId} options={apprenticeData} permission={fieldTruePermission} />
                <DateField control={control} {...formConfig.todayDate} permission={fieldTruePermission} />
              </Grid>
              <Grid container mt="1rem">
                <ProButton onClick={handleSubmit(onSave)} isSaving={isSaving}>
                  {isSaving ? (
                    <>
                      <CircularProgress size={24} />
                      <span style={{ marginLeft: 10 }}>Save</span>
                    </>
                  ) : (
                    "Save"
                  )}
                </ProButton>
              </Grid>
            </>
          )}
          <ProGrid
            title="Mentor Apprentice History"
            columns={columns}
            loading={status === STAUTES.LOADING}
            rows={employeeData}
            searchMode={!!searchString}
            searchModel={{ defaultValue: searchString, handleDebounce: setSearchString }}
            hideAddButton={true}
            hideExport={true}
            options={{
              getRowId: row => row.id,
              rowCount: rowCount,
              paginationMode: "server",
              paginationModel: { pageSize: pageSize, page: currentPage },
              onPaginationModelChange: setPagination,
              sortingMode: "server",
              onSortModelChange: setSortModel
            }}
            additionButtions={<DateRangeSelect control={control} formConfig={formConfig} fromDate={fromDate} toDate={toDate} />}
          />
          <DialogModal
            show={isError}
            title="Confirm"
            message="Mentor Assignment for this Apprentice already exists, Please Provide Concent to ReAssign"
            onConfirm={handleConfirmModal}
            onCancel={handleCancelModal}
          />
        </Box>
      ) : (
        <ErrorBox message="You don't have permission to view this page." />
      )}
    </ReportsLayout>
  );
};

export default MentorAndApprentice;
