import { lessThan16Years } from "../../../utils/formHelper";

export const EmployeeProfileFormConfing = {
  employeeStatusTypeId: {
    label: "Employee Status",
    perKey: "EmployeeStatus",
    defaultValue: null
  },
  interalEmployeeID: {
    label: "Internal Employee Id",
    perKey: "InternalEmployeeID",
    rules: {
      required: "Internal Employee Id is required!",
      pattern: {
        value: /^\d*$/,
        message: "Internal Employee Id must be only in numbers."
      }
    }
  },
  firstName: {
    label: "First Name",
    rules: { required: "First Name is required!" },
    perKey: "FirstName"
  },
  middleName: {
    label: "Middle Name",
    perKey: "MiddleName"
  },
  lastName: {
    label: "Last Name",
    rules: { required: "Last Name is required!" },
    perKey: "LastName"
  },
  jobTitle: {
    label: "Job Title",
    perKey: "JobTitle"
  },
  department: {
    label: "Department",
    perKey: "Department"
  },
  cellPhone: {
    label: "Cell Phone",
    rules: {
      required: "Cell Phone is required!",
      pattern: {
        value: /^.{14}$/,
        message: "Cell Phone Number must be 10 digits"
      }
    },
    perKey: "CellPhone"
  },
  officePhone: {
    label: "Office Phone",
    perKey: "OfficePhone",
    rules: {
      pattern: {
        value: /^.{14}$/,
        message: "Office Phone Number must be 10 digits"
      }
    }
  },
  email: {
    label: "Email",
    rules: { required: "Email is required!" },
    perKey: "Email"
  },
  whatsApp: {
    label: "Whats App",
    perKey: "WhatsApp",
    rules: {
      pattern: {
        value: /^.{14}$/,
        message: "Whats App Number must be 10 digits"
      }
    }
  },
  last4ofSSN: {
    label: "Last 4 SSN Digits",
    perKey: "SSN"
  },
  birthDate: {
    label: "Date of Birth",
    defaultValue: null,
    perKey: "BirthDate",
    rules: {
      validate: lessThan16Years
    }
  },
  sex: {
    label: "Sex",
    perKey: "Sex"
  },
  profilePictureFile: {
    label: "Profile Picture",
    perKey: "ProfilePicture",
    defaultValue: null,
    readKey: "profilePicture"
  },
  yearsOfExperience: {
    label: "Years of Experience",
    perKey: "YearsOfExperience"
  },
  isAppentice: {
    label: "Is Apprentice",
    perKey: "IsAppentice",
    defaultValue: false
  },
  ethnicityTypeId: {
    label: "Ethnicity",
    perKey: "Ethnicity",
    defaultValue: null
  },
  veteranStatusTypeId: {
    label: "Veteran Status",
    perKey: "VeteranStatus",
    defaultValue: null
  },
  raceTypeId: {
    label: "Race",
    perKey: "Race",
    defaultValue: null
  },
  educationLevelTypeId: {
    label: "Education Level",
    perKey: "EducationLevel",
    defaultValue: null
  },
  disabilityTypeId: {
    label: "Disability",
    perKey: "Disability",
    defaultValue: null
  },
  rapInfoId: {
    label: "Apprenticeships Program",
    perKey: "ApprenticeshipsProgram",
    defaultValue: null
  },
  dateApprenticeshipBegins: {
    label: "Date Apprenticeship Begins",
    defaultValue: null,
    perKey: "DateApprenticeshipBegins"
  },
  careerConnectionTypeId: {
    label: "Career Connection",
    perKey: "CareerConnection",
    defaultValue: null
  },
  anyCredit: {
    label: "Any credit for Previous On-the-job Learning Experience?",
    perKey: "AnyCredit",
    defaultValue: null
  },
  rti: {
    label: "RIL or Related Instruction, any Credit for Previous Instruction Experience?",
    perKey: "Rti",
    defaultValue: null
  },
  apprenticeStatusTypeId: {
    label: "Apprentice Status",
    perKey: "ApprenticeStatus",
    defaultValue: null
  },
  apprenticeshipRegNumber: {
    label: "Apprenticeship Number",
    perKey: "ApprenticeshipRegNumber"
  },
  address: {
    label: "Address",
    rules: { required: "Address is required!" },
    perKey: "Address"
  },
  address2: {
    label: "Address 2",
    perKey: "Address2"
  },
  state: {
    label: "State",
    rules: { required: "State is required!" },
    perKey: "State"
  },
  city: {
    label: "City",
    rules: { required: "City is required!" },
    perKey: "City"
  },
  zip: {
    label: "Zip",
    perKey: "Zip",
    rules: {
      required: "Zip is required!"
    }
  },
  mailingAddress: {
    label: "Mailing Address",
    perKey: "MailingAddress"
  },
  mailingState: {
    label: "Mailing State",
    perKey: "MailingState"
  },
  mailingCity: {
    label: "Mailing City",
    perKey: "MailingCity"
  },
  mailingZip: {
    label: "Mailing Zip",
    perKey: "MailingZip"
  },
  fringePaymentMethodType: {
    label: "Fringe Payment Method",
    perKey: "FringePaymentMethod",
    defaultValue: null
  },
  hourlyRateAmount: {
    label: "Hourly Rate Amount",
    perKey: "HourlyRateAmount",
    defaultValue: null
  },
  fringeRateAmount: {
    label: "Fringe Rate Amount",
    perKey: "FringeRateAmount",
    defaultValue: null
  },
  startDate: {
    label: "Start Date",
    defaultValue: null,
    perKey: "StartDate",
    rules: {
      validate: (value, formValues) => {
        if (!value) return true;
        if (value.isValid && !value.isValid()) return "Enter valid date";
        if (formValues.endDate && formValues.endDate < value) {
          return "Start Date should be less than End Date";
        }
      }
    }
  },
  endDate: {
    label: "End Date",
    defaultValue: null,
    perKey: "EndDate",
    rules: {
      validate: (value, formValues) => {
        if (!value) return true;
        if (value.isValid && !value.isValid()) return "Enter valid date";
        if (formValues.startDate && formValues.startDate > value) {
          return "End Date should be greater than Start Date";
        }
      }
    }
  },
  manager: {
    label: "Manager",
    perKey: "Manager",
    defaultValue: null
  },
  languageType: {
    label: "Language",
    perKey: "Language",
    defaultValue: null
  },
  notificationType: {
    label: "Notification",
    perKey: "Notification",
    defaultValue: null
  },
  employmentTermTypeId: {
    label: "Employment Term",
    perKey: "EmploymentTerm",
    defaultValue: null
  },
  employmentStatus: {
    label: "Employment Status",
    perKey: "EmploymentStatus",
    defaultValue: null
  }
};

export const EmployeeDocumentsFormConfing = {
  documentName: {
    label: "Document Name",
    perKey: "documentName",
    rules: {
      required: "Document Name is required!"
    }
  },
  employeeDocumentTypeId: {
    label: "Employee Document Type",
    perKey: "employeeDocumentTypeId",
    rules: {
      required: "Document Type is required!"
    }
  },
  documentMediaModel: {
    label: "Document Upload",
    perKey: "documentMediaModel",
    rules: {
      required: "Document is required!"
    }
  }
};

export const EmployeeNotesFormConfing = {
  noteName: {
    name: "noteName",
    label: "Note Name",
    perKey: "Note"
  },
  noteType: {
    name: "noteType",
    label: "Note Type",
    perKey: "SelectNoteType"
  },
  isPrivate: {
    name: "isPrivate",
    label: "Is Private",
    perKey: "IsPrivate",
    defaultValue: false
  }
};

export const EmployeeSocialMediaFormConfing = {
  socialMediaType: {
    name: "socialMediaType",
    label: "Social Media Type",
    size: "4",
    perKey: "SelectSociaMediaType"
  },
  handle: {
    name: "handle",
    label: "Handle",
    size: "4",
    perKey: "Handle"
  }
};
