import DashboardIcon from "../App/Assets/IconComponents/Dashboard";
import RapIcon from "../App/Assets/IconComponents/Rap";
import EmployeesIcon from "../App/Assets/IconComponents/Employees";
import ProjectsIcon from "../App/Assets/IconComponents/Projects";
import PartnersIocn from "../App/Assets/IconComponents/Partners";
import ReportsIcon from "../App/Assets/IconComponents/Reports";
import SettingsIcon from "../App/Assets/IconComponents/Settings";

import {
  R_DASHBOARD,
  R_PARTNERS,
  R_PROJECTS,
  R_EMPLOYEES,
  R_RAP_MGMT,
  R_REPORTS,
  R_SETTINGS,
  R_PAYROLL_REPORTS,
  R_JOB_TITLE,
  R_ADDRESS_TYPE,
  R_BONUS_TYPE,
  R_COMPENSATION_TYPE,
  R_CLIENT_DOCUMENT_TYPE,
  R_PROJECT_TYPE,
  R_INDUSTRY_TYPE,
  R_DEPARTMENT_TYPE,
  R_SOCIALMEDIA_TYPE,
  R_CONTRACT_TYPE,
  R_EMPLOYEE_STATUS_TYPE,
  R_EMPLOYEE_DOCUMENT_TYPE,
  R_EMPLOYEE_TERM_TYPE,
  R_FRINGE_PAYMENT_METHOD_TYPE,
  R_JOB_CATEGORY,
  R_LANGUAGE_TYPE,
  R_NOTIFICATION_TYPE,
  R_WORK_TYPE,
  R_ROLES,
  R_USERS,
  R_USER_PERMISSION,
  R_PROJECT_CONTACT_TYPE,
  R_LEADSOURCE_TYPE,
  R_LEADSUBSOURCE_TYPE
} from "./permissionReferenceConfig";

export const headerMenus = [
  {
    to: "/dashboard",
    title: "Dashboard",
    reference: R_DASHBOARD,
    hasArrow: false,
    icon: DashboardIcon,
    subMenus: [],
    ignorePermission: false
  },
  {
    to: "/partners",
    title: "Partners",
    reference: R_PARTNERS,
    hasArrow: false,
    icon: PartnersIocn,
    subMenus: []
  },
  {
    to: "/projects",
    title: "Projects",
    reference: R_PROJECTS,
    hasArrow: false,
    icon: ProjectsIcon,
    subMenus: []
  },
  {
    to: "/employees",
    title: "Employees",
    reference: R_EMPLOYEES,
    hasArrow: false,
    icon: EmployeesIcon,
    subMenus: []
  },
  {
    to: "/rap",
    title: "RAP Mgmt",
    reference: R_RAP_MGMT,
    hasArrow: false,
    icon: RapIcon,
    subMenus: []
  },
  {
    to: "/reports",
    title: "Reports",
    reference: R_REPORTS,
    hasArrow: true,
    icon: ReportsIcon,
    subMenus: [
      {
        link: "/reports/weekly-compliance-report",
        title: "Weekly Compliance Report",
        reference: R_PAYROLL_REPORTS,
        hasArrow: false
      }
    ]
  },
  {
    to: "/settings",
    title: "Settings",
    reference: R_SETTINGS,
    hasArrow: true,
    icon: SettingsIcon,
    subMenus: [
      {
        link: "/settings/address-type",
        title: "Address Type",
        reference: R_ADDRESS_TYPE
      },
      {
        link: "/settings/bonus-type",
        title: "Bonus Type",
        reference: R_BONUS_TYPE
      },
      {
        link: "/settings/client-document-type",
        title: "Client Document Type",
        reference: R_CLIENT_DOCUMENT_TYPE
      },
      {
        link: "/settings/compensation-type",
        title: "Compensation Type",
        reference: R_COMPENSATION_TYPE
      },
      {
        link: "/settings/contract-type",
        title: "Contract Type",
        reference: R_CONTRACT_TYPE
      },
      {
        link: "/settings/department-type",
        title: "Department Type",
        reference: R_DEPARTMENT_TYPE
      },
      {
        link: "/settings/employee-document-type",
        title: "Employee Document Type",
        reference: R_EMPLOYEE_DOCUMENT_TYPE
      },
      {
        link: "/settings/employee-status-type",
        title: "Employee Status Type",
        reference: R_EMPLOYEE_STATUS_TYPE
      },
      {
        link: "/settings/employee-term-type",
        title: "Employee Term Type",
        reference: R_EMPLOYEE_TERM_TYPE
      },
      {
        link: "/settings/fringe-payment-method-type",
        title: "Fringe Payment Method Type",
        reference: R_FRINGE_PAYMENT_METHOD_TYPE
      },
      {
        link: "/settings/industry-type",
        title: "Industry Type",
        reference: R_INDUSTRY_TYPE
      },
      {
        link: "/settings/job-category",
        title: "Job Category",
        reference: R_JOB_CATEGORY
      },
      {
        link: "/settings/job-title",
        title: "Job Title",
        reference: R_JOB_TITLE
      },
      {
        link: "/settings/language-type",
        title: "Language Type",
        reference: R_LANGUAGE_TYPE
      },
      {
        link: "/settings/lead-source-type",
        title: "Lead Source Type",
        reference: R_LEADSOURCE_TYPE,
        ignorePermission: true
      },
      {
        link: "/settings/lead-subsource-type",
        title: "Lead Sub Source Type",
        reference: R_LEADSUBSOURCE_TYPE,
        ignorePermission: true
      },
      {
        link: "/settings/notification-type",
        title: "Notification Type",
        reference: R_NOTIFICATION_TYPE
      },
      {
        link: "/settings/project-type",
        title: "Project Type",
        reference: R_PROJECT_TYPE
      },
      {
        link: "/settings/project-contact-type",
        title: "Project Contact Type",
        reference: R_PROJECT_CONTACT_TYPE
      },
      {
        link: "/settings/social-media-type",
        title: "Social Media Type",
        reference: R_SOCIALMEDIA_TYPE
      },
      {
        link: "/settings/users",
        title: "Users",
        reference: R_USERS
      },
      {
        link: "/settings/roles",
        title: "User Roles",
        reference: R_ROLES
      },
      {
        link: "/settings/user-permission",
        title: "User Permission",
        reference: R_USER_PERMISSION
      },
      {
        link: "/settings/work-type",
        title: "Work Type",
        reference: R_WORK_TYPE
      }
    ]
  }
];
