import React, { useCallback, useEffect, useState, useMemo } from "react";
import "./Dashboard.scss";
import projectsIcon from "../../Assets/Icons/projects.png";
import workersIcon from "../../Assets/Icons/workers.png";
import apprenticesIcon from "../../Assets/Icons/apprentices.png";
import CradsTopSection from "../../Components/CradsTopSection/CradsTopSection";
import Layout from "../../Components/Layout";
import { useTheme } from "../../ContextStore/ThemeContext";
import { getApi } from "../../../utils/services";
import { GET_ALL_PROJECT_COMPLIANCE, GET_USER_DASHBOARD_DETAILS, GET_ALL_PROJECT, GET_DASHBOARD_DETAIL_BY_ID } from "../../../utils/services/apiPath";
import useColumn from "../../../utils/customHooks/useColumns";
import ProGrid from "../../Components/ProGrid/v2";
import { STAUTES } from "../../../utils/constant";
import { errorToast } from "../../../utils/toastHelper";
import usePageState from "../../../utils/customHooks/usePageState";
import { Box, Card, CardHeader, Divider, Grid, Stack, Tooltip, Typography } from "@mui/material";
import { buildStyles } from "react-circular-progressbar";
import { currencyColumnDef, percentageColumnDef } from "../../Components/ProGrid/components/FormatCell";
import { allPermissionSelector } from "../../store/features/permissions/permissionsSlice";
import { headerPermissionTransformer } from "../../store/DataTransformer/permissionTransformer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { R_DASHBOARD } from "../../../utils/permissionReferenceConfig";
import { handleLogout } from "../../store/features/auth/authSlice";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import WorkforceHoursChart from "./Analytics/WorkforceHoursChart";
import DBAChart from "./Analytics/DBAChart";
import RTIChart from "./Analytics/RTIChart";
import LostOTJChart from "./Analytics/LostOTJChart";
import ProjectWPSStatus from "./Analytics/ProjectWPSStatus";

const genrateOptions = options => {
  return options.map(option => {
    return (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    );
  });
};

const Dashboard = () => {
  const navigate = useNavigate();
  const { theme } = useTheme();
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState("All");
  const [selectedProjectLabel, setSelectedProjectLabel] = useState("");
  const [dashboardDetailByProject, setDashboardDetailByProject] = useState();
  const { data: complianceDetails, status, setPageState, setPagination, setStatus } = usePageState();
  const { permissions, permissionLoaded } = useSelector(allPermissionSelector);
  const accesibleTabs = useMemo(() => headerPermissionTransformer(permissions), [permissions]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (permissionLoaded) {
      if (accesibleTabs?.length) {
        const [firstTab] = accesibleTabs;
        if (firstTab.reference !== R_DASHBOARD) navigate(firstTab.to);
      } else {
        dispatch(handleLogout());
        errorToast("You don't have access to any tab. Please contact your administrator.");
      }
    }
  }, [accesibleTabs]);

  const fetchUserData = async () => {
    try {
      const { error } = await getApi(GET_USER_DASHBOARD_DETAILS);
      if (error) return errorToast(error);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchComplianceData = useCallback(
    async value => {
      const { data, totalRecords, error } = await getApi(`${GET_ALL_PROJECT_COMPLIANCE}/${value}`);
      setStatus(STAUTES.IDLE);
      if (error) return errorToast(error);
      setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
    },
    [setPageState, setStatus]
  );

  const fetchProjects = async () => {
    const { data } = await getApi(GET_ALL_PROJECT);
    setProjects(data.map(item => ({ label: item.projectName, value: item.projectId })));
  };

  const fetchDashboardDetail = async projectId => {
    const { data } = await getApi(`${GET_DASHBOARD_DETAIL_BY_ID}?projectId=${projectId}`);
    setDashboardDetailByProject(data);
  };

  useEffect(() => {
    fetchUserData();
    fetchProjects();
    fetchComplianceData(selectedProject);
    fetchDashboardDetail("All");
  }, []);

  const data = [
    {
      icon: workersIcon,
      title: "Active Workers",
      value: dashboardDetailByProject?.activeWorkers,
      color: theme === "light" ? "#E6F5F9" : "#81E5FF15"
    },
    {
      icon: apprenticesIcon,
      title: "Active Apprentices",
      value: dashboardDetailByProject?.activeApprentice,
      color: theme === "light" ? "#EFF6FC" : "#72BEFF15"
    },
    {
      icon: workersIcon,
      title: "Active Mentors",
      value: dashboardDetailByProject?.mentorCount,
      color: theme === "light" ? "#FFF7E8" : "#FFD88E15"
    },
    {
      icon: workersIcon,
      title: "Active Journey Workers",
      value: dashboardDetailByProject?.journeyManCount,
      color: theme === "light" ? "#E6F5F9" : "#81E5FF15"
    },
    {
      icon: workersIcon,
      title: "Active Non-Trade Workers",
      value: dashboardDetailByProject?.nonTradersCount,
      color: theme === "light" ? "#EFF6FC" : "#72BEFF15"
    },
    {
      icon: projectsIcon,
      title: "Project Workers to date",
      value: dashboardDetailByProject?.projectWorkersToDate,
      color: theme === "light" ? "#E6F5F9" : "#81E5FF15"
    },
    {
      icon: projectsIcon,
      title: "Apprentice - Attrition Rate",
      value: `${
        dashboardDetailByProject?.apprenticeAttritionRate ? dashboardDetailByProject?.apprenticeAttritionRate?.toFixed(2) : parseFloat(0.0).toFixed(2)
      }%`,
      color: theme === "light" ? "#EFF6FC" : "#72BEFF15"
    },
    {
      icon: projectsIcon,
      title: "Mentor - Attrition Rate",
      value: `${
        dashboardDetailByProject?.mentorAttritionRate ? dashboardDetailByProject?.mentorAttritionRate?.toFixed(2) : parseFloat(0.0).toFixed(2)
      }%`,
      color: theme === "light" ? "#FFF7E8" : "#FFD88E15"
    },
    {
      icon: projectsIcon,
      title: "Journey - Attrition Rate",
      value: `${
        dashboardDetailByProject?.journeyAttritionRate ? dashboardDetailByProject?.journeyAttritionRate.toFixed(2) : parseFloat(0.0).toFixed(2)
      }%`,
      color: theme === "light" ? "#E6F5F9" : "#81E5FF15"
    },
    {
      icon: projectsIcon,
      title: "Non-Trade - Attrition Rate",
      value: `${
        dashboardDetailByProject?.nonTradeAttritionRate ? dashboardDetailByProject?.nonTradeAttritionRate.toFixed(2) : parseFloat(0.0).toFixed(2)
      }%`,
      color: theme === "light" ? "#EFF6FC" : "#72BEFF15"
    }
  ];

  const columns = useColumn([
    {
      field: "externalJobTitle",
      headerName: "Position",
      valueGetter: params => {
        if (params.row.isExternalJobTitle) {
          return params.row.externalJobTitle;
        } else {
          return params.row.jobTitle;
        }
      }
    },
    {
      field: "projectName",
      headerName: "Project Name"
    },
    {
      field: "compliant",
      headerName: "Compliant"
    },
    currencyColumnDef({ field: "wageRate", headerName: "PW Wage Rate" }),
    currencyColumnDef({ field: "fringe", headerName: "PW Fringe" }),
    currencyColumnDef({ field: "pwTotalHourlyComp", headerName: "PW Total Hourly Comp" }),
    currencyColumnDef({ field: "marketRate", headerName: "Martet Rate" }),
    percentageColumnDef({ field: "pwAiConfidenceMatchScore", headerName: "PW AI % CONFIDENCE MATCH" })
  ]);

  return (
    <Layout>
      <div className="dashboard">
        <div className="dashboard_container">
          <div className="dashboard_section_dropdown">
            <Grid className="proInputWrapper" item mt={0} md={12} xs={12}>
              <FormControl className="proInputWithMessage" fullWidth>
                <div className="proInputWithMessage-content">
                  <InputLabel id="project">Project</InputLabel>
                  <Select
                    labelId="project"
                    id="project"
                    value={selectedProject}
                    name="project"
                    onChange={event => {
                      setSelectedProject(event.target.value);
                      setSelectedProjectLabel(projects.find(option => option.value === event.target.value).label);
                      fetchDashboardDetail(event.target.value);
                      fetchComplianceData(event.target.value);
                    }}
                    autoWidth
                    label="Project"
                  >
                    {genrateOptions(projects)}
                  </Select>
                </div>
              </FormControl>
            </Grid>
          </div>
          <div className="dashboard_section">
            <div className="dashboard_section_top">
              {data.map(item => {
                return <CradsTopSection key={item.icon} {...item} />;
              })}
            </div>
            <div className="dashboard_section_charts">
              <Grid item mt={5}>
                <Grid container spacing={2} direction="row" justifyContent="center" alignItems="stretch">
                  <Grid item sm={12} md={6} lg={6} xs={12}>
                    <Card
                      sx={{
                        minHeight: "100%",
                        backgroundColor: theme === "dark" ? "#15171C" : "",
                        border: `1px solid ${theme === "dark" ? "#1E2027" : "transparent"}`
                      }}
                    >
                      <CardHeader
                        title={<Typography className="dashboard_section_charts_heading">IRA Workforce Compliance</Typography>}
                        sx={{ color: theme === "dark" ? "#ffffff" : "" }}
                      />
                      <Divider sx={{ mb: 3, border: ` ${theme === "dark" ? " 1px solid #1E2027" : ""}` }} />
                      <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
                        {dashboardDetailByProject && (
                          <DBAChart
                            circleRatio={1}
                            styles={buildStyles({ rotation: 1 / 2 + 1 / 9.5 })}
                            value={dashboardDetailByProject?.apprenticePer}
                            strokeWidth={13}
                            text={`${dashboardDetailByProject ? dashboardDetailByProject?.apprenticePer : "0"}%`}
                            color="success"
                            size="xxlarge"
                            theme
                          />
                        )}
                      </Stack>
                      <Stack spacing={2} mt={6} direction={{ xs: "column", sm: "row" }} alignItems="center" justifyContent="center" width="100%">
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Box
                            sx={{
                              backgroundColor: "#018749",
                              width: 20,
                              height: 20
                            }}
                          />
                          <Typography color="black" className="dashboard_section_charts_heading" sx={{ color: theme === "dark" ? "#ffffff" : "" }}>
                            Apprentice Hours
                          </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Box
                            sx={{
                              backgroundColor: "#e8eaee",
                              width: 20,
                              height: 20
                            }}
                          />
                          <Typography color="black" className="dashboard_section_charts_heading" sx={{ color: theme === "dark" ? "#ffffff" : "" }}>
                            Non-Apprentice Hours
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack direction="row" flexWrap="wrap" sx={{ justifyContent: { xs: "center", md: "space-around" } }} alignItems="center">
                        <Box sx={{ p: 2, width: "50%" }}>
                          <Tooltip placement="top" title="Total Workforce Hours" arrow>
                            <Typography
                              className="dashboard_section_charts_sub-heading"
                              color="black"
                              sx={{ color: theme === "dark" ? "#B5B7C8" : "" }}
                            >
                              Total Workforce
                            </Typography>
                          </Tooltip>
                          <Typography
                            className="dashboard_section_charts_heading"
                            fontWeight="bold"
                            sx={{ color: theme === "dark" ? "#FFFFFF" : "" }}
                          >
                            {`${Math.floor(dashboardDetailByProject?.totalWorkforce)} Hrs ${(
                              (dashboardDetailByProject?.totalWorkforce * 60) %
                              60
                            ).toFixed(0)} Min`}
                          </Typography>
                        </Box>
                        <Box sx={{ p: 2, width: "50%" }}>
                          <Tooltip placement="top" title="Apprentice OTJ Hours" arrow>
                            <Typography
                              className="dashboard_section_charts_sub-heading"
                              color="black"
                              sx={{ color: theme === "dark" ? "#B5B7C8" : "" }}
                            >
                              Total Apprentice Hours
                            </Typography>
                          </Tooltip>
                          <Typography
                            className="dashboard_section_charts_heading"
                            fontWeight="bold"
                            sx={{ color: theme === "dark" ? "#FFFFFF" : "" }}
                          >
                            {`${Math.floor(dashboardDetailByProject?.apprenticeOTJ)} Hrs ${(
                              (dashboardDetailByProject?.apprenticeOTJ * 60) %
                              60
                            ).toFixed(0)} Min`}
                          </Typography>
                        </Box>
                        <Box sx={{ p: 2, width: "100%" }}>
                          <Tooltip fontSize={10} placement="top" title="Non Apprentice-to-Apprentice Hours" arrow>
                            <Typography className="dashboard_section_charts_sub-heading" sx={{ color: theme === "dark" ? "#B5B7C8" : "" }}>
                              Non Apprentice-to-Apprentice Hours
                            </Typography>
                          </Tooltip>
                          <Box sx={{ display: "flex", justifyContent: "center" }}>
                            <Typography
                              sx={{
                                display: "inline-block",
                                fontSize: 20,
                                fontWeight: "bold",
                                color: dashboardDetailByProject?.apprenticePer < dashboardDetailByProject?.minThreshold ? "red" : "green"
                              }}
                            >
                              {dashboardDetailByProject?.apprenticePer}%
                            </Typography>
                            <div
                              style={{
                                display: "inline-block",
                                backgroundColor: "rgba(87, 202, 34, 0.1)",
                                padding: "4.5px 9px",
                                marginInline: "2px",
                                borderRadius: "10px",
                                color: theme === "dark" ? "#FFFFFF" : ""
                              }}
                            >
                              {dashboardDetailByProject?.minThreshold?.toLocaleString("en-US", { minimumFractionDigits: 2 })}% Min Threshold
                            </div>
                          </Box>
                        </Box>
                      </Stack>
                    </Card>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Card
                      sx={{
                        minHeight: "80%",
                        backgroundColor: theme === "dark" ? "#15171C" : "",
                        border: `1px solid ${theme === "dark" ? "#1E2027" : "transparent"}`
                      }}
                    >
                      <CardHeader
                        title={
                          <Box>
                            <Typography className="dashboard_section_charts_heading" sx={{ color: theme === "dark" ? "#FFFFFF" : "" }}>
                              Workforce Hours Worked
                            </Typography>
                          </Box>
                        }
                      />
                      <Divider sx={{ border: ` ${theme === "dark" ? " 1px solid #1E2027" : ""}` }} />
                      <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
                        {dashboardDetailByProject && (
                          <WorkforceHoursChart
                            tradeHours={dashboardDetailByProject?.tradeHours}
                            apprenticeHours={dashboardDetailByProject?.apprenticeHours}
                            nonTradeHours={dashboardDetailByProject?.nonTradeHours}
                            workingHours={dashboardDetailByProject?.totalWorkingHours}
                          />
                        )}
                      </Stack>
                      <Stack spacing={2} direction={{ xs: "column", sm: "row" }} alignItems="center" justifyContent="center" width="100%">
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Box
                            sx={{
                              backgroundColor: "#FF033E",
                              width: 20,
                              height: 20
                            }}
                          />
                          <Typography color="black" className="dashboard_section_charts_heading" sx={{ color: theme === "dark" ? "#B5B7C8" : "" }}>
                            Craft/Trade Labor
                          </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Box
                            sx={{
                              backgroundColor: "#FFC72C",
                              width: 20,
                              height: 20
                            }}
                          />
                          <Typography color="black" className="dashboard_section_charts_heading" sx={{ color: theme === "dark" ? "#B5B7C8" : "" }}>
                            Apprentice
                          </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Box
                            sx={{
                              backgroundColor: "#018749",
                              width: 20,
                              height: 20
                            }}
                          />
                          <Typography color="black" className="dashboard_section_charts_heading" sx={{ color: theme === "dark" ? "#B5B7C8" : "" }}>
                            Non-Trade/Craft
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack
                        direction="row"
                        flexWrap="wrap"
                        sx={{
                          justifyContent: {
                            xs: "center",
                            md: "space-around"
                          }
                        }}
                        alignItems="center"
                      >
                        <Box sx={{ p: 2, width: "33.33%" }}>
                          <Tooltip placement="top" title="Craft/Trade Labor" arrow>
                            <Typography className="dashboard_section_charts_sub-heading" sx={{ color: theme === "dark" ? "#B5B7C8" : "" }}>
                              Craft/Trade Labor
                            </Typography>
                          </Tooltip>

                          <Typography className="dashboard_section_charts_heading" sx={{ color: theme === "dark" ? "#FFFFFF" : "" }}>
                            {`${Math.floor(dashboardDetailByProject?.tradeHours)} Hrs ${((dashboardDetailByProject?.tradeHours * 60) % 60).toFixed(
                              0
                            )} Min`}
                          </Typography>
                        </Box>
                        <Box sx={{ p: 2, width: "33.33%" }}>
                          <Tooltip placement="top" title="Apprentice" arrow>
                            <Typography className="dashboard_section_charts_sub-heading" sx={{ color: theme === "dark" ? "#B5B7C8" : "" }}>
                              Apprentice
                            </Typography>
                          </Tooltip>
                          <Typography className="dashboard_section_charts_heading" sx={{ color: theme === "dark" ? "#FFFFFF" : "" }}>
                            {`${Math.floor(dashboardDetailByProject?.apprenticeHours)} Hrs ${(
                              (dashboardDetailByProject?.apprenticeHours * 60) %
                              60
                            ).toFixed(0)} Min`}
                          </Typography>
                        </Box>
                        <Box sx={{ p: 2, width: "33.33%" }}>
                          <Tooltip fontSize={10} placement="top" title="Non-Trade/Craft" arrow>
                            <Typography className="dashboard_section_charts_sub-heading" sx={{ color: theme === "dark" ? "#B5B7C8" : "" }}>
                              Non-Trade/Craft
                            </Typography>
                          </Tooltip>
                          <Typography className="dashboard_section_charts_heading" sx={{ color: theme === "dark" ? "#FFFFFF" : "" }}>
                            {`${Math.floor(dashboardDetailByProject?.nonTradeHours)} Hrs ${(
                              (dashboardDetailByProject?.nonTradeHours * 60) %
                              60
                            ).toFixed(0)} Min`}
                          </Typography>
                        </Box>
                        <Box sx={{ p: 2, width: "100%" }}>
                          <Tooltip fontSize={10} placement="top" title="Non-Trade/Craft" arrow>
                            <Typography className="dashboard_section_charts_sub-heading" sx={{ color: theme === "dark" ? "#B5B7C8" : "" }}>
                              Actual Worked Hours:
                            </Typography>
                          </Tooltip>
                          <Typography className="dashboard_section_charts_heading" sx={{ color: theme === "dark" ? "#FFFFFF" : "" }}>
                            {`${Math.floor(dashboardDetailByProject?.totalWorkingHours)} Hrs ${(
                              (dashboardDetailByProject?.totalWorkingHours * 60) %
                              60
                            ).toFixed(0)} Min`}
                          </Typography>
                        </Box>
                      </Stack>
                    </Card>
                  </Grid>
                </Grid>
                <Grid container mt={3} spacing={2} direction="row" justifyContent="center" alignItems="stretch">
                  <Grid item sm={12} md={6} lg={6} xs={12}>
                    <Card
                      sx={{
                        minHeight: "100%",
                        backgroundColor: theme === "dark" ? "#15171C" : "",
                        border: `1px solid ${theme === "dark" ? "#1E2027" : "transparent"}`
                      }}
                    >
                      <CardHeader
                        title={<Typography className="dashboard_section_charts_heading">RTI and RTI Remaining</Typography>}
                        sx={{ color: theme === "dark" ? "#ffffff" : "" }}
                      />
                      <Divider sx={{ mb: 3, border: ` ${theme === "dark" ? " 1px solid #1E2027" : ""}` }} />
                      <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
                        {dashboardDetailByProject && (
                          <RTIChart
                            rtiPercent={dashboardDetailByProject?.rtiRemaining?.progressRti || 0}
                            rtiEstimatePercent={dashboardDetailByProject?.rtiRemaining?.estimatedRti || 0}
                          />
                        )}
                      </Stack>
                      <Stack spacing={2} direction={{ xs: "column", sm: "row" }} alignItems="center" justifyContent="center" width="100%">
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Box
                            sx={{
                              backgroundColor: "#018749",
                              width: 20,
                              height: 20
                            }}
                          />
                          <Typography color="black" className="dashboard_section_charts_heading" sx={{ color: theme === "dark" ? "#ffffff" : "" }}>
                            RTI
                          </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Box
                            sx={{
                              backgroundColor: "#e8eaee",
                              width: 20,
                              height: 20
                            }}
                          />
                          <Typography color="black" className="dashboard_section_charts_heading" sx={{ color: theme === "dark" ? "#ffffff" : "" }}>
                            RTI Remaining
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack
                        direction="row"
                        flexWrap="wrap"
                        sx={{
                          justifyContent: {
                            xs: "center",
                            md: "space-around"
                          }
                        }}
                        alignItems="center"
                      >
                        <Box sx={{ p: 2 }}>
                          <Tooltip placement="top" title="Total Workforce Hours" arrow>
                            <Typography className="dashboard_section_charts_sub-heading" sx={{ color: theme === "dark" ? "#B5B7C8" : "" }}>
                              RTI Taken
                            </Typography>
                          </Tooltip>
                          <Typography className="dashboard_section_charts_heading" sx={{ color: theme === "dark" ? "#FFFFFF" : "" }}>
                            {`${Math.floor(dashboardDetailByProject?.rtiRemaining?.progressRti)} Hrs ${(
                              (dashboardDetailByProject?.rtiRemaining?.progressRti * 60) %
                              60
                            ).toFixed(0)} Min`}
                          </Typography>
                        </Box>
                        <Box sx={{ p: 2 }}>
                          <Tooltip placement="top" title="Apprentice OTJ Hours" arrow>
                            <Typography className="dashboard_section_charts_sub-heading" sx={{ color: theme === "dark" ? "#B5B7C8" : "" }}>
                              RTI Goal
                            </Typography>
                          </Tooltip>
                          <Typography className="dashboard_section_charts_heading" sx={{ color: theme === "dark" ? "#FFFFFF" : "" }}>
                            {`${Math.floor(dashboardDetailByProject?.rtiRemaining?.estimatedRti)} Hrs ${(
                              (dashboardDetailByProject?.rtiRemaining?.estimatedRti * 60) %
                              60
                            ).toFixed(0)} Min`}
                          </Typography>
                        </Box>
                        <Box sx={{ p: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
                          <Tooltip fontSize={10} placement="top" title="Non Apprentice-to-Apprentice Hours" arrow>
                            <Typography className="dashboard_section_charts_sub-heading" sx={{ color: theme === "dark" ? "#B5B7C8" : "" }}>
                              RTI Achieved %
                            </Typography>
                          </Tooltip>
                          <Typography className="dashboard_section_charts_heading" sx={{ display: "inline-block", color: "green" }}>
                            {(
                              (dashboardDetailByProject?.rtiRemaining?.progressRti / dashboardDetailByProject?.rtiRemaining?.estimatedRti) *
                              100
                            ).toFixed(2) || "0"}
                            %
                          </Typography>
                        </Box>
                      </Stack>
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={6} xs={12}>
                    <Card
                      sx={{
                        minHeight: "80%",
                        backgroundColor: theme === "dark" ? "#15171C" : "",
                        border: `1px solid ${theme === "dark" ? "#1E2027" : "transparent"}`
                      }}
                    >
                      <CardHeader
                        title={<Typography className="dashboard_section_charts_heading">Lost OTJ Over Time</Typography>}
                        sx={{ color: theme === "dark" ? "#ffffff" : "" }}
                      />
                      <Divider sx={{ mb: 3, border: ` ${theme === "dark" ? " 1px solid #1E2027" : ""}` }} />
                      {dashboardDetailByProject && (
                        <Stack spacing={2} direction={{ xs: "column", sm: "row" }} alignItems="center" justifyContent="center" width="100%">
                          <Stack direction="row" spacing={1}>
                            <Typography color="black" className="dashboard_section_charts_heading" sx={{ color: theme === "dark" ? "#ffffff" : "" }}>
                              {`${Math.floor(dashboardDetailByProject?.totalOtjAndLostOtjHours?.totalOTJHours)} Hrs ${(
                                (dashboardDetailByProject?.totalOtjAndLostOtjHours?.totalOTJHours * 60) %
                                60
                              ).toFixed(0)} Min`}{" "}
                              Total OTJ Hours
                            </Typography>
                          </Stack>
                          <Stack direction="row" spacing={1}>
                            <Typography color="black" className="dashboard_section_charts_heading" sx={{ color: theme === "dark" ? "#ffffff" : "" }}>
                              {`${Math.floor(dashboardDetailByProject?.totalOtjAndLostOtjHours?.totalLostOTJHours)} Hrs ${(
                                (dashboardDetailByProject?.totalOtjAndLostOtjHours?.totalLostOTJHours * 60) %
                                60
                              ).toFixed(0)} Min`}{" "}
                              OTJ Hours Lost
                            </Typography>
                          </Stack>
                        </Stack>
                      )}
                      <Grid container p={4} spacing={2}>
                        <Grid item sm={12} xs={12}>
                          {dashboardDetailByProject && <LostOTJChart lostOtjData={dashboardDetailByProject?.totalOtjAndLostOtjHours} />}
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
                <Grid container mt={3} spacing={2} direction="row" justifyContent="center" alignItems="stretch">
                  <Grid item sm={12} md={6} lg={6} xs={12}>
                    {selectedProject !== "All" && (
                      <Grid item sm={12} md={12} lg={12} xs={12}>
                        <Card
                          sx={{
                            minHeight: "80%",
                            backgroundColor: theme === "dark" ? "#15171C" : "",
                            border: `1px solid ${theme === "dark" ? "#1E2027" : "transparent"}`
                          }}
                        >
                          <CardHeader
                            title={
                              <Typography fontSize={18} fontWeight="700">
                                WPS Status - {selectedProjectLabel}
                              </Typography>
                            }
                            sx={{ color: theme === "dark" ? "#ffffff" : "" }}
                          />
                          <Divider sx={{ mb: 3, border: ` ${theme === "dark" ? " 1px solid #1E2027" : ""}` }} />
                          <Grid container p={4} spacing={2}>
                            <Grid item sm={12} xs={12}>
                              {dashboardDetailByProject?.wpsData?.length > 0 ? (
                                <ProjectWPSStatus wpsStatusData={dashboardDetailByProject?.wpsData} />
                              ) : (
                                "No WPS Data to Display"
                              )}
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item sm={12} md={6} lg={6} xs={12}></Grid>
                </Grid>
              </Grid>
            </div>
            <div className="dashboard_section_table">
              <ProGrid
                title="Project Compliance: Confidence Match Score (%)"
                columns={columns}
                loading={status === STAUTES.LOADING}
                rows={complianceDetails}
                hideAddButton={true}
                hideExport={true}
                hideSearch={true}
                options={{
                  getRowId: row => row.projectJobTitleId,
                  pagination: false,
                  onPaginationModelChange: setPagination,
                  style: { maxHeight: "500px" }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
