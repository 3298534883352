import React, { useCallback, useEffect } from "react";
import ProGrid from "../../../Components/ProGrid/v2";
import { Box } from "@mui/material";
import useColumn from "../../../../utils/customHooks/useColumns";
import { postApi } from "../../../../utils/services";
import { STAUTES } from "../../../../utils/constant";
import { errorToast } from "../../../../utils/toastHelper";
import { APPRENTICE_MENTOR_HISTORY } from "../../../../utils/services/apiPath";
import usePageState from "../../../../utils/customHooks/usePageState";
import { dateColumnDef } from "../../../Components/ProGrid/components/FormatCell";
import PropTypes from "prop-types";

const ApprenticeMentorHistory = ({ actionData }) => {
  const {
    currentPage,
    pageSize,
    data: employeeData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();

  const employeeId = actionData?.employeeId;

  const getAllApprenticeHistory = useCallback(
    async employeeId => {
      setStatus(STAUTES.LOADING);
      const payload = {
        pageIndex: searchString ? 1 : currentPage + 1,
        pageSize,
        searchString: searchString,
        orderByAscending: false,
        orderCol: "workDate",
        ...sortModel
      };

      const { data, totalRecords, error } = await postApi(APPRENTICE_MENTOR_HISTORY, payload, { params: { employeeGuid: employeeId } });
      setStatus(STAUTES.IDLE);

      if (error) return errorToast(error);
      const formattedData = (data || []).map((item, index) => ({ ...item, id: index + 1 }));
      setPageState(prevPageInfo => ({ ...prevPageInfo, data: formattedData, rowCount: totalRecords }));
    },
    [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]
  );

  useEffect(() => {
    if (employeeId) getAllApprenticeHistory(employeeId);
  }, [getAllApprenticeHistory, employeeId]);

  const columns = useColumn(
    [
      {
        field: "mentorName",
        headerName: "Mentor Name",
        renderCell: params => (
          <Box>{params.row.mentorFirstName && params.row.mentorLastName ? `${params.row.mentorFirstName} ${params.row.mentorLastName}` : "N/A"}</Box>
        )
      },
      {
        field: "apprenticeName",
        headerName: "Apprentice Name",
        renderCell: params => (
          <Box>
            {params.row.apprenticeFirstName && params.row.apprenticeLastName
              ? `${params.row.apprenticeFirstName} ${params.row.apprenticeLastName}`
              : "N/A"}
          </Box>
        )
      },
      dateColumnDef({
        field: "workDate",
        headerName: "Work Date"
      })
    ],
    false
  );
  return (
    <div style={{ width: "100%", margin: "2rem auto", maxWidth: "1440px" }}>
      <ProGrid
        title="Apprentice Mentor History"
        columns={columns}
        loading={status === STAUTES.LOADING}
        rows={employeeData}
        searchMode={!!searchString}
        searchModel={{ defaultValue: searchString, handleDebounce: setSearchString }}
        hideAddButton={true}
        hideExport={true}
        options={{
          getRowId: row => row.id,
          rowCount: rowCount,
          paginationMode: "server",
          paginationModel: { pageSize: pageSize, page: currentPage },
          onPaginationModelChange: setPagination,
          sortingMode: "server",
          onSortModelChange: setSortModel
        }}
      />
    </div>
  );
};

export default ApprenticeMentorHistory;

ApprenticeMentorHistory.propTypes = {
  actionData: PropTypes.object
};
