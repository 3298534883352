import React, { useMemo } from "react";
import FormControl from "@mui/material/FormControl";
import InputWrapper from "./InputWrapper";
import ReadOnlyInput from "./ReadOnlyInput";
import { Controller } from "react-hook-form";
import { Autocomplete, FormHelperText, TextField } from "@mui/material";
import { DEFAULT_SIZE } from "../../../utils/constant";
import PropTypes from "prop-types";
import { sortOptions } from "../../../utils/formHelper";

export default function ProMultiSelectSelectField({
  name,
  mode,
  label,
  rules,
  control,
  isAddMode,
  infoMessage,
  options = [],
  size = DEFAULT_SIZE,
  startAdornment = null,
  withInfoIcon = false,
  fieldProps = {},
  ...rest
}) {
  const sortedOptions = useMemo(() => sortOptions(options), [options]);
  
  const { canView = false, canAdd = false, canEdit = false } = rest.permission || {};
  if (!canView || (isAddMode && !canAdd)) return null;
  const editModeAllowed = isAddMode ? canAdd : canEdit;
  if (mode === "read" || (mode === "edit" && !editModeAllowed))
    return <ReadOnlyInput type="multiSelect" size={size} label={label} name={name} {...rest} />;

  return (
    <InputWrapper size={size}>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormControl className="proInputWithMessage" fullWidth>
            <Autocomplete
              multiple
              options={sortedOptions}
              value={value || []}
              onChange={(e, value) => onChange(value)}
              renderInput={params => (
                <TextField
                  {...params}
                  label={label}
                  InputLabelProps={{ shrink: true }}
                  placeholder={value?.length ? "" : label}
                  variant="outlined"
                  fullWidth
                />
              )}
              {...fieldProps}
            />
            <FormHelperText error={!!error}>{error?.message}</FormHelperText>
          </FormControl>
        )}
      />
    </InputWrapper>
  );
}

ProMultiSelectSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(["read", "edit"]).isRequired,
  label: PropTypes.string.isRequired,
  rules: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,
  isAddMode: PropTypes.bool,
  withInfoIcon: PropTypes.bool,
  size: PropTypes.string,
  infoMessage: PropTypes.string,
  startAdornment: PropTypes.node,
  fieldProps: PropTypes.object,
  options: PropTypes.array,
  permission: PropTypes.shape({
    canView: PropTypes.bool,
    canAdd: PropTypes.bool,
    canEdit: PropTypes.bool
  })
};
