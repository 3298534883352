import React, { useState } from "react";
import Layout from "../../Components/Layout";
import "./index.scss";
import FileUploadField from "../../Components/Inputs/FileUploadField";
import { useForm } from "react-hook-form";
import ProButton from "../../Components/ProButton";
import { convertFileToBase64, fieldTruePermission } from "../../../utils/formHelper";
import { postApi } from "../../../utils/services";
import { errorToast, successToast } from "../../../utils/toastHelper";
import { useNavigate } from "react-router";
import { STAUTES } from "../../../utils/constant";
import { useSelector } from "react-redux";
import { userSelector } from "../../store/features/auth/authSlice";

export default function MyAccount() {
  const user = useSelector(userSelector);
  const { handleSubmit, control, watch } = useForm({ defaultValues: { profile: null } });
  const [status, setStatus] = useState(STAUTES.IDLE);
  const navigate = useNavigate();
  const profile = watch("profile");
  const onUpload = async () => {
    try {
      const byteArray = await convertFileToBase64(profile);
      const payload = {
        fileName: profile?.name,
        byteArray: byteArray
      };
      setStatus(STAUTES.LOADING);
      const { error, data } = await postApi(`/User/UpdateClientUserProfile?clientUserGuid=${user.id}`, payload);
      setStatus(STAUTES.IDLE);
      if (error) return errorToast(error);
      successToast("Logo successfully updated");
      navigate("/dashboard");
      if (data.path) {
        localStorage.setItem("user", JSON.stringify({ ...user, clientUserProfilePicture: data.path }));
      }
    } catch (error) {
      setStatus(STAUTES.IDLE);
      errorToast(error);
    }
  };
  return (
    <Layout status={status}>
      <div className="proForm-layout proSettingForm">
        <h1>My Account</h1>
        <div className="upload-profile-picture">
          <FileUploadField control={control} permission={fieldTruePermission} name="profile" label="Profile Picture" mode="edit" />
        </div>
        <ProButton disabled={!profile} onClick={handleSubmit(onUpload)}>
          Upload
        </ProButton>
      </div>
    </Layout>
  );
}
