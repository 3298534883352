import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./RAPWageScale.scss";
import ProGrid from "../../../Components/ProGrid/v2";
import DeleteCell from "../../../Components/ProGrid/components/DeleteCell";
import ProTextInput from "../../../Components/Inputs/TextField";
import { useForm } from "react-hook-form";
import ProSelectField from "../../../Components/Inputs/SelectField";
import FormSectionWrapper from "../../../Components/ProForms/FormSectionWrapper";
import { useParams } from "react-router-dom";
import NameCell from "../../../Components/ProGrid/components/NameCell";
import {
  ADD_WAGE_SCALE,
  ADD_WAGE_SCALE_DETAILS,
  DELETE_RAPWAGESCALE_LIST,
  DELETE_WAGE_SCALE_DETAILS,
  DYNAMIC_DROPDOWN,
  GET_ALL_RAPWAGESCALE_LIST,
  GET_ALL_STATES,
  GET_ALL_WAGE_RATE_DETAILS,
  UPDATE_WAGE_SCALE
} from "../../../../utils/services/apiPath";
import { deleteApi, getApi, postApi } from "../../../../utils/services";
import { errorToast, successToast } from "../../../../utils/toastHelper";
import { STAUTES } from "../../../../utils/constant";
import usePageState from "../../../../utils/customHooks/usePageState";
import { defaultDropdownConfig } from "../../../../utils/dropdownHelper";
import { changeMode, changeModeForField, prepareDefaultValues, prepareInitialConfig } from "../../../../utils/formHelper";
import TabGridAndFormLayout from "../../../Components/ProForms/ProFormsLayout/TabGridAndFormLayout";
import { Button, Grid, IconButton, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { rapMgmtWageScalePermissionSelector } from "../../../store/features/permissions/permissionsSlice";
import usePermission from "../../../../utils/customHooks/usePermission";
import useColumn from "../../../../utils/customHooks/useColumns";
import { useTheme } from "../../../ContextStore/ThemeContext";

const RapMgmtWageScaleFormConfing = {
  wageScaleTitle: {
    label: "Wage Scale Title",
    perKey: "WageScaleTitle"
  },
  state: {
    label: "State",
    perKey: "State"
  },
  journeymanWage: {
    label: "Journeyman Wage",
    perKey: "JourneyManWage"
  },
  wageRateUnit: {
    label: "Wage Rate Unit",
    perKey: "WageRateUnit"
  },
  wageRateType: {
    label: "Wage Rate Type",
    perKey: "WageRateType"
  },
  fromHours: {
    label: "From Hours",
    perKey: "FromHours"
  },
  toHours: {
    label: "To Hours",
    perKey: "ToHours"
  },
  wageRate: {
    label: "Wage Rate ",
    perKey: "WageRate"
  }
};

const requiredFormNames = ["Assign RAP Info", "Wage Rate", "Wage Scale List"];

export default function RAPApprentice() {
  const { id } = useParams();
  const [states, setStates] = useState([]);
  const [showGrid, setShowGrid] = useState(true);
  const [rapMgmtWgeScaleData, setRapMgmtWgeScaleData] = useState({});
  const [actionData, setActionData] = useState({});
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(RapMgmtWageScaleFormConfing));
  const {
    currentPage,
    pageSize,
    data: rapWageScaleData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();
  const [wageScaleDropDownType, setWageScaleDropDownType] = useState([]);
  const [wageRateDropDownType, setWageRateDropDownType] = useState([]);
  const [wageRateTableData, setWageRateTableData] = useState([]);
  const [wageRateFormData, setWageRateFormData] = useState([]);
  const {
    "Assign RAP Info": assignRapInfo,
    "Wage Rate": addWageRate,
    "Wage Scale List": wageScaleListPermission
  } = usePermission(rapMgmtWageScalePermissionSelector, requiredFormNames);
  const { theme } = useTheme();
  const cellStyles = theme === "dark" ? { color: "#ffffff" } : { color: "#00050B" };
  const [isSaving, setIsSaving] = useState(false);
  const columns = useColumn(
    [
      {
        field: "wageScaleTitle",
        headerName: "Wage Scale Title ",
        renderCell: params => <NameCell params={params} onClick={onWageScale} />
      },
      {
        field: "state",
        headerName: "State"
      },
      {
        field: "assignRAPWageScaleId",
        headerName: "Actions",
        isDeleteColumn: true,
        disableExport: true,
        width: 80,
        renderCell: params => <DeleteCell title={"Delete Rap Wage Scale"} params={params} onDelete={onDelete} />
      }
    ],
    wageScaleListPermission.canDelete
  );

  const { handleSubmit, control, setValue, clearErrors, getValues, resetField, setError } = useForm({
    defaultValues: prepareDefaultValues(RapMgmtWageScaleFormConfing)
  });
  const isEditModeOn = useMemo(() => Object.values(formConfig).some(({ mode }) => mode === "edit"), [formConfig]);

  const validateToHours = toHours => {
    if (!toHours || isNaN(parseInt(toHours))) {
      return "To Hours is required";
    }
    return null;
  };

  const validateWageRate = wageRate => {
    if (!wageRate || isNaN(parseInt(wageRate))) {
      return "Wage Rate is required";
    }
    return null;
  };

  const validateWageRateFormData = (toHours, wageRate, wageRateFormData) => {
    const validationErrors = {};
    if (wageRateFormData.length > 0) {
      const prevObject = wageRateFormData[wageRateFormData.length - 1];
      if (parseInt(toHours) <= prevObject.endHours + 1) {
        validationErrors.toHours = "To Hours should be greater than the previous entry's, but not equal to the current From Hours value.";
      }
      if (parseFloat(wageRate) <= prevObject.wageRate) {
        validationErrors.wageRate = "Wage Rate should be greater than previous entry's wageRate";
      }
    }
    return validationErrors;
  };

  const handleWageRate = async () => {
    const data = getValues();
    if (data) {
      const { toHours, wageRate } = data;
      const validationErrors = {};

      const toHoursError = validateToHours(toHours);
      const wageRateError = validateWageRate(wageRate);

      if (toHoursError) validationErrors.toHours = toHoursError;
      if (wageRateError) validationErrors.wageRate = wageRateError;

      const formDataErrors = validateWageRateFormData(toHours, wageRate, wageRateFormData);
      Object.assign(validationErrors, formDataErrors);

      if (Object.keys(validationErrors).length > 0) {
        Object.keys(validationErrors).forEach(fieldName => {
          setError(fieldName, {
            type: "manual",
            message: validationErrors[fieldName]
          });
        });
        return;
      }

      const fromHours = wageRateFormData.length > 0 ? wageRateFormData[wageRateFormData.length - 1].endHours + 1 : 0;
      const patchedObject = {
        assignRAPWageScaleGuid: "",
        startHours: fromHours,
        endHours: parseInt(toHours) ?? "",
        wageRate: parseFloat(wageRate) ?? ""
      };
      setValue("fromHours", parseInt(toHours) + 1);
      setWageRateFormData(prevState => [...prevState, patchedObject]);
    }
    resetField("toHours", "");
    resetField("wageRate", "");
  };

  const handleDeleteCompleted = async () => {
    let lastObject = wageRateFormData[wageRateFormData.length - 1];
    if (lastObject?.assignRAPWageScaleDetailId) {
      const url = DELETE_WAGE_SCALE_DETAILS + `?assignRAPWageScaleDetailId=${lastObject.assignRAPWageScaleDetailId}`;
      await deleteApi(url);
      getAllWateRateDetalis(actionData?.assignRAPWageScaleId);
    }

    setWageRateFormData(prevState => {
      const updatedData = prevState.slice(0, -1);
      if (updatedData.length === 0) {
        setValue("fromHours", 0);
      } else {
        setValue("fromHours", updatedData[updatedData.length - 1].endHours + 1);
      }
      return updatedData;
    });
  };

  const onEdit = () => {
    setFormConfig(changeMode("edit"));
  };

  const handleReadValueclick = name => setFormConfig(changeModeForField(name, "edit"));

  const resetForm = () => {
    setActionData({});
    setShowGrid(true);
    setFormConfig(changeMode("read"));
    clearErrors();
  };

  const onCancel = () => {
    resetForm();
    setShowGrid(true);
    setWageRateFormData([]);
    setValue(null);
  };

  const setSelectedRapData = data => {
    setRapMgmtWgeScaleData(data);
    Object.keys(formConfig).forEach(field => setValue(field, data[field]));
  };

  const getAllWateRateDetalis = async id => {
    const { data } = await getApi(`${GET_ALL_WAGE_RATE_DETAILS}/${id}`);
    setWageRateFormData(data || []);
    setWageRateTableData(data || []);
    const fromHours = data?.length > 0 ? data[data.length - 1].endHours + 1 : 0;
    setRapMgmtWgeScaleData(obj => ({ ...obj, fromHours }));
    setValue("fromHours", fromHours);
  };

  const onWageScale = params => {
    setShowGrid(false);
    setActionData(params.row);
    setSelectedRapData(params.row);
    getAllWateRateDetalis(params.row.assignRAPWageScaleId);
  };

  const addButtonAction = () => {
    Object.keys(formConfig).forEach(field => {
      setValue(field, field === "fromHours" ? 0 : null);
    });
    onEdit();
    setShowGrid(false);
  };

  const onSave = async formData => {
    if (isSaving) return; 
    setIsSaving(true);
    let payload = {
      wageScaleTitle: formData?.wageScaleTitle,
      state: formData?.state,
      wageRateType: formData?.wageRateType,
      wageRateUnit: formData?.wageRateUnit,
      journeymanWage: formData?.journeymanWage,
      county: "",
      assignRAPInfoGuid: id,
      programLength: null,
      assignRAPWageScaleId: actionData?.assignRAPWageScaleId ?? null
    };
    const { error, data } = await postApi(actionData?.assignRAPWageScaleId ? UPDATE_WAGE_SCALE : ADD_WAGE_SCALE, payload);
    setIsSaving(false); 
    if (error) return errorToast(error);
    getRapWageScaleList();
    resetForm();
    setWageRateFormData([]);
    wageRateFormData.forEach(x => {
      x.assignRAPWageScaleGuid = data ?? actionData?.assignRAPWageScaleId;
    });

    const wageRateForm = wageRateFormData.filter(
      wageRateFormDataItem =>
        !wageRateTableData.some(
          wageRateTableDataItem => wageRateTableDataItem.assignRAPWageScaleDetailPKId === wageRateFormDataItem.assignRAPWageScaleDetailPKId
        )
    );
    await postApi(ADD_WAGE_SCALE_DETAILS, wageRateForm);
    successToast(`Rap Management Wage Scale ${actionData?.assignRAPWageScaleId ? "updated" : "added"} successfully.`);
  };

  const onDelete = async id => {
    const { error } = await deleteApi(DELETE_RAPWAGESCALE_LIST, { data: [{ assignRAPWageScaleId: id }] });
    if (error) return errorToast(error);
    getRapWageScaleList();
    setShowGrid(true);
    successToast("Rap Management Wage Scale deleted successfully.");
  };

  const getRapWageScaleList = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const payload = {
      pageIndex: searchString ? 1 : currentPage + 1,
      pageSize: pageSize,
      searchString: searchString,
      orderByAscending: true,
      orderCol: "wageScaleTitle",
      ...sortModel
    };
    const { data, totalRecords, error } = await postApi(GET_ALL_RAPWAGESCALE_LIST, payload, { params: { rapInfoId: id } });
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]);

  const getAllStates = async () => {
    const { data } = await postApi(GET_ALL_STATES, defaultDropdownConfig);
    const temp = [];
    temp?.push({
      label: "All States",
      value: "All States"
    });
    data?.forEach(ele => {
      temp?.push({
        label: ele?.stateName,
        value: ele?.stateAbrv
      });
    });
    setStates(temp);
  };

  const getWageRateDropdown = async () => {
    const payload = ["WAGESCALETYPE", "WAGESCALEUNIT"];
    const data = await postApi(DYNAMIC_DROPDOWN, payload);
    setWageRateDropDownType(data[0]?.typeDropDowns.map(item => ({ label: item.label, value: item.intValue })));
    setWageScaleDropDownType(data[1]?.typeDropDowns.map(item => ({ label: item.label, value: item.intValue })));
  };

  useEffect(() => {
    getRapWageScaleList();
    getAllStates();
    getWageRateDropdown();
  }, [getRapWageScaleList]);

  const defaultFormProps = { control, formValues: rapMgmtWgeScaleData, handleReadValueclick };

  return (
    <div className="rapWage">
      <TabGridAndFormLayout
        showGrid={showGrid}
        title={`${actionData?.assignRAPWageScaleId ? "Edit" : "Add"} Wage Scale`}
        backLabel="Go back to Wage Scale List"
        backAction={() => resetForm()}
        beingEdited={isEditModeOn}
        onEdit={onEdit}
        onSave={handleSubmit(onSave)}
        onCancel={onCancel}
        hideEdit={!wageScaleListPermission?.canEdit}
        canView={wageScaleListPermission?.canView}
        isSaving={isSaving}
      >
        {showGrid && (
          <ProGrid
            title="Wage Scale"
            loading={status === STAUTES.LOADING}
            columns={columns}
            rows={rapWageScaleData}
            searchMode={!!searchString}
            searchModel={{ defaultValue: searchString, handleDebounce: setSearchString }}
            addButtonLabel="+ Add Wage Scale"
            addButtonAction={addButtonAction}
            hideAddButton={!wageScaleListPermission?.canAdd}
            hideExport={!wageScaleListPermission?.canExport}
            options={{
              getRowId: row => row.assignRAPWageScaleId,
              rowCount: rowCount,
              paginationMode: "server",
              paginationModel: { pageSize: pageSize, page: currentPage },
              onPaginationModelChange: setPagination,
              sortingMode: "server",
              onSortModelChange: setSortModel
            }}
          />
        )}
        {!showGrid && (
          <>
            <FormSectionWrapper title={"ASSIGN RAP INFO"}>
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.wageScaleTitle}
                rules={{ required: "Wage Scale Title is required!" }}
                permission={assignRapInfo.fieldPerObj[formConfig.wageScaleTitle.perKey]}
              />
              <ProSelectField
                options={states}
                {...defaultFormProps}
                {...formConfig.state}
                rules={{ required: "State is required!" }}
                permission={assignRapInfo.fieldPerObj[formConfig.state.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.journeymanWage}
                type="number"
                rules={{ required: "Journey Man Wage is required!" }}
                permission={assignRapInfo.fieldPerObj[formConfig.journeymanWage.perKey]}
              />
              <ProSelectField
                options={wageScaleDropDownType}
                {...defaultFormProps}
                {...formConfig.wageRateUnit}
                rules={{ required: "Wage Rate Unit is required!" }}
                permission={assignRapInfo.fieldPerObj[formConfig.wageRateUnit.perKey]}
              />
              <ProSelectField
                options={wageRateDropDownType}
                {...defaultFormProps}
                {...formConfig.wageRateType}
                rules={{ required: "Wage Rate Type is required!" }}
                permission={assignRapInfo.fieldPerObj[formConfig.wageRateType.perKey]}
              />
            </FormSectionWrapper>
            <FormSectionWrapper title={"WAGE RATE"}>
              <ProTextInput
                type="number"
                {...defaultFormProps}
                {...formConfig.fromHours}
                permission={addWageRate.fieldPerObj[formConfig.fromHours.perKey]}
                disabled={true}
              />
              <ProTextInput
                type="number"
                {...defaultFormProps}
                {...formConfig.toHours}
                permission={addWageRate.fieldPerObj[formConfig.toHours.perKey]}
              />
              <ProTextInput
                type="number"
                {...defaultFormProps}
                {...formConfig.wageRate}
                permission={addWageRate.fieldPerObj[formConfig.wageRate.perKey]}
              />
              <Button variant="contained" sx={{ mt: 2, ml: 2 }} onClick={handleWageRate}>
                Add Wage Rate
              </Button>
              {wageRateFormData?.length !== 0 && (
                <Grid item xs={12} md={12}>
                  <Box sx={{ mt: 2 }}>
                    <TableContainer>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow className="table-head-row">
                            <TableCell sx={cellStyles}>Start Hours</TableCell>
                            <TableCell sx={cellStyles}>End Hours</TableCell>
                            <TableCell sx={cellStyles}>Wage Rate</TableCell>
                            <TableCell sx={cellStyles}>Actions</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody sx={{ border: "1px solid #d6ddea" }}>
                          {wageRateFormData?.map((row, index = 0) => (
                            <TableRow
                              key={Number(index)}
                              sx={{
                                "&:last-child td, &:last-child th": { border: 0 }
                              }}
                            >
                              <TableCell sx={cellStyles}>{row.startHours}</TableCell>
                              <TableCell sx={cellStyles}>{row.endHours}</TableCell>
                              <TableCell sx={cellStyles}>${parseFloat(row.wageRate)?.toFixed(2)}</TableCell>
                              <TableCell>
                                <IconButton
                                  disabled={index !== (wageRateFormData?.length ?? 0) - 1}
                                  onClick={() => handleDeleteCompleted()}
                                  color="error"
                                >
                                  <Button className="proGrid-delete" variant="outlined" size="small" color="error">
                                    <DeleteOutlineIcon fontSize="small" color="error" />
                                  </Button>
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Grid>
              )}
            </FormSectionWrapper>
          </>
        )}
      </TabGridAndFormLayout>
    </div>
  );
}
