import { useMemo } from "react";
import { useSelector } from "react-redux";

const usePartnersPermission = (selector, formNames) => {
  const permission = useSelector(selector);

  const modifiedPermission = useMemo(() => {
    const formPermission = {};
    permission.formPermissions?.forEach(form => {
      if (typeof formNames === "string" ? formNames === form.formName : formNames.includes(form.formName)) {
        const fieldPerObj = form.fieldPermissions.reduce((acc, field) => {
          acc[field.fieldName] = field;
          return acc;
        }, {});
        formPermission[form.formName] = { ...form, fieldPerObj };
      }
    });
    return formPermission;
  }, [permission, formNames]);

  return modifiedPermission;
};

export default usePartnersPermission;