import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import ProToggleField from "../../../Components/Inputs/ToggleButton";
import ProTextInput from "../../../Components/Inputs/TextField";
import ProSelectField from "../../../Components/Inputs/SelectField";
import TabGridAndFormLayout from "../../../Components/ProForms/ProFormsLayout/TabGridAndFormLayout";
import ProGrid from "../../../Components/ProGrid/v2";
import NameCell from "../../../Components/ProGrid/components/NameCell";
import DeleteCell from "../../../Components/ProGrid/components/DeleteCell";
import DateField from "../../../Components/Inputs/DateField";
import { Box } from "@mui/material";
import { STAUTES } from "../../../../utils/constant";
import usePageState from "../../../../utils/customHooks/usePageState";
import { errorToast, successToast } from "../../../../utils/toastHelper";
import {
  prepareDefaultValues,
  prepareInitialConfig,
  convertResponseDate,
  fieldTruePermission,
  modifyEmployeeImportData,
  checkEditEnabled,
  changeMode,
  changeModeForField,
  formatKeys,
  formatPayloadDate
} from "../../../../utils/formHelper";
import {
  GET_ALL_PROJECTEMPLOYEE,
  ADD_PROJECT_PROJECTEMPLOYEE,
  UPDATE_PROJECT_PROJECTEMPLOYEE,
  DELETE_PROJECT_PROJECTEMPLOYEE,
  GET_ALL_PROJECTJOBTITLES,
  GET_ALL_MENTOR,
  GET_NonMappedEmployee,
  GET_PROJECT_JOBTITLE,
  GET_ALL_EMPLOYEE_TERM,
  GET_PROJECTEMPLOYEE,
  UPLOAD_PROJECT_EMPLOYEES
} from "../../../../utils/services/apiPath";
import { deleteApi, postApi, getApi } from "../../../../utils/services";
import { R_PROJECTS, R_PROJECTS_EMPLOYEE } from "../../../../utils/permissionReferenceConfig";
import { subModPermissionSelector } from "../../../store/features/permissions/permissionsSlice";
import usePermission from "../../../../utils/customHooks/usePermission";
import { defaultDropdownConfig } from "../../../../utils/dropdownHelper";
import useColumn from "../../../../utils/customHooks/useColumns";
import { currencyColumnDef } from "../../../Components/ProGrid/components/FormatCell";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import ApprenticeMentorHistory from "./ApprenticeMentorHistory";
import FormSectionWrapper from "../../../Components/ProForms/FormSectionWrapper";
import DialogModal from "../../../Components/DialogModel";
import ProCheckbox from "../../../Components/Inputs/CheckboxField";
import { EmployeesExcelData, projectEmployeeFormConfing } from "../projectData";

const fields = EmployeesExcelData?.map(fieldName => ({
  label: fieldName,
  key: fieldName,
  fieldType: { type: "input" }
}));

export default function ProjectEmployees() {
  let { id } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(projectEmployeeFormConfing));
  const { handleSubmit, control, setValue, watch } = useForm({
    defaultValues: prepareDefaultValues(projectEmployeeFormConfing)
  });
  const {
    currentPage,
    pageSize,
    data: employeeData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();
  const { "Add Employee": addEmployeePermission, "Employees List": employeeListPermission } = usePermission(
    subModPermissionSelector(R_PROJECTS, R_PROJECTS_EMPLOYEE),
    ["Add Employee", "Employees List"]
  );
  const [showLogGrid, setShowLogGrid] = useState(false);
  const [showGrid, setShowGrid] = useState(true);
  const [projectEmployeeData, setProjectEmployeeData] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [mentors, setMentors] = useState([]);
  const [jobTitle, setJobTitle] = useState([]);
  const [employeeTermType, setEmployeeTermType] = useState([]);
  const [employeeTermId, setEmployeeTermId] = useState("");
  const [showProjectEmployeesConfirmModal, setShowProjectEmployeesConfirmModal] = useState(false);
  const [apiResponse, setApiResponse] = useState("");
  const fringe = watch("fringe");
  const hourlyWageRate = watch("rate");
  const projectJobTitleId = watch("projectJobTitleId");
  const isApprentice = watch("isApprentice") ?? false;
  const isMentor = watch("isMentor") ?? false;
  const startDate = watch("startDate");
  const [isAddMode, setIsAddMode] = useState(false);

  const isEditModeOn = useMemo(() => checkEditEnabled(false, formConfig), [formConfig]);

  const onEdit = () => setFormConfig(changeMode("edit"));
  const handleReadValueclick = name => setFormConfig(changeModeForField(name, "edit"));

  const resetForm = () => {
    setProjectEmployeeData({});
    setShowGrid(true);
    setFormConfig(changeMode("read"));
  };

  const onCancel = () => {
    if (projectEmployeeData?.employmentTermId) setFormConfig(changeMode("read"));
    else resetForm();
  };

  const onProjectEmployeeClick = params => {
    setShowGrid(false);
    getProjectEmployeeData(params.row.employmentTermId);
    setShowLogGrid(true);
  };

  const addButtonAction = () => {
    Object.keys(formConfig).forEach(field => setValue(field, null));
    onEdit();
    setProjectEmployeeData({});
    setShowGrid(false);
    setShowLogGrid(false);
    setValue("isApprentice", false);
    setValue("isMentor", false);
    getAllEmployees(false);
    setEmployeeTermId("");
    getMentors("");
    setIsAddMode(true);
  };

  const onSave = async data => {
    if (isSaving) return;
    setIsSaving(true);
    const { startDate, endDate, isApprentice, isMentor, isActive, fringe, rate, actualHourlyRate, ...rest } = data;
    const employeeId = projectEmployeeData?.employmentTermId || null;
    const payload = {
      ...rest,
      isApprentice: !!isApprentice,
      isMentor: !!isMentor,
      projectId: id,
      mentorId: null,
      isActive: !!isActive,
      fringe: parseFloat(fringe),
      rate: parseFloat(rate),
      actualHourlyRate: parseFloat(actualHourlyRate),
      employmentTermId: employeeId,
      startDate: formatPayloadDate(startDate),
      endDate: formatPayloadDate(endDate)
    };
    const { error, data: employeeInfo } = await postApi(employeeId ? UPDATE_PROJECT_PROJECTEMPLOYEE : ADD_PROJECT_PROJECTEMPLOYEE, payload);
    setIsSaving(false);
    if (error) return errorToast(error);
    getAllProjectEmployee();
    setFormConfig(changeMode("read"));
    successToast(`Employee ${employeeId ? "Updated" : "Added"} Successfully`);
    const eId = employeeId || employeeInfo?.guidId;
    if (eId) getProjectEmployeeData(eId);
    else resetForm();
  };

  const onDelete = async params => {
    const data = {
      headers: { "Content-Type": "application/json" },
      data: [{ employmentTermId: params }]
    };
    const { error } = await deleteApi(DELETE_PROJECT_PROJECTEMPLOYEE, data);
    if (error) return errorToast(error);
    getAllProjectEmployee();
    setShowGrid(true);
  };

  const getProjectEmployeeData = async employmentTermId => {
    const { data, error } = await getApi(`${GET_PROJECTEMPLOYEE}/${employmentTermId}`);
    if (error) return errorToast(error);
    const obj = {
      ...data,
      startDate: convertResponseDate(data.startDate),
      endDate: convertResponseDate(data.endDate),
      actualHourlyRate: data.actualHourlyTotalComp,
      isApprentice: data.isApprentice,
      isMentor: data.isMentor,
      mentorId: data?.mentorTypeDropDown?.value
    };
    setProjectEmployeeData(obj);
    const newObject = { label: `${data.firstName} ${data.lastName}`, value: data.employeeId };
    getAllEmployees(data.isApprentice, newObject);
    getProjectEmployeeLog(data.employmentTermId);
    if (data.isApprentice) {
      getMentors(data.employmentTermId, data.mentorTypeDropDown);
    }
    Object.keys(formConfig).forEach(field => setValue(field, obj[field]));
  };

  const getAllProjectEmployee = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const payload = {
      pageIndex: searchString ? 1 : currentPage + 1,
      pageSize: pageSize,
      searchString: searchString,
      orderByAscending: true,
      orderCol: "fullName",
      ...sortModel
    };
    const { data, totalRecords, error } = await postApi(GET_ALL_PROJECTEMPLOYEE, payload, { params: { projectId: id } });
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
    return data;
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]);

  const getProjectEmployeeLog = async employeeTermId => {
    setEmployeeTermId(employeeTermId);
  };

  const getAllJobTitles = async () => {
    const { data } = await postApi(`${GET_ALL_PROJECTJOBTITLES}?projectId=${id}`, defaultDropdownConfig);
    setJobTitle(
      data?.map(({ isExternalJobTitle, externalJobTitle, jobTitle, projectJobTitleId }) => ({
        label: isExternalJobTitle === true ? externalJobTitle : jobTitle,
        value: projectJobTitleId
      })) || []
    );
  };

  const getAllEmployeeTermType = async () => {
    const { data } = await postApi(GET_ALL_EMPLOYEE_TERM, defaultDropdownConfig);
    setEmployeeTermType(data?.map(item => ({ label: item.employmentTermType, value: item.employmentTermTypeId })) || []);
  };

  const getAllEmployees = async (isApprentice, preSelectedEmployee) => {
    if (preSelectedEmployee) return setEmployees([preSelectedEmployee]);
    const data = await getApi(`${GET_NonMappedEmployee}/${id}/${!!isApprentice}`);
    setEmployees(data?.map(item => ({ label: item.firstName + " " + item.lastName, value: item.employeeId })) || []);
  };

  const getMentors = async (employeeTermId, preSelectedMentor) => {
    const mentorsdata = await getApi(`${GET_ALL_MENTOR}/${id}?employeeTermGuid=${employeeTermId}`);
    setMentors(mentorsdata.map(item => ({ label: item.firstName + " " + item.lastName, value: item.employeeId })) || []);
    if (preSelectedMentor) return setMentors(...mentors, [preSelectedMentor]);
  };

  const getPerDiem = async item => {
    if (item) {
      const res = await getApi(`${GET_PROJECT_JOBTITLE}/${item}`);
      const actualWageRate = parseFloat(res.data.actualWageRate ?? 0);
      const perDiem = parseFloat(res.data.perDiem ?? 0);
      const fringe = parseFloat(res.data.actualFringe ?? 0);
      setValue("perDiem", perDiem);
      setValue("fringe", fringe);
      setValue("rate", actualWageRate);
      setValue("actualHourlyRate", actualWageRate + fringe);
    }
  };

  const defaultFormProps = { control, formValues: projectEmployeeData, handleReadValueclick };

  const columns = useColumn(
    [
      {
        field: "fullName",
        headerName: "Full Name",
        renderCell: params => <NameCell params={params} onClick={onProjectEmployeeClick} />
      },
      {
        field: "projectJobTitle",
        headerName: "Job Title"
      },
      {
        field: "isActive",
        headerName: "Status",
        renderCell: params => <Box>{params.row.isActive === true ? "Active" : "In Active"}</Box>
      },
      currencyColumnDef({
        field: "ActualHourlyTotalComp",
        headerName: "Actual Total Hourly Comp",
        renderCell: params => <Box>${(params.row.fringe + params.row.rate).toFixed(2)}</Box>
      }),
      currencyColumnDef({
        field: "perDiem",
        headerName: "Perdiem (p/day)"
      }),
      {
        field: "isApprentice",
        headerName: "Apprentice",
        renderCell: params => <Box>{params.row.isApprentice === true ? "Yes" : "No"}</Box>
      },
      {
        field: "isMentor",
        headerName: "Mentor",
        renderCell: params => <Box>{params.row.isMentor === true ? "Yes" : "No"}</Box>
      },
      {
        field: "isJourneyMan",
        headerName: "Journeyman",
        renderCell: params => <Box>{params.row.isJourneyMan === true ? "Yes" : "No"}</Box>
      },
      {
        field: "employmentTermId",
        headerName: "Actions",
        isDeleteColumn: true,
        disableExport: true,
        width: 80,
        renderCell: params => <DeleteCell params={params} onDelete={onDelete} />
      }
    ],
    employeeListPermission.canDelete
  );

  const handleOpenDialogpopup = () => {
    setIsOpen(true);
  };

  const handleCloseDialogpopup = () => {
    setIsOpen(false);
  };

  const handleSubmitImport = async ({ validData }) => {
    try {
      const excelData = formatKeys(validData);
      const payload = { projectId: id, ...modifyEmployeeImportData(excelData) };
      const res = await postApi(UPLOAD_PROJECT_EMPLOYEES, payload);
      setShowProjectEmployeesConfirmModal(true);
      if (res.error) return setApiResponse(res.error);
      setApiResponse(res?.message);
      getAllProjectEmployee();
    } catch (err) {
      errorToast("Project Employees Excel Data Failed to Upoad");
    }
  };

  const handleCloseProjectEmployeesModal = () => {
    setShowProjectEmployeesConfirmModal(false);
  };

  const handleDownload = () => {
    const filePath = "/static/Images/Excel/ProjectEmployeeImportTemplate.xlsx";
    window.open(filePath, "_blank");
  };

  useEffect(() => {
    getAllJobTitles();
    getAllEmployeeTermType();
  }, []);

  useEffect(() => {
    setValue("actualHourlyRate", (parseFloat(fringe || 0) + parseFloat(hourlyWageRate || 0)).toFixed(2));
  }, [fringe, hourlyWageRate]);

  useEffect(() => {
    if (!projectEmployeeData?.employmentTermId) getAllEmployees(isApprentice);
  }, [isApprentice, projectEmployeeData]);

  useEffect(() => {
    getAllProjectEmployee();
  }, [getAllProjectEmployee]);

  useEffect(() => {
    if (isMentor) setValue("isApprentice", false);
  }, [isMentor]);

  useEffect(() => {
    if (isApprentice) setValue("isMentor", false);
  }, [isApprentice]);

  useEffect(() => {
    if (projectJobTitleId && !projectEmployeeData?.employmentTermId) {
      getPerDiem(projectJobTitleId);
    }
  }, [projectJobTitleId]);

  return (
    <TabGridAndFormLayout
      showGrid={showGrid}
      title={"Employee"}
      backLabel="Go back to Employee List"
      backAction={() => resetForm()}
      beingEdited={isEditModeOn}
      onEdit={onEdit}
      onSave={handleSubmit(onSave)}
      onCancel={onCancel}
      hideEdit={!addEmployeePermission.canEdit}
      canView={employeeListPermission.canView}
      isSaving={isSaving}
    >
      {showGrid && (
        <ProGrid
          title="Employee"
          columns={columns}
          loading={status === STAUTES.LOADING}
          rows={employeeData}
          searchMode={!!searchString}
          searchModel={{ defaultValue: searchString, handleDebounce: setSearchString }}
          addButtonLabel="+ Add Employee"
          addButtonAction={addButtonAction}
          hideAddButton={!employeeListPermission.canAdd}
          hideExport={!employeeListPermission.canExport}
          options={{
            getRowId: row => row.employmentTermId,
            rowCount: rowCount,
            paginationMode: "server",
            paginationModel: { pageSize: pageSize, page: currentPage },
            onPaginationModelChange: setPagination,
            sortingMode: "server",
            onSortModelChange: setSortModel
          }}
          showImportExcel={employeeListPermission.canImport}
          showDownloadSampleExcel={employeeListPermission.canImport}
          importExcelAction={handleOpenDialogpopup}
          downloadSampleExcelAction={handleDownload}
          importExcelLabel={"Import Excel"}
          downloadSampleExcelLabel={"Project Employee Import Template"}
        />
      )}
      {!showGrid && (
        <>
          <ProToggleField
            {...defaultFormProps}
            {...formConfig.isApprentice}
            permission={addEmployeePermission.fieldPerObj[formConfig.isApprentice.perKey]}
          />
          <ProToggleField {...defaultFormProps} {...formConfig.isMentor} permission={fieldTruePermission} />
          <ProSelectField
            {...defaultFormProps}
            {...formConfig.projectJobTitleId}
            options={jobTitle}
            permission={addEmployeePermission.fieldPerObj[formConfig.projectJobTitleId.perKey]}
          />
          {isAddMode ? (
            <ProSelectField
              {...defaultFormProps}
              {...formConfig.employeeId}
              options={employees}
              permission={addEmployeePermission.fieldPerObj[formConfig.employeeId.perKey]}
            />
          ) : (
            <ProSelectField
              {...defaultFormProps}
              {...formConfig.employeeId}
              options={employees}
              mode={"read"}
              permission={addEmployeePermission.fieldPerObj[formConfig.employeeId.perKey]}
            />
          )}
          <DateField {...defaultFormProps} {...formConfig.startDate} permission={addEmployeePermission.fieldPerObj[formConfig.startDate.perKey]} />
          <DateField
            {...defaultFormProps}
            {...formConfig.endDate}
            fieldProps={{ minDate: startDate }}
            permission={addEmployeePermission.fieldPerObj[formConfig.endDate.perKey]}
          />
          <ProSelectField
            {...defaultFormProps}
            {...formConfig.employmentTermTypeId}
            options={employeeTermType}
            permission={addEmployeePermission.fieldPerObj[formConfig.employmentTermTypeId.perKey]}
          />
          <ProTextInput {...defaultFormProps} {...formConfig.perDiem} permission={addEmployeePermission.fieldPerObj[formConfig.perDiem.perKey]} />
          <ProTextInput {...defaultFormProps} {...formConfig.rate} permission={addEmployeePermission.fieldPerObj[formConfig.rate.perKey]} />
          <ProTextInput {...defaultFormProps} {...formConfig.fringe} permission={addEmployeePermission.fieldPerObj[formConfig.fringe.perKey]} />
          <ProTextInput
            {...defaultFormProps}
            {...formConfig.actualHourlyRate}
            permission={addEmployeePermission.fieldPerObj[formConfig.actualHourlyRate.perKey]}
          />
          <ProCheckbox {...defaultFormProps} {...formConfig.isActive} permission={fieldTruePermission} />
          {showLogGrid === true && employeeTermId !== "" ? (
            <Box sx={{ pt: 4, width: "100%" }}>
              <FormSectionWrapper collapsible defaultClose title={"Apprentice Mentor History"}>
                <ApprenticeMentorHistory actionData={projectEmployeeData} />
              </FormSectionWrapper>
            </Box>
          ) : (
            <></>
          )}
        </>
      )}
      <ReactSpreadsheetImport isOpen={isOpen} onClose={handleCloseDialogpopup} onSubmit={handleSubmitImport} fields={fields} />
      <DialogModal
        show={showProjectEmployeesConfirmModal}
        title="Message"
        message={` ${apiResponse}`}
        onConfirm={handleCloseProjectEmployeesModal}
        showCancel={false}
      />
    </TabGridAndFormLayout>
  );
}
