import React from "react";
import ProTextInput from "./TextField";
import { InputAdornment } from "@mui/material";
import DollerIcon from "../../Assets/IconComponents/DollerIcon";
import ReadOnlyInput from "./ReadOnlyInput";
import PropTypes from "prop-types";
export default function CurrencyField({ mode, isAddMode, ...rest }) {
  const { canView = false, canAdd = false, canEdit = false } = rest.permission || {};
  if (!canView || (isAddMode && !canAdd)) return null;
  const editModeAllowed = isAddMode ? canAdd : canEdit;

  if (mode === "read" || (mode === "edit" && !editModeAllowed)) return <ReadOnlyInput type="currency" {...rest} />;
  return (
    <ProTextInput
      {...rest}
      type="number"
      startAdornment={
        <InputAdornment position="start">
          <DollerIcon />
        </InputAdornment>
      }
    />
  );
}

CurrencyField.propTypes = {
  mode: PropTypes.oneOf(["read", "edit"]).isRequired,
  isAddMode: PropTypes.bool
};
