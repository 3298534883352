import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Box, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import FormGridInsideSectionLayout from "../../../Components/ProForms/ProFormsLayout/FormGridInsideSectionLayout.jsx";
import ProGrid from "../../../Components/ProGrid/v2";
import { STAUTES } from "../../../../utils/constant";
import usePageState from "../../../../utils/customHooks/usePageState";
import DownloadCell from "../../../Components/ProGrid/components/DownloadCell";
import { errorToast, successToast } from "../../../../utils/toastHelper";
import PropTypes from "prop-types";
import {
  DELETE_PROJECTSCHEDULES,
  GET_ALL_PROJECTSCHEDULES,
  GET_PROJECTSCHEDULES,
  ADD_PROJECTSCHEDULES,
  UPDATE_PROJECTSCHEDULES
} from "../../../../utils/services/apiPath";
import { postApi, getApi, deleteApi } from "../../../../utils/services";
import { ProjectSchedulingConfig } from "../projectData.js";
import ProTextInput from "../../../Components/Inputs/TextField";
import {
  changeMode,
  changeModeForField,
  checkEditEnabled,
  convertFileToBase64,
  prepareDefaultValues,
  prepareInitialConfig
} from "../../../../utils/formHelper";
import ProFileUploadField from "../../../Components/Inputs/FileUploadField";
import DeleteCell from "../../../Components/ProGrid/components/DeleteCell";
import NameCell from "../../../Components/ProGrid/components/NameCell";
import usePermission from "../../../../utils/customHooks/usePermission";
import { R_PROJECTS, R_PROJECTS_PROFILE } from "../../../../utils/permissionReferenceConfig";
import { subModPermissionSelector } from "../../../store/features/permissions/permissionsSlice";
import useColumn from "../../../../utils/customHooks/useColumns";

export default function ProjectScheduling({ projectId, globalEditMode, setGlobalEditMode }) {
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(ProjectSchedulingConfig));
  const [documentUrl, setDocumentUrl] = useState("");
  const [documentFileName, setDocumentFileName] = useState(null);
  const [actionData, setActionData] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const isEditModeOn = useMemo(() => checkEditEnabled(false, formConfig), [formConfig]);

  const { "Project Site : Material & Equipment Scheduling Info": projectSiteMaterialPermission } = usePermission(
    subModPermissionSelector(R_PROJECTS, R_PROJECTS_PROFILE),
    ["Project Site : Material & Equipment Scheduling Info"]
  );

  let { id } = useParams();
  const { currentPage, pageSize, data: documentData, rowCount, status, setPageState, setPagination, setStatus } = usePageState();
  const { handleSubmit, control, setValue } = useForm({ defaultValues: prepareDefaultValues(ProjectSchedulingConfig) });

  const getProjectSchedules = useCallback(async () => {
    if (projectId !== "") {
      setStatus(STAUTES.LOADING);
      const payload = {
        pageIndex: currentPage + 1,
        pageSize: pageSize,
        orderByAscending: false,
        searchString: ""
      };
      const { data, totalRecords, error } = await postApi(`${GET_ALL_PROJECTSCHEDULES}?projectId=${projectId}`, payload);

      setStatus(STAUTES.IDLE);
      if (error) return errorToast(error);
      setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
    }
  }, [currentPage, pageSize, setPageState, setStatus]);

  const handleDownload = params => {
    const file = params.row.documentMediaModel.path;
    const link = document.createElement("a");
    link.href = file;
    link.target = "_blank";
    link.rel = "noopener noreferrer";
    link.click();
  };

  const handleReadValueclick = name => {
    setFormConfig(changeModeForField(name, "edit"));
  };

  const defaultFormProps = { control, formValues: actionData, handleReadValueclick };

  const handleEditRow = async event => {
    const { data, error } = await getApi(`${GET_PROJECTSCHEDULES}/${event}`);
    if (error) return errorToast(error);
    if (!data) return;
    setActionData(data);
    setValue("deliverySchedule", data.equipmentDeliverySchedule);
    setDocumentUrl(data.documentMediaModel.base64String);
    setDocumentFileName(data.documentMediaModel.fileName);
    setGlobalEditMode(true);
    onEdit();
  };
  const onEdit = () => {
    setFormConfig(changeMode("edit"));
  };

  const onCancel = () => {
    resetForm();
  };

  const resetForm = () => {
    setActionData({});
    setDocumentUrl("");
    setDocumentFileName(null);
    Object.keys(formConfig).forEach(field => setValue(field, null));
    setFormConfig(changeMode());
  };

  useEffect(() => {
    getProjectSchedules();
  }, [getProjectSchedules, currentPage, pageSize]);

  const handleAddUpdateRow = async data => {
    if (isSaving) return; 
    setIsSaving(true);
    const payload = {
      projectId: id,
      equipmentDeliverySchedule: data.deliverySchedule,
      projectScheduleId: actionData?.projectScheduleId || null
    };
    if (documentFileName !== null) {
      payload["documentMediaModel"] = {
        base64String: documentUrl,
        fileName: documentFileName
      };
    }
    const { error } = await postApi(actionData?.projectScheduleId ? UPDATE_PROJECTSCHEDULES : ADD_PROJECTSCHEDULES, payload);
    setIsSaving(false); 
    if (error) return errorToast(error);
    getProjectSchedules();
    resetForm();
    successToast(`Project Equipment ${!actionData?.projectScheduleId ? "Added" : "Updated"} Successfully`);
  };

  const onDelete = async projectScheduleId => {
    const { error } = await deleteApi(DELETE_PROJECTSCHEDULES, { data: [{ projectScheduleId: projectScheduleId }] });
    if (error) return errorToast(error);
    getProjectSchedules();
    successToast("Project Equipment deleted successfully.");
  };

  useEffect(() => {
    if (globalEditMode) onEdit();
    else resetForm();
  }, [globalEditMode]);

  const columns = useColumn(
    [
      {
        field: "equipmentDeliverySchedule",
        headerName: "Equipment Delivery Schedule",
        renderCell: params => <NameCell params={params} onClick={() => handleEditRow(params.row.projectScheduleId)} />
      },
      {
        field: "projectClientContractId",
        headerName: "Actions",
        hideSortIcons: true,
        sortingOrder: false,

        renderCell: params => (
          <>
            <Box>
              <DownloadCell params={params} onOpen={() => handleDownload(params)} />
            </Box>
            <Box>
              <DeleteCell params={params} onDelete={onDelete} />
            </Box>
          </>
        )
      }
    ],
    projectSiteMaterialPermission.canDelete
  );

  return (
    <div className="proFormGridInsideSectionLayout-wrapper">
      <FormGridInsideSectionLayout
        showGrid={true}
        beingEdited={isEditModeOn}
        onEdit={onEdit}
        onCancel={onCancel}
        onSave={handleSubmit(handleAddUpdateRow)}
        isSaving={isSaving}
      >
        <Grid container spacing={2}>
          <ProTextInput
            {...defaultFormProps}
            {...formConfig.deliverySchedule}
            permission={projectSiteMaterialPermission.fieldPerObj[formConfig.deliverySchedule.perKey]}
          />

          <ProFileUploadField
            {...defaultFormProps}
            {...formConfig.attachmentFile}
            inputProps={{ accept: "/*" }}
            fieldProps={{
              onChange: async event => {
                setValue("attachmentFile", event);
                const file = event;
                setDocumentFileName(file.name);
                if (!file) {
                  setDocumentUrl("");
                  setDocumentFileName("");
                  return;
                }
                const cleanedDataUrl =await convertFileToBase64(file);
                setDocumentUrl(cleanedDataUrl);
              }
            }}
            permission={projectSiteMaterialPermission.fieldPerObj[formConfig.attachmentFile.perKey]}
          />
        </Grid>
      </FormGridInsideSectionLayout>
      <ProGrid
        loading={status === STAUTES.LOADING}
        options={{
          getRowId: row => row.projectScheduleId,
          rowCount: rowCount,
          paginationMode: "server",
          paginationModel: { pageSize: pageSize, page: currentPage },
          onPaginationModelChange: setPagination
        }}
        columns={columns}
        hideExport
        hideSearch={true}
        hideIfNoData={true}
        hideAddButton={true}
        rows={documentData || []}
      />
    </div>
  );
}

ProjectScheduling.propTypes = {
  projectId: PropTypes.string,
  globalEditMode: PropTypes.bool,
  setGlobalEditMode: PropTypes.bool
};
