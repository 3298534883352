import React from "react";
import ProTextInput from "./TextField";
import ReadOnlyInput from "./ReadOnlyInput";
import PropTypes from "prop-types";

export default function ZipField({ rules, mode, isAddMode, ...rest }) {
  const { canView = false, canAdd = false, canEdit = false } = rest.permission || {};
  if (!canView || (isAddMode && !canAdd)) return null;
  const editModeAllowed = isAddMode ? canAdd : canEdit;
  if (mode === "read" || (mode === "edit" && !editModeAllowed)) return <ReadOnlyInput {...rest} type="zip" />;
  return (
    <ProTextInput
      {...rest}
      rules={{
        ...(rules || {}),
        pattern: {
          value: /^\d{5}(-\d{4})?$/,
          message: "Invalid Zip"
        }
      }}
    />
  );
}

ZipField.propTypes = {
  mode: PropTypes.oneOf(["read", "edit"]).isRequired,
  rules: PropTypes.object.isRequired,
  isAddMode: PropTypes.bool,
  permission: PropTypes.shape({
    canView: PropTypes.bool,
    canAdd: PropTypes.bool,
    canEdit: PropTypes.bool
  })
};
