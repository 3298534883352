import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DetailPageLayout from "../../../Components/Layout/DetailPageLayout";
import Layout from "../../../Components/Layout";
import PartnerProfile from "./PartnerProfile";
import PartnerContacts from "./PartnerContacts";
import PartnerDocument from "./PartnerDocument";
import ProfileIcon from "../../../Assets/IconComponents/ProfileIcon";
import ContactIcon from "../../../Assets/IconComponents/ContactIcon";
import PartnerNotes from "./PartnerNotes";
import PartnerLocations from "./PartnerLocations";
import PartnerSocialMedia from "./PartnerSocialMedia";
import { postApi } from "../../../../utils/services";
import { GET_PARTNER_BY_ID } from "../../../../utils/services/apiPath";
import { errorToast } from "../../../../utils/toastHelper";
import { partnersPermissionSelector } from "../../../store/features/permissions/permissionsSlice";
import { useSelector } from "react-redux";

export default function PartnerDetail() {
  const { tab, id } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("");
  const [partnerProfileData, setPartnerProfileData] = useState();
  const isAddMode = useMemo(() => id === "add", [id]);
  const { subModulePermissions } = useSelector(partnersPermissionSelector);
  const documentPermission = subModulePermissions?.PARTNERS_DOCUMENTS?.canView;
  const locationPermission = subModulePermissions?.PARTNERS_LOCATIONS?.canView;
  const contactPermission = subModulePermissions?.PARTNERS_CONTACT?.canView;
  const notesPermission = subModulePermissions?.PARTNERS_NOTES?.canView;
  const profilePermission = subModulePermissions?.PARTNERS_PROFILE?.canView;
  const socialMediaPermission = subModulePermissions?.PARTNERS_SOCIAL_MEDIA?.canView;

  const tabs = isAddMode
    ? [
        {
          to: "/profile",
          title: "Profile",
          key: "profile",
          hasArrow: false,
          icon: ProfileIcon,
          canView: profilePermission
        }
      ]
    : [
        {
          to: "/profile",
          title: "Profile",
          key: "profile",
          hasArrow: false,
          icon: ProfileIcon,
          canView: profilePermission
        },
        {
          to: "/contacts",
          title: "Contacts",
          key: "contacts",
          hasArrow: false,
          icon: ContactIcon,
          canView: contactPermission
        },
        {
          to: "/documents",
          title: "Documents",
          key: "documents",
          hasArrow: false,
          icon: ContactIcon,
          canView: documentPermission
        },
        {
          to: "/notes",
          title: "Notes",
          key: "notes",
          hasArrow: false,
          icon: ContactIcon,
          canView: notesPermission
        },
        {
          to: "/locations",
          title: "Locations",
          key: "locations",
          hasArrow: false,
          icon: ContactIcon,
          canView: locationPermission
        },
        {
          to: "/socialmedia",
          title: "Social Media",
          key: "socialmedia",
          hasArrow: false,
          icon: ContactIcon,
          canView: socialMediaPermission
        }
      ];

  const backAction = () => {
    navigate("/partners");
  };

  const handleTabClick = tab => {
    navigate(`/partners/${id}${tab.to}`);
  };

  useEffect(() => {
    const selectedTab = tabs.find(t => t.key === tab?.toLowerCase())?.title;
    setActiveTab(selectedTab || "Profile");
  }, [tab]);

  const getPartnerProfileData = async () => {
    const { error, data } = await postApi(GET_PARTNER_BY_ID, { clientId: id });
    if (error) {
      return errorToast(error);
    }
    setPartnerProfileData(data);
  };

  useEffect(() => {
    if (!isAddMode) getPartnerProfileData();
  }, [id]);

  return (
    <Layout>
          <DetailPageLayout
            backAction={backAction}
            backLabel={"Go back to Partners"}
            title={id === "add" ? "ADD PARTNER " : "PARTNER INFORMATION "}
            subTitle={id === "add" ? "Fill in the below details to add partner" : partnerProfileData?.companyName}
            tabs={tabs.filter(tab => tab.canView)}
            activeTab={activeTab}
            handleTabClick={handleTabClick}
          >
            
            {activeTab === "Profile" && profilePermission && <PartnerProfile />}
            {activeTab === "Contacts" && contactPermission && <PartnerContacts />}
            {activeTab === "Documents" && documentPermission && <PartnerDocument />}
            {activeTab === "Notes" && notesPermission && <PartnerNotes />}
            {activeTab === "Locations" && locationPermission && <PartnerLocations />}
            {activeTab === "Social Media" && socialMediaPermission && <PartnerSocialMedia />}
          </DetailPageLayout>
    </Layout>
  );
}
