export const transFormModalData = dropDownKeys => {
  const transformedData = {};
  Object.keys(dropDownKeys).forEach(key => {
    const model = dropDownKeys[key] || {};
    transformedData[key] = model.value || null;
    transformedData[`${key}Label`] = model.label || "";
  });

  return transformedData;
};

export const defaultDropdownConfig = {
  pageIndex: 1,
  pageSize: 100,
  orderByAscending: true,
  searchString: ""
};

export const employmentStatusOptions = [
  {
    label: "New Employee",
    value: "New"
  },
  {
    label: "Current Employee",
    value: "Current"
  }
];

export const sexOptions = [
  {
    label: "Male",
    value: "male"
  },
  {
    label: "Female",
    value: "female"
  },
  {
    label: "Participant Did Not Self Identify",
    value: "notIdentify"
  }
];

export const payrollTypeOptions = [
  { label: "Weekly", value: "1" },
  { label: "BiWeekly", value: "2" },
  { label: "Monthly", value: "3" }
];

export const timekeepingOptions = [
  { label: "Daily", value: "1" },
  { label: "Weekly", value: "2" }
];

export const CourseContentDeliveryOptions = [
  { label: "Fixed Schedule", value: "1" },
  { label: "Flexible", value: "2" },
  { label: "Adaptive RTI", value: "3" },
  { label: "Accelerated Path", value: "4" },
  { label: "ALL", value: "5" }
];

export const TrackingMethod = [
  { label: "ProScore Mobile App", value: "1" },
  { label: "Client Timekeeping System", value: "2" },
  { label: "Manual Timesheet", value: "3" }
];
export const ElearningHoursPaidOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false }
];
export const RelatedInstructionIsProvidedOptions = [
  { label: "During Work Hours", value: "DuringWorkHours" },
  { label: "Not During Work Hours", value: "NotDuringWorkHours" },
  {
    label: "Both During and Not During Work Hours",
    value: "BothDuringandNotDuringWorkHours"
  }
];
export const yesNoOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false }
];

export const weekdaysOptions = [
  { id: "Sun", label: "Sun" },
  { id: "Mon", label: "Mon" },
  { id: "Tue", label: "Tue" },
  { id: "Wed", label: "Wed" },
  { id: "Thu", label: "Thu" },
  { id: "Fri", label: "Fri" },
  { id: "Sat", label: "Sat" }
];

export const timeKeeping = [
  { label: "Yes", value: true },
  { label: "No", value: false }
];
export const communitySolarOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false }
];
export const coLocatedProjectOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false }
];
export const projectKickoff = [
  { label: "Fall", value: "Fall" },
  { label: "Winter", value: "Winter" },
  { label: "Spring", value: "Spring" },
  { label: "Summer", value: "Summer" }
];
export const interconnectionApproval = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
  { label: "Pending", value: "Pending" }
];

export const payTypePositionOption = [
  { label: "Hourly", value: "Hourly" },
  { label: "Salary", value: "Salary" }
];
export const operator = [
  { label: "Yes", value: true },
  { label: "No", value: false }
];
export const typeOfOperatorOption = [
  { label: "Heavy Equipment Operator", value: "heavy equipment operator" },
  { label: "Light Equipment Operator", value: "light equipment operator" },
  { label: "Line Operator", value: "line operator" },
  { label: "Power Equipment Operator", value: "power equipment operator" }
];
export const jobCategories = [
  { label: "General Labor", value: "general labor" },
  { label: "Construction Labor", value: "construction labor" }
];
export const responsibleForUpkeep = [
  { label: "Yes", value: true },
  { label: "No", value: false }
];
