import React from "react";
import { Grid } from "@mui/material";
import Stack from "@mui/material/Stack";
import { PieChart } from "@mui/x-charts/PieChart";
import PropTypes from "prop-types";

const RTIChart = ({ rtiPercent, rtiEstimatePercent }) => {
  const data = [
    { label: "RTI", value: ((rtiPercent / rtiEstimatePercent) * 100).toFixed(2), color: "#018749" },
    { label: "RTI Remaining", value: (((rtiEstimatePercent - rtiPercent) / rtiEstimatePercent) * 100).toFixed(2), color: "#e8eaee" }
  ];

  const getArcLabel = (params) => {
    return params.value !== 0 ? `${params.value}%` : '';
  };

  const pieChartsParams = {
    series: [
      {
        startAngle: -90,
        endAngle: 90,
        paddingAngle: 0,
        innerRadius: 180,
        outerRadius: 130,
        data,
        arcLabel: getArcLabel,
      }
    ]
  };
  return (
    <Grid
      sx={{
        py: 2,
        display: "flex",
        alignItems: "center",
        height: "100%"
      }}
    >
      <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
        <PieChart
          {...pieChartsParams}
          alignItems="center"
          margin={{ top: 100, right: 10 }}
          width={400}
          height={300}
          slotProps={{
            legend: { hidden: true }
          }}
          series={pieChartsParams.series.map(serie => ({
            ...serie,
            valueFormatter: values => `${values.value}%`
          }))}
        />
      </Stack>
    </Grid>
  );
};

RTIChart.propTypes = {
  rtiPercent: PropTypes.number.isRequired,
  rtiEstimatePercent: PropTypes.number.isRequired
};

export default RTIChart;
