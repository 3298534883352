import React from "react";
import { Grid, Box, CircularProgress, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { footNotesHeader } from "./ProjectJobsConstant";

export const RenderContent = ({ isWagesLoader, chatBotFringes, chatBotRates, chatBotMatchScore, isNoStateOrCountyData, errorMsg, footNotes }) => {
  if (isWagesLoader) {
    return (
      <Box sx={{ m: 5, height: 100, textAlign: "center" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <CircularProgress />
          </Grid>
        </Grid>
      </Box>
    );
  }
  if (chatBotFringes) {
    return (
      <Box>
        <div className="pwc_section_top_3">
          <div className="pwc-top" style={{ backgroundColor: "neutral" }}>
            <div className="pwc-top_right">
              <h5>Rates</h5>
              <h2>${chatBotRates}</h2>
            </div>
          </div>
          <div className="pwc-top" style={{ backgroundColor: "neutral" }}>
            <div className="pwc-top_right">
              <h5>Fringes</h5>
              <h2>${chatBotFringes}</h2>
            </div>
          </div>
          <div className="pwc-top" style={{ backgroundColor: "neutral" }}>
            <div className="pwc-top_right">
              <h5>Confidence Match Score</h5>
              <h2>{chatBotMatchScore}%</h2>
            </div>
          </div>
        </div>
        {Object.keys(footNotes)?.map(
          key =>
            footNotes[key] !== "" &&
            Object.keys(footNotes[key])?.length !== 0 && (
              <div className="pwc_section_footnotes" key={key}>
                <div className="pwc-top" style={{ backgroundColor: "#FFF7E8" }}>
                  <div className="pwc-top_right">
                    <h3>{footNotesHeader[key]}</h3>
                    {key === "otherFootnotes" ? (
                      Object.keys(footNotes[key])?.map(otherkey => <h5 key={otherkey}>{`(${otherkey}). ${footNotes[key][otherkey]}`}</h5>)
                    ) : (
                      <h5>{footNotes[key]}</h5>
                    )}
                  </div>
                </div>
              </div>
            )
        )}
      </Box>
    );
  }
  return (
    <Box sx={{ m: 5, height: 100, textAlign: "center" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Typography cla variant="h6" sx={{ fontSize: "12px !important" }}>
            {isNoStateOrCountyData ? "Project does not have a state and county." : errorMsg}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

RenderContent.propTypes = {
  isWagesLoader: PropTypes.bool,
  chatBotFringes: PropTypes.number,
  chatBotRates: PropTypes.number,
  chatBotMatchScore: PropTypes.number,
  isNoStateOrCountyData: PropTypes.bool,
  errorMsg: PropTypes.string,
  footNotes: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.string)]))
};
