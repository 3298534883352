import { useMemo } from "react";
import { useSelector } from "react-redux";

const usePermission = (selector, formNames) => {
  const permission = useSelector(selector);
  const modifiedPermission = useMemo(() => {
    const formPermission = {};
    const reqForms = Array.isArray(formNames) ? formNames : [formNames];
    reqForms.forEach(formName => {
      formPermission[formName] = { canView: false, canEdit: false, canAdd: false, canDelete: false, canExport: false, fieldPerObj: {} };
    });
    permission.formPermissions?.forEach(form => {
      if (reqForms.includes(form.formName)) {
        const fieldPerObj = form.fieldPermissions.reduce((acc, field) => {
          acc[field.fieldName] = field;
          return acc;
        }, {});
        formPermission[form.formName] = { ...form, fieldPerObj };
      }
    });
    return formPermission;
  }, [permission, formNames]);

  return modifiedPermission;
};

export default usePermission;
