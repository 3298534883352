import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";

export default function ProModal({ children, show, title, actions = null, handleClose, ...rest }) {
  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={show} {...rest}>
      <DialogTitle sx={{ m: 0, p: 2 }}>{title} </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500]
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>{children}</DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
}

ProModal.propTypes = {
  children: PropTypes.node,
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  actions: PropTypes.node,
  handleClose: PropTypes.func.isRequired,
};

ProModal.defaultProps = {
  actions: null,
};