import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import FormGridInsideSectionLayout from "../ProForms/ProFormsLayout/FormGridInsideSectionLayout.jsx";
import ProGrid from "../ProGrid/v2.jsx";
import { STAUTES } from "../../../utils/constant.js";
import usePageState from "../../../utils/customHooks/usePageState.js";
import { errorToast, successToast } from "../../../utils/toastHelper.js";
import ProTextInput from "../Inputs/TextField.jsx";
import { changeMode,changeModeForField, checkEditEnabled, prepareDefaultValues, prepareInitialConfig } from "../../../utils/formHelper.js";
import DeleteCell from "../ProGrid/components/DeleteCell.jsx";
import useColumn from "../../../utils/customHooks/useColumns.js";
import ProSelectField from "../Inputs/SelectField.jsx";
import { deleteApi, postApi } from "../../../utils/services/index.js";
import {
  DELETE_PARTNERS_CONTACT_NOTE,
  GET_ALL_PARTNERS_CONTACTS_NOTES,
  UPDATE_PARTNERS_CONTACT_NOTE,
  ADD_PARTNERS_CONTACT_NOTE,
  GET_ALL_NOTE_TYPES
} from "../../../utils/services/apiPath.js";
import { defaultDropdownConfig } from "../../../utils/dropdownHelper.js";
import ProCheckbox from "../Inputs/CheckboxField.jsx";
import { dateColumnDef } from "../ProGrid/components/FormatCell.jsx";
import { partnersContactPermissionSelector } from "../../store/features/permissions/permissionsSlice.js";
import usePermission from "../../../utils/customHooks/usePermission.js";
import PropTypes from "prop-types";

const PartnersNotesFormConfing = {
  noteTypeId: {
    label: "Note Type",
    perKey: "NoteType",
    rules: { required: "Note Type is required!" }
  },
  notes: {
    label: "Notes",
    perKey: "NoteName",
    rules: { required: "Notes is required!" },
    size: 12,
    fieldProps: { multiline: true, rows: 5 }
  },
  isPrivate: {
    label: "Is Private",
    perKey: "IsPrivate"
  }
};

export default function PartnerContactNotes({ clientContactId, clientId, globalEditMode }) {
  const [actionData, setActionData] = useState({});
  const [notesTypes, setNotesTypes] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  const { "Partner Contact Notes": partnerContactNotesPermission } = usePermission(partnersContactPermissionSelector, ["Partner Contact Notes"]);
  const { canDelete, canAdd, fieldPerObj } = partnerContactNotesPermission;

  const { data: notesData, status, setPageState, setStatus } = usePageState();
  const { handleSubmit, control, setValue } = useForm({ defaultValues: prepareDefaultValues(PartnersNotesFormConfing) });
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(PartnersNotesFormConfing));

  const isEditModeOn = useMemo(() => checkEditEnabled(false, formConfig), [formConfig]);

  const getContactNotes = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const { data, error } = await postApi(
      GET_ALL_PARTNERS_CONTACTS_NOTES,
      {
        pageIndex: 0,
        pageSize: 100,
        orderByAscending: true,
        searchString: "",
        orderCol: "notes"
      },
      { params: { clientContactId } }
    );
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data }));
  }, [clientContactId, setPageState]);

  const handleReadValueclick = name => setFormConfig(changeModeForField(name, "edit"));

  const onEdit = () => setFormConfig(changeMode("edit"));

  const resetForm = keepMode => {
    setActionData({});
    Object.keys(formConfig).forEach(field => setValue(field, null));
    setValue("notes", "");
    if (!keepMode) setFormConfig(changeMode("read"));
  };

  const onSave = async data => {
    if (isSaving) return; 
    setIsSaving(true);
    const payload = { ...data, clientContactId, clientId, isPrivate: data.isPrivate || false };
    if (actionData.clientContactNoteId) payload.clientContactNoteId = actionData.clientContactNoteId;
    const { error } = await postApi(actionData.clientContactNoteId ? UPDATE_PARTNERS_CONTACT_NOTE : ADD_PARTNERS_CONTACT_NOTE, payload);
    setIsSaving(false); 
    if (error) return errorToast(error);
    resetForm(true);
    getContactNotes();
    successToast(`Contact Note ${actionData.clientContactNoteId ? "Updated" : "Added"} Successfully`);
  };

  const onDelete = async id => {
    const { error } = await deleteApi(DELETE_PARTNERS_CONTACT_NOTE, { data: { clientContactNoteId: id } });
    if (error) return errorToast(error);
    getContactNotes();
    successToast("Contact Note deleted successfully.");
  };

  const getAllNoteTypes = async () => {
    const { data } = await postApi(GET_ALL_NOTE_TYPES, defaultDropdownConfig);
    setNotesTypes(data.map(item => ({ label: item.noteType, value: item.noteTypeId })));
  };

  useEffect(() => {
    getContactNotes();
    getAllNoteTypes();
  }, [getContactNotes]);

  useEffect(() => {
    if (globalEditMode) onEdit();
    else resetForm();
  }, [globalEditMode]);

  const columns = useColumn(
    [
      {
        field: "notes",
        headerName: "Notes Name"
      },
      {
        field: "noteType",
        headerName: "Notes Type"
      },
      {
        field: "createdByName",
        headerName: "Created By"
      },
      dateColumnDef({
        field: "createdDate",
        headerName: "Created Date"
      }),
      {
        field: "clientContactNoteId",
        headerName: "Actions",
        isDeleteColumn: true,
        disableExport: true,
        width: 80,
        renderCell: params => <DeleteCell title={"Delete Contact Notes"} params={params} onDelete={onDelete} />
      }
    ],
    globalEditMode && canDelete
  );

  const defaultFormProps = { control, formValues: {}, handleReadValueclick, isAddMode: true };

  return (
    <div className="proFormGridInsideSectionLayout-wrapper">
      <FormGridInsideSectionLayout
        showGrid={true}
        cancelLabel="Reset"
        beingEdited={isEditModeOn && canAdd}
        onEdit={onEdit}
        onCancel={resetForm}
        onSave={handleSubmit(onSave)}
        isSaving={isSaving}
      >
        <Grid container spacing={2}>
          <ProSelectField
            {...defaultFormProps}
            {...formConfig.noteTypeId}
            options={notesTypes}
            permission={{ ...fieldPerObj[formConfig.noteTypeId.perKey], canEdit: false }}
          />
          <ProCheckbox {...defaultFormProps} {...formConfig.isPrivate} permission={{ ...fieldPerObj[formConfig.isPrivate.perKey], canEdit: false }} />
          <ProTextInput {...defaultFormProps} {...formConfig.notes} permission={{ ...fieldPerObj[formConfig.notes.perKey], canEdit: false }} />
        </Grid>
      </FormGridInsideSectionLayout>
      <ProGrid
        loading={status === STAUTES.LOADING}
        options={{
          getRowId: row => row.clientContactNoteId,
          pagination: false,
          autoHeight: true
        }}
        columns={columns}
        hideExport
        hideSearch={true}
        hideIfNoData={true}
        hideAddButton={true}
        rows={notesData || []}
      />
    </div>
  );
}

PartnerContactNotes.propTypes = {
  clientContactId: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  globalEditMode: PropTypes.bool.isRequired,
};