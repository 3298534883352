export const PartnerProfileFormConfing = {
    companyName: {
      label: "Company Name",
      perKey: "CompanyName",
      rules: { required: "Company Name is Required!" }
    },
    legalBusinessName: {
      label: "Legal Business Name",
      perKey: "LegalBusinessName"
    },
    website: {
      label: "Website",
      perKey: "Website",
      rules: { required: "Website is Required!" }
    },
    industrySector: {
      label: "Industry Sector",
      perKey: "IndustrySector"
    },
    dunsNumber: {
      label: "Duns Number",
      perKey: "DunsNumber"
    },
    taxId: {
      label: "Tax Id",
      perKey: "TAXId"
    },
    clientTypeId: {
      label: "Partner Category",
      perKey: "SelectPartnerCategory"
    },
    mainContactNumber: {
      label: "Main Contact Number",
      perKey: "MainContactNumber"
    },
    logo: {
      label: "Logo",
      perKey: "Logo",
      defaultValue: null,
      readKey: "Logo"
    },
    mailingAddress1: {
      label: "Address1",
      perKey: "MailingAddress1"
    },
    mailingAddress2: {
      label: "Address2",
      perKey: "MailingAddress2"
    },
    mailingCity: {
      label: "City",
      perKey: "MailingCity"
    },
    mailingState: {
      label: "State",
      perKey: "MailingState"
    },
    mailingZip: {
      label: "Zip",
      perKey: "MailingZip"
    },
    mailingEmail: {
      label: "Email",
      perKey: "MailingEmail"
    },
    billingAddress1: {
      label: "Address1",
      perKey: "BillingAddress1"
    },
    billingAddress2: {
      label: "Address2",
      perKey: "BillingAddress2"
    },
    billingCity: {
      label: "City",
      perKey: "BillingCity"
    },
    billingState: {
      label: "State",
      perKey: "BillingState"
    },
    billingZip: {
      label: "Zip",
      perKey: "BillingZip"
    },
    billingEmail: {
      label: "Email",
      perKey: "BillingEmail"
    },
    copyMailingAddress: {
      label: "Billing Address Same As Mailing Address",
      type: "checkbox"
    },
    clientSourceTypeId: {
      label: "Source",
      perKey: "ClientSourceTypeId"
    },
    clientSubSourceTypeId: {
      label: "Sub Source",
      perKey: "ClientSubSourceTypeId"
    },
    otherSubSource: {
      label: "Sub Source",
      perKey: "ClientOtherSubSource"
    }
  };

  export const partnerContactFormConfing = {
    contactTypeId: {
      label: "Contact Type",
      perKey: "ContactType",
      rules: { required: "Required!" }
    },
    firstName: {
      label: "First Name",
      perKey: "FirstName",
      rules: { required: "Required!" }
    },
    middleName: {
      label: "Middle Name",
      perKey: "MiddleName"
    },
    lastName: {
      label: "Last Name",
      perKey: "LastName",
      rules: { required: "Required!" }
    },
    email: {
      label: "Email",
      perKey: "Email",
      rules: { required: "Required!" }
    },
    cellphone: {
      label: "Cell Phone",
      perKey: "CellPhone",
      rules: {
        required: "Required!"
      }
    },
    officeNumber: {
      label: "Office Number",
      perKey: "OfficeNumber",
      rules: {
        required: "Required!"
      }
    },
    jobTitle: {
      label: "Job Title",
      perKey: "JobTitle",
      rules: { required: "Required!" }
    },
    clientContactSourceTypeId: {
      label: "Source",
      perKey: "ClientContactSourceTypeId"
    },
    clientContactSubSourceTypeId: {
      label: "Sub Source",
      perKey: "ClientContactSubSourceTypeId"
    },
    otherSubSource: {
      label: "Sub Source",
      perKey: "ClientContactOtherSubSource"
    }
  };

  export const partnerDocumentFormConfing = {
    documentName: {
      label: "Document Name",
      perKey: "DocumentName",
      rules: { required: "Document Name is required!" }
    },
    clientDocumentTypeId: {
      label: "Document Type",
      perKey: "SelectDocument",
      rules: { required: "Document Type is required!" }
    },
    documentFile: {
      label: "Document",
      perKey: "DocumentFileName",
      readKey: "documentFilePath",
      rules: { required: "Document is required!" }
    }
  };

  export const PartnersNotesFormConfing = {
    noteTypeId: {
      label: "Note Type",
      perKey: "SelectNoteType",
      rules: { required: "Note Type is required!" }
    },
    notes: {
      label: "Notes",
      perKey: "Note",
      rules: { required: "Notes is required!" },
      size: 12,
      fieldProps: { multiline: true, rows: 5 }
    },
    isPrivate: {
      label: "Is Private",
      perKey: "IsPrivate"
    }
  };

  export const PartnerLocationFormConfing = {
    locationName: {
      label: "Location Name",
      perKey: "Title"
    },
    email: {
      label: "Email",
      perKey: "Email"
    },
    address1: {
      label: "Address 1",
      perKey: "Address1"
    },
    address2: {
      label: "Address 2",
      perKey: "Address2"
    },
    contactNumber: {
      label: "Contact Number",
      perKey: "ContactNumber"
    },
    city: {
      label: "City",
      perKey: "City"
    },
    state: {
      label: "State",
      perKey: "State"
    },
    zip: {
      label: "Zip",
      perKey: "Zip",
      rules: {
        required: "Zip is required!"
      }
    }
  };

  export const PartnerSocialMediaFormConfing = {
    socialMediaType: {
      label: "Social Media Type",
      perKey: "SocialMedia"
    },
    link: {
      label: "Link",
      perKey: "Link"
    }
  };