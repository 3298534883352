import React, { useCallback, useEffect, useState } from "react";
import ProGrid from "../../../Components/ProGrid/v2";
import useColumn from "../../../../utils/customHooks/useColumns";
import usePageState from "../../../../utils/customHooks/usePageState";
import { STAUTES } from "../../../../utils/constant";
import { postApi } from "../../../../utils/services";
import { GET_PROJECT_WPS } from "../../../../utils/services/apiPath";
import { errorToast } from "../../../../utils/toastHelper";
import NameCell from "../../../Components/ProGrid/components/NameCell";
import DailyTimeAllocationFormModal from "../../../Components/ModuleComponent/wps/DailyTimeAllocationFormModal";
import { dateColumnDef } from "../../../Components/ProGrid/components/FormatCell";
import TabGridAndFormLayout from "../../../Components/ProForms/ProFormsLayout/TabGridAndFormLayout";
import { useParams } from "react-router-dom";
import { formatPayloadDate, prepareDefaultValues, prepareInitialConfig } from "../../../../utils/formHelper";
import { useForm } from "react-hook-form";
import DateRangeSelect from "../../../Components/ProGrid/components/DateRangeSelect";
import { R_PROJECTS, R_PROJECTS_WPS_TIME_ALLOCATION } from "../../../../utils/permissionReferenceConfig";
import { subModPermissionSelector } from "../../../store/features/permissions/permissionsSlice";
import ReportsLayout from "../../../Components/ProForms/ProFormsLayout/ReportsFormsLayout";
import { ErrorBox } from "../../../Components/Helpers/ErrorBox";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

const WpsConfig = {
  fromDate: {
    label: "From Date",
    defaultValue: dayjs()
  },
  toDate: {
    label: "To Date",
    defaultValue: dayjs()
  }
};

export default function DailyTimeAllocation() {
  const { id: projectId } = useParams();
  const [showTimeAllocationModal, setShowTimeAllocationModal] = useState(false);
  const [actionData, setActionData] = useState(null);
  const { control, watch } = useForm({ defaultValues: prepareDefaultValues(WpsConfig) });
  const [formConfig] = useState(prepareInitialConfig(WpsConfig, "edit"));
  const fromDate = watch("fromDate");
  const toDate = watch("toDate");
  const { canView, canEdit } = useSelector(subModPermissionSelector(R_PROJECTS, R_PROJECTS_WPS_TIME_ALLOCATION));
  const {
    currentPage,
    pageSize,
    data: wpsTimeData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();

  const openTimeAllocationModal = params => {
    setActionData(params.row);
    setShowTimeAllocationModal(true);
  };

  const columns = useColumn([
    {
      field: "apprenticeName",
      headerName: "Apprentice Name",
      renderCell: params => <NameCell params={params} onClick={openTimeAllocationModal} />
    },
    {
      field: "mentorName",
      headerName: "Mentor Name"
    },
    {
      field: "estimatedHours",
      headerName: "Estimated Hours"
    },
    {
      field: "otjHours",
      headerName: "OTJ / WPS Hours Worked"
    },
    dateColumnDef({
      field: "workDate",
      headerName: "Work Date"
    })
  ]);

  const getWpsTimeAllocationList = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const payload = {
      pageIndex: searchString ? 1 : currentPage + 1,
      pageSize: pageSize,
      searchString: searchString,
      orderByAscending: false,
      orderCol: "workDate",
      ...sortModel
    };
    console.log(payload, "payload");
    const { data, totalRecords, error } = await postApi(GET_PROJECT_WPS, payload, {
      params: { projectId: projectId, dateFrom: formatPayloadDate(fromDate), dateTo: formatPayloadDate(toDate) }
    });
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
  }, [currentPage, pageSize, sortModel, searchString, fromDate, toDate, setPageState, setStatus]);

  const handleClose = refresh => {
    setShowTimeAllocationModal(false);
    setActionData(null);
    if (refresh) getWpsTimeAllocationList();
  };

  useEffect(() => {
    getWpsTimeAllocationList();
  }, [getWpsTimeAllocationList]);

  return (
    <ReportsLayout>
      {canView ? (
        <>
          <TabGridAndFormLayout showGrid={true}>
            <ProGrid
              title={"WPS Time Allocation"}
              columns={columns}
              loading={status === STAUTES.LOADING}
              rows={wpsTimeData}
              searchMode={!!searchString}
              searchModel={{ defaultValue: searchString, handleDebounce: setSearchString }}
              hideAddButton={true}
              options={{
                getRowId: row => row.projectApprenticeMentorId,
                rowCount: rowCount,
                paginationMode: "server",
                paginationModel: { pageSize: pageSize, page: currentPage },
                onPaginationModelChange: setPagination,
                sortingMode: "server",
                onSortModelChange: setSortModel
              }}
              additionButtions={<DateRangeSelect control={control} formConfig={formConfig} fromDate={fromDate} toDate={toDate} />}
            />
          </TabGridAndFormLayout>
          <DailyTimeAllocationFormModal show={showTimeAllocationModal} handleClose={handleClose} actionData={actionData} canEdit={canEdit} />
        </>
      ) : (
        <ErrorBox message="You don't have permission to view this page." />
      )}
    </ReportsLayout>
  );
}
