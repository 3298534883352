import { Grid } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

export const ErrorBox = ({ message }) => {
  return (
    <Grid padding="20px" textAlign={"center"}>
      {message}
    </Grid>
  );
};

ErrorBox.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};
