import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { useTheme } from "../../../../ContextStore/ThemeContext";

const GaugeWrapper = styled(CircularProgressbarWithChildren)(({ theme }) => {
  const currentTheme = useTheme();
  return `
  &.CircularProgressbar {
    vertical-align: middle;
    width: 100%;
    margin: 0 auto;
    display: flex;
    
    & + [data-test-id="CircularProgressbarWithChildren__children"] {
      margin-top: 0 !important;
      top: 0;
    }

    .CircularProgressbar-path {
      stroke-linecap: round;
      transition: stroke-dashoffset 0.5s ease 0s;
    }
  
    .CircularProgressbar-trail {
      stroke-linecap: round;
    }
    
    .CircularProgressbar-text {
      font-size: ${theme.typography.pxToRem(15)};
      dominant-baseline: middle;
      text-anchor: middle;
      font-weight: bold;

    }
  
    &.MuiGauge {
      &-xsmall {
        max-width: 48px;
      }
      &-small {
        max-width: 62px;
        .CircularProgressbar-text {
          font-weight: bold;
          font-size: ${theme.typography.pxToRem(24)};
        }
      }
      &-medium {
        max-width: 120px;
      }
      &-large {
        max-width: 140px;
      }
      &-xlarge {
        max-width: 180px;
      }
      &-xxlarge {
        max-width: 230px;
      }
      
      &-primary {
        .CircularProgressbar-path {
          stroke: ${theme.palette.primary.main};
        }
    
        .CircularProgressbar-trail {
          stroke: ${theme.palette.grey[200]};
        }
    
        .CircularProgressbar-text {
          fill: ${theme.palette.text.secondary};
        }
    
        .CircularProgressbar-background {
          fill: ${theme.palette.primary.light};
        }
      }

      &-success {
        .CircularProgressbar-path {
          stroke: #018749; 
        }
    
        .CircularProgressbar-trail {
          stroke: ${theme.palette.grey[200]};
        }
    
        .CircularProgressbar-text {
          fill: ${currentTheme.theme === "dark" ? "#B5B7C8" : ""};
        }
    
        .CircularProgressbar-background {
          fill: ${theme.palette.success.light};
        }
      }
    }
  }
`;
});

const Gauge = ({ className, color = "#018749", size = "medium", circleRatio, value, styles, text, strokeWidth, children, ...rest }) => {
  return (
    <GaugeWrapper
      circleRatio={circleRatio}
      value={value}
      text={text}
      strokeWidth={strokeWidth}
      styles={styles}
      className={`MuiGauge-${color} MuiGauge-${size} ${className}`}
      {...rest}
    >
      {children}
    </GaugeWrapper>
  );
};

Gauge.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  styles: PropTypes.any,
  value: PropTypes.number,
  text: PropTypes.any,
  strokeWidth: PropTypes.number,
  circleRatio: PropTypes.number,
  color: PropTypes.oneOf(["primary", "secondary", "error", "warning", "success", "info", "white", "trueWhite"]),
  size: PropTypes.oneOf(["xsmall", "small", "medium", "large", "xlarge", "xxlarge"])
};

export default Gauge;
