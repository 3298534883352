import React, { useMemo } from "react";
import InputWrapper from "./InputWrapper";
import "./ReadOnlyInput.scss";
import dayjs from "dayjs";
import { DEFAULT_DATE_FORMAT, DEFAULT_SIZE, DEFAULT_TIME_FORMAT } from "../../../utils/constant";
import PropTypes from "prop-types";

const formateDateTime = (fieldValue, type) => {
  if (!fieldValue) return "-";
  const format = type === "date" ? DEFAULT_DATE_FORMAT : DEFAULT_TIME_FORMAT;
  if (typeof fieldValue === "string") dayjs(fieldValue).format(format);
  return fieldValue.format(format);
};

const formateValue = (type, fieldValue, options) => {
  if (type !== "select" && !fieldValue) return "-";
  switch (type) {
    case "number":
      return new Intl.NumberFormat().format(fieldValue);
    case "date":
      return formateDateTime(fieldValue, "date");
    case "time":
      return formateDateTime(fieldValue, "time");
    case "currency":
      return `$${new Intl.NumberFormat().format(fieldValue)}`;
    case "select":
      return options.find(opt => opt.value === fieldValue)?.label || fieldValue || "-";
    case "multiSelect":
      return fieldValue?.map(({ label }) => label).join(", ") || "-";
    case "freeSoloMultiSelect":
      return fieldValue?.map(( label ) => label).join(", ") || "-";
    case "file":
      return fieldValue ? <img className="proInputWrapper-read-image" src={fieldValue} alt="" /> : "-";
    case "ssn":
      return fieldValue ? `XXX-XX-${fieldValue}` : "-";
    default:
      return fieldValue;
  }
};

export default function ReadOnlyInput({
  name,
  readKey,
  label,
  options,
  readfromModel,
  type = "text",
  permission = {},
  formValues = {},
  size = DEFAULT_SIZE,
  handleReadValueclick
}) {
  const fieldValue = useMemo(() => formValues[readKey] || formValues[name], [name, readKey, formValues]);
  const renderValue = useMemo(
    () => (readfromModel ? formValues[`${name}Label`] : formateValue(type, fieldValue, options)),
    [readfromModel, formValues, name, type, fieldValue, options]
  );

  const handleValueClick = () => {
    const { canEdit } = permission;
    if (canEdit) handleReadValueclick?.(name);
  };

  return (
    <InputWrapper size={size}>
      <div className="proInputWrapper-read-label">{label}</div>
      <div className="proInputWrapper-read-value" onClick={handleValueClick}>
        {renderValue}
      </div>
    </InputWrapper>
  );
}

ReadOnlyInput.propTypes = {
  name: PropTypes.string.isRequired,
  readKey: PropTypes.string,
  label: PropTypes.string.isRequired,
  options: PropTypes.array,
  readfromModel: PropTypes.bool,
  type: PropTypes.string,
  permission: PropTypes.shape({
    canView: PropTypes.bool,
    canAdd: PropTypes.bool,
    canEdit: PropTypes.bool
  }),
  formValues: PropTypes.object,
  size: PropTypes.string,
  handleReadValueclick: PropTypes.func
};
