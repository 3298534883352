import { List, ListItem, Popover, Typography } from "@mui/material";
import VideoModel from "./VideoModel";
import { useState } from "react";
import ProModal from "../../ProModal";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PropTypes from "prop-types";

function VideoPopOver({ open, anchorEl, onClose, videos }) {
  const [showVideoModel, setShowVideoModel] = useState(false);
  const [videoDetails, setVideoDetails] = useState(null);

  const handleClose = () => {
    onClose(false);
    handleVideoPleayerClose();
  };

  const openVideoPlayer = video => {
    setVideoDetails(video);
    setShowVideoModel(true);
  };

  const handleVideoPleayerClose = () => {
    setShowVideoModel(false);
    setVideoDetails(null);
  };

  return (
    <>
      <Popover
        disableScrollLock
        anchorEl={anchorEl}
        onClose={handleClose}
        open={open}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <List sx={{ p: 2 }}>
          {videos.map(video => (
            <ListItem key={video.id} sx={{ display: { xs: "flex" }, alignItems: "center" }}>
              <PlayCircleIcon sx={{ mr: 1 }} />
              <Typography component="span" variant="body2" onClick={() => openVideoPlayer(video)}>
                {video.title}
              </Typography>
            </ListItem>
          ))}
        </List>
      </Popover>
      <ProModal fullScreen {...videoDetails} show={showVideoModel} handleClose={handleVideoPleayerClose}>
        <VideoModel {...videoDetails} />
      </ProModal>
    </>
  );
}

VideoPopOver.propTypes = {
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.instanceOf(Element),
  ]),
  onClose: PropTypes.func.isRequired,
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      videoUrl: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default VideoPopOver;
