import React, { useEffect, useMemo, useState } from "react";
import "./SubHeader.scss";
import DownArrowIcon from "../../Assets/IconComponents/DownArrow";
import { NavLink, useLocation } from "react-router-dom";
import { useTheme } from "../../ContextStore/ThemeContext";
import PropTypes from "prop-types";

const Tab = ({ name, tab, Icon, active, arrow, subMenus, onTabClick }) => {
  const { theme } = useTheme();
  const [showDropdown, setShowDropdown] = useState(false);

  const handleTabClick = e => {
    if (arrow) {
      e.preventDefault();
      setShowDropdown(!showDropdown);
    } else onTabClick(tab);
  };

  useEffect(() => {
    const outsideClick = e => {
      if (!e.target.closest(".drop")) setShowDropdown(false);
    };

    if (showDropdown) document.addEventListener("click", outsideClick);
    return () => {
      document.removeEventListener("click", outsideClick);
    };
  }, [showDropdown]);

  const handleKeyDown = event => {
    if (event.key === "Enter" || event.key === " ") {
      handleTabClick();
    }
  };

  const themeArrowColor = theme === "dark" ? "#6D7080" : "#00050B";
  const themeIconColor = theme === "dark" ? "#ffffff" : "#00050B";

  return (
    <button className={`tab ${active ? "active" : ""} ${showDropdown ? "drop" : ""}`} onClick={handleTabClick} onKeyDown={handleKeyDown}>
      <div className="tab_main">
        <Icon color={active || showDropdown ? "#00B0EE" : themeIconColor} />
        <span>{name}</span>
        {arrow && <DownArrowIcon color={active ? "#00B0EE" : themeArrowColor} />}
      </div>
      {showDropdown && arrow && (
        <div className="tab_dropdown">
          {subMenus?.length !== 0 &&
            subMenus?.map(menu => {
              return (
                <NavLink key={menu} to={menu.link} className="tab_dropdown_item">
                  <span>{menu?.title}</span>
                </NavLink>
              );
            })}
        </div>
      )}
    </button>
  );
};

export const MenuItems = ({ tabs, activeTab, onTabClick }) => {
  return (
    <div className="subheader">
      {tabs.map(tab => (
        <Tab
          key={tab}
          tab={tab}
          name={tab.title}
          Icon={tab.icon}
          arrow={tab.hasArrow}
          subMenus={tab.subMenus}
          active={activeTab === tab.title}
          onTabClick={onTabClick}
        />
      ))}
    </div>
  );
};

const SubHeader = ({ tabs, onTabClick }) => {
  const location = useLocation();

  const activeTabData = useMemo(() => {
    return tabs.find(tab => location.pathname.startsWith(tab.to));
  }, [location.pathname, tabs]);

  return (
    <div className="subheader">
      {tabs.map(tab => (
        <Tab
          key={tab}
          tab={tab}
          name={tab.title}
          Icon={tab.icon}
          arrow={tab.hasArrow}
          subMenus={tab.subMenus}
          active={activeTabData?.title === tab.title}
          onTabClick={onTabClick}
        />
      ))}
    </div>
  );
};

export default SubHeader;

SubHeader.propTypes = {
  tabs: PropTypes.array.isRequired,
  onTabClick: PropTypes.func.isRequired
};

MenuItems.propTypes = {
  tabs: PropTypes.array.isRequired,
  activeTab: PropTypes.string,
  onTabClick: PropTypes.func.isRequired
};

Tab.propTypes = {
  name: PropTypes.string,
  tab: PropTypes.object.isRequired,
  Icon: PropTypes.any.isRequired,
  active: PropTypes.bool,
  arrow: PropTypes.any.isRequired,
  subMenus: PropTypes.array.isRequired,
  onTabClick: PropTypes.func.isRequired
};
