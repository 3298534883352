import {
  R_ADDRESS_TYPE,
  R_BONUS_TYPE,
  R_CLIENT_DOCUMENT_TYPE,
  R_COMPENSATION_TYPE,
  R_CONTRACT_TYPE,
  R_DEPARTMENT_TYPE,
  R_EMPLOYEE_DOCUMENT_TYPE,
  R_EMPLOYEE_STATUS_TYPE,
  R_EMPLOYEE_TERM_TYPE,
  R_FRINGE_PAYMENT_METHOD_TYPE,
  R_INDUSTRY_TYPE,
  R_LANGUAGE_TYPE,
  R_NOTIFICATION_TYPE,
  R_PROJECT_TYPE,
  R_ROLES,
  R_SOCIALMEDIA_TYPE,
  R_WORK_TYPE,
  R_PROJECT_CONTACT_TYPE
} from "../../../../utils/permissionReferenceConfig";

// Address
export const GET_ALL_ADDRESS_TYPE = "/AddressType/GetAllAddressTypes";
export const ADD_ADDRESS_TYPE = "/AddressType/AddAddressType";
export const UPDATE_ADDRESS_TYPE = "/AddressType/UpdateAddressType";
export const DELETE_ADDRESS_TYPE = "/AddressType/DeleteAddressType";
export const GET_ADDRESS_TYPE_BY_ID = "/AddressType/GetAddressType";

// Bonus
export const GET_ALL_BONUS_TYPE = "/BonusType/GetAllBonusTypes";
export const ADD_BONUS_TYPE = "/BonusType/AddBonusType";
export const UPDATE_BONUS_TYPE = "/BonusType/UpdateBonusType";
export const DELETE_BONUS_TYPE = "/BonusType/DeleteBonusType";
export const GET_BONUS_TYPE_BY_ID = "/BonusType/GetBonusType";

// compansation type
export const GET_ALL_COMPENSATION_TYPE = "/CompensationType/GetAllCompensationType";
export const ADD_COMPENSATION_TYPE = "/CompensationType/AddCompensationType";
export const UPDATE_COMPENSATION_TYPE = "/CompensationType/UpdateCompensationType";
export const DELETE_COMPENSATION_TYPE = "/CompensationType/DeleteCompensationType";
export const GET_COMPENSATION_TYPE_BY_ID = "/CompensationType/GetCompensationType";

// Client Document Type
export const GET_ALL_CLIENT_DOCUMENT_TYPE = "/ClientDocumentType/GetAllClientDocumentTypes";
export const ADD_CLIENT_DOCUMENT_TYPE = "/ClientDocumentType/AddClientDocumentType";
export const UPDATE_CLIENT_DOCUMENT_TYPE = "/ClientDocumentType/UpdateClientDocumentType";
export const DELETE_CLIENT_DOCUMENT_TYPE = "/ClientDocumentType/DeleteClientDocumentType";
export const GET_CLIENT_DOCUMENT_TYPE_BY_ID = "/ClientDocumentType/GetClientDocumentType";

// project type
export const GET_ALL_PROJECT_TYPE = "/ProjectType/GetAllProjectType";
export const ADD_PROJECT_TYPE = "/ProjectType/AddProjectType";
export const UPDATE_PROJECT_TYPE = "/ProjectType/UpdateProjectType";
export const DELETE_PROJECT_TYPE = "/ProjectType/DeleteProjectType";
export const GET_PROJECT_TYPE_BY_ID = "/ProjectType/GetProjectType";

// contact type
export const GET_ALL_PROJECT_CONTACT_TYPE = "/ProjectContactType/GetAllProjectContactType";
export const ADD_PROJECT_CONTACT_TYPE = "/ProjectContactType/AddProjectContactType";
export const UPDATE_PROJECT_CONTACT_TYPE = "/ProjectContactType/UpdateProjectContactType";
export const DELETE_PROJECT_CONTACT_TYPE = "/ProjectContactType/DeleteProjectContactType";
export const GET_PROJECT_CONTACT_TYPE_BY_ID = "/ProjectContactType/GetProjectContactType";

// industry type
export const GET_ALL_INDUSTRY_TYPE = "/IndustryType/GetAllIndustryType";
export const ADD_INDUSTRY_TYPE = "/IndustryType/AddIndustryType";
export const UPDATE_INDUSTRY_TYPE = "/IndustryType/UpdateIndustryType";
export const DELETE_INDUSTRY_TYPE = "/IndustryType/DeleteIndustryType";
export const GET_INDUSTRY_TYPE_BY_ID = "/IndustryType/GetIndustryType";

// department type
export const GET_ALL_DEPARTMENT_TYPE = "/DepartmentType/GetAllDepartmentType";
export const ADD_DEPARTMENT_TYPE = "/DepartmentType/AddDepartmentType";
export const UPDATE_DEPARTMENT_TYPE = "/DepartmentType/UpdateDepartmentType";
export const DELETE_DEPARTMENT_TYPE = "/DepartmentType/DeleteDepartmentType";
export const GET_DEPARTMENT_TYPE_BY_ID = "/DepartmentType/GetDepartmentType";

// social media type
export const GET_ALL_SOCIAL_MEDIA_TYPE = "/SocialMedia/GetAllSocialMediaByClient";
export const ADD_SOCIAL_MEDIA_TYPE = "/SocialMedia/AddSocialMedia";
export const UPDATE_SOCIAL_MEDIA_TYPE = "/SocialMedia/UpdateSocialMedia";
export const DELETE_SOCIAL_MEDIA_TYPE = "/SocialMedia/DeleteSocialMedia";
export const GET_SOCIAL_MEDIA_TYPE_BY_ID = "/SocialMedia/GetSocialMedia";

// contract type
export const GET_ALL_CONTRACT_TYPE = "/ClientContractType/GetAllClientContractType";
export const ADD_CONTRACT_TYPE = "/ClientContractType/AddClientContractType";
export const UPDATE_CONTRACT_TYPE = "/ClientContractType/UpdateClientContractType";
export const DELETE_CONTRACT_TYPE = "/ClientContractType/DeleteClientContractType";
export const GET_CONTRACT_TYPE_BY_ID = "/ClientContractType/GetClientContractType";

// employee status type
export const GET_ALL_EMPLOYEE_STATUS_TYPE = "/EmployeeStatusType/GetAllEmployeeStatusType";
export const ADD_EMPLOYEE_STATUS_TYPE = "/EmployeeStatusType/AddEmployeeStatusType";
export const UPDATE_EMPLOYEE_STATUS_TYPE = "/EmployeeStatusType/UpdateEmployeeStatusType";
export const DELETE_EMPLOYEE_STATUS_TYPE = "/EmployeeStatusType/DeleteEmployeeStatusType";
export const GET_EMPLOYEE_STATUS_TYPE_BY_ID = "/EmployeeStatusType/GetEmployeeStatusType";

// employee document type
export const GET_ALL_EMPLOYEE_DOCUMENT_TYPE = "/EmploymentDocumentType/GetAllEmploymentDocumentTypes";
export const ADD_EMPLOYEE_DOCUMENT_TYPE = "/EmploymentDocumentType/AddEmploymentDocumentType";
export const UPDATE_EMPLOYEE_DOCUMENT_TYPE = "/EmploymentDocumentType/UpdateEmploymentDocumentType";
export const DELETE_EMPLOYEE_DOCUMENT_TYPE = "/EmploymentDocumentType/DeleteEmploymentDocumentType";
export const GET_EMPLOYEE_DOCUMENT_TYPE_BY_ID = "/EmploymentDocumentType/GetEmploymentDocumentType";

// employee term type
export const GET_ALL_EMPLOYEE_TERM_TYPE = "/EmploymentTermType/GetAllEmploymentTermType";
export const ADD_EMPLOYEE_TERM_TYPE = "/EmploymentTermType/AddEmploymentTermType";
export const UPDATE_EMPLOYEE_TERM_TYPE = "/EmploymentTermType/UpdateEmploymentTermType";
export const DELETE_EMPLOYEE_TERM_TYPE = "/EmploymentTermType/DeleteEmploymentTermType";
export const GET_EMPLOYEE_TERM_TYPE_BY_ID = "/EmploymentTermType/GetEmploymentTermType";

// fringe payment method type
export const GET_ALL_FRINGE_PAYMENT_METHOD_TYPE = "/FringePaymentMethodType/GetAllFringePaymentMethodType";
export const ADD_FRINGE_PAYMENT_METHOD_TYPE = "/FringePaymentMethodType/AddFringePaymentMethodType";
export const UPDATE_FRINGE_PAYMENT_METHOD_TYPE = "/FringePaymentMethodType/UpdateFringePaymentMethodType";
export const DELETE_FRINGE_PAYMENT_METHOD_TYPE = "/FringePaymentMethodType/DeleteFringePaymentMethodType";
export const GET_FRINGE_PAYMENT_METHOD_TYPE_BY_ID = "/FringePaymentMethodType/GetFringePaymentMethodType";

// language type
export const GET_ALL_LANGUAGE_TYPE = "/LanguageType/GetAllLanguageType";
export const ADD_LANGUAGE_TYPE = "/LanguageType/AddLanguageType";
export const UPDATE_LANGUAGE_TYPE = "/LanguageType/UpdateLanguageType";
export const DELETE_LANGUAGE_TYPE = "/LanguageType/DeleteLanguageType";
export const GET_LANGUAGE_TYPE_BY_ID = "/LanguageType/GetLanguageType";

// notification type
export const GET_ALL_NOTIFICATION_TYPE = "/NotificationType/GetAllNotificationType";
export const ADD_NOTIFICATION_TYPE = "/NotificationType/AddNotificationType";
export const UPDATE_NOTIFICATION_TYPE = "/NotificationType/UpdateNotificationType";
export const DELETE_NOTIFICATION_TYPE = "/NotificationType/DeleteNotificationType";
export const GET_NOTIFICATION_TYPE_BY_ID = "/NotificationType/GetNotificationType";

// work type
export const GET_ALL_WORK_TYPE = "/WorkType/GetAllWorkType";
export const ADD_WORK_TYPE = "/WorkType/AddWorkType";
export const UPDATE_WORK_TYPE = "/WorkType/UpdateWorkType";
export const DELETE_WORK_TYPE = "/WorkType/DeleteWorkType";
export const GET_WORK_TYPE_BY_ID = "/WorkType/GetWorkType";

// Roles
export const GET_ALL_ROLES = "/RoleType/GetRoleTypeList";
export const ADD_ROLE = "/RoleType/AddRoleType";
export const UPDATE_ROLE = "/RoleType/UpdateRoleType";
export const DELETE_ROLE = "/RoleType/DeleteRoleType";
export const GET_ROLE_BY_ID = "/RoleType/GetRoleTypeById";

//leadsource
export const GET_ALL_LEAD_SOURCE = "/LeadSourceType/GetAllLeadSourceType";
export const ADD_LEAD_SOURCE = "/LeadSourceType/AddLeadSourceType";
export const UPDATE_LEAD_SOURCE = "/LeadSourceType/UpdateLeadSourceType";
export const DELETE_LEAD_SOURCE = "/LeadSourceType/DeleteLeadSourceType";
export const GET_LEAD_SOURCE_BY_ID = "/LeadSourceType/GetLeadSourceType";

// Purpose: Configuration for the type settings page.
export const typeSettingConfig = {
  "address-type": {
    typeLabel: "Address Type",
    typeKey: "addressType",
    idKey: "addressTypeId",
    getApiPath: GET_ALL_ADDRESS_TYPE,
    addApiPath: ADD_ADDRESS_TYPE,
    deleteApiPath: DELETE_ADDRESS_TYPE,
    updateApiPath: UPDATE_ADDRESS_TYPE,
    getByIdApiPath: GET_ADDRESS_TYPE_BY_ID,
    subModuleRef: R_ADDRESS_TYPE,
    listFormName: "Address Type List",
    addFormName: "Add Address Type",
    perKey: "AddressType"
  },
  roles: {
    typeLabel: "User Roles",
    typeKey: "roleTypeName",
    idKey: "roleTypeId",
    gridKey: "roleTypeGuid",
    getApiPath: GET_ALL_ROLES,
    addApiPath: ADD_ROLE,
    deleteApiPath: DELETE_ROLE,
    updateApiPath: UPDATE_ROLE,
    getByIdApiPath: GET_ROLE_BY_ID,
    subModuleRef: R_ROLES,
    listFormName: "User Roles List",
    addFormName: "Add User Role",
    perKey: "Role Type"
  },
  "bonus-type": {
    typeLabel: "Bonus Type",
    typeKey: "bonusType",
    idKey: "bonusTypeId",
    getApiPath: GET_ALL_BONUS_TYPE,
    addApiPath: ADD_BONUS_TYPE,
    deleteApiPath: DELETE_BONUS_TYPE,
    updateApiPath: UPDATE_BONUS_TYPE,
    getByIdApiPath: GET_BONUS_TYPE_BY_ID,
    subModuleRef: R_BONUS_TYPE,
    listFormName: "Bonus Type List",
    addFormName: "Add Bonus Type",
    perKey: "BonusType"
  },
  "compensation-type": {
    typeLabel: "Compensation Type",
    typeKey: "compensationType",
    idKey: "compensationTypeId",
    getApiPath: GET_ALL_COMPENSATION_TYPE,
    addApiPath: ADD_COMPENSATION_TYPE,
    deleteApiPath: DELETE_COMPENSATION_TYPE,
    updateApiPath: UPDATE_COMPENSATION_TYPE,
    getByIdApiPath: GET_COMPENSATION_TYPE_BY_ID,
    getWithPostMethod: false,
    subModuleRef: R_COMPENSATION_TYPE,
    listFormName: "Compensation Type List",
    addFormName: "Add Compensation Type",
    perKey: "CompensationType"
  },
  "client-document-type": {
    typeLabel: "Client Document Type",
    typeKey: "clientDocumentType",
    idKey: "clientDocumentTypeId",
    getApiPath: GET_ALL_CLIENT_DOCUMENT_TYPE,
    addApiPath: ADD_CLIENT_DOCUMENT_TYPE,
    deleteApiPath: DELETE_CLIENT_DOCUMENT_TYPE,
    updateApiPath: UPDATE_CLIENT_DOCUMENT_TYPE,
    getByIdApiPath: GET_CLIENT_DOCUMENT_TYPE_BY_ID,
    subModuleRef: R_CLIENT_DOCUMENT_TYPE,
    listFormName: "Client Document Type List",
    addFormName: "Add Client Document Type",
    perKey: "ClientDocumentType"
  },
  "project-type": {
    typeLabel: "Project Type",
    typeKey: "projectType",
    idKey: "projectTypeId",
    getApiPath: GET_ALL_PROJECT_TYPE,
    addApiPath: ADD_PROJECT_TYPE,
    deleteApiPath: DELETE_PROJECT_TYPE,
    updateApiPath: UPDATE_PROJECT_TYPE,
    getByIdApiPath: GET_PROJECT_TYPE_BY_ID,
    getWithPostMethod: false,
    subModuleRef: R_PROJECT_TYPE,
    listFormName: "Project Type List",
    addFormName: "Add Project Type",
    perKey: "ProjectType"
  },
  "project-contact-type": {
    typeLabel: "Project Contact Type",
    typeKey: "projectContactType",
    idKey: "projectContactTypeId",
    getApiPath: GET_ALL_PROJECT_CONTACT_TYPE,
    addApiPath: ADD_PROJECT_CONTACT_TYPE,
    deleteApiPath: DELETE_PROJECT_CONTACT_TYPE,
    updateApiPath: UPDATE_PROJECT_CONTACT_TYPE,
    getByIdApiPath: GET_PROJECT_CONTACT_TYPE_BY_ID,
    getWithPostMethod: false,
    subModuleRef: R_PROJECT_CONTACT_TYPE,
    listFormName: "Project Contact Type List",
    addFormName: "Add Project Contact Type",
    perKey: "ProjectContactType"
  },
  "industry-type": {
    typeLabel: "Industry Type",
    typeKey: "industryType",
    idKey: "industryTypeId",
    getApiPath: GET_ALL_INDUSTRY_TYPE,
    addApiPath: ADD_INDUSTRY_TYPE,
    deleteApiPath: DELETE_INDUSTRY_TYPE,
    updateApiPath: UPDATE_INDUSTRY_TYPE,
    getByIdApiPath: GET_INDUSTRY_TYPE_BY_ID,
    getWithPostMethod: false,
    subModuleRef: R_INDUSTRY_TYPE,
    listFormName: "Industry Type LIst",
    addFormName: "Add Industry Type",
    perKey: "IndustryType"
  },
  "department-type": {
    typeLabel: "Department Type",
    typeKey: "departmentType",
    idKey: "departmentTypeId",
    getApiPath: GET_ALL_DEPARTMENT_TYPE,
    addApiPath: ADD_DEPARTMENT_TYPE,
    deleteApiPath: DELETE_DEPARTMENT_TYPE,
    updateApiPath: UPDATE_DEPARTMENT_TYPE,
    getByIdApiPath: GET_DEPARTMENT_TYPE_BY_ID,
    subModuleRef: R_DEPARTMENT_TYPE,
    listFormName: "Department Type List",
    addFormName: "Add Department Type",
    perKey: "DepartmentType"
  },
  "social-media-type": {
    typeLabel: "Social Media Type",
    typeKey: "name",
    idKey: "socialMediaId",
    getApiPath: GET_ALL_SOCIAL_MEDIA_TYPE,
    addApiPath: ADD_SOCIAL_MEDIA_TYPE,
    deleteApiPath: DELETE_SOCIAL_MEDIA_TYPE,
    updateApiPath: UPDATE_SOCIAL_MEDIA_TYPE,
    getByIdApiPath: GET_SOCIAL_MEDIA_TYPE_BY_ID,
    getWithPostMethod: false,
    subModuleRef: R_SOCIALMEDIA_TYPE,
    listFormName: "Social Media  Type List",
    addFormName: "Add Social Media Type",
    perKey: "Name"
  },
  "contract-type": {
    typeLabel: "Contract Type",
    typeKey: "clientContractType",
    idKey: "clientContractTypeId",
    getApiPath: GET_ALL_CONTRACT_TYPE,
    addApiPath: ADD_CONTRACT_TYPE,
    deleteApiPath: DELETE_CONTRACT_TYPE,
    updateApiPath: UPDATE_CONTRACT_TYPE,
    getByIdApiPath: GET_CONTRACT_TYPE_BY_ID,
    subModuleRef: R_CONTRACT_TYPE,
    listFormName: "Contract Type LIst",
    addFormName: "Add Client Contract Type",
    perKey: "ClientContractType"
  },
  "employee-status-type": {
    typeLabel: "Employee Status Type",
    typeKey: "employeeStatusType",
    idKey: "employeeStatusTypeId",
    getApiPath: GET_ALL_EMPLOYEE_STATUS_TYPE,
    addApiPath: ADD_EMPLOYEE_STATUS_TYPE,
    deleteApiPath: DELETE_EMPLOYEE_STATUS_TYPE,
    updateApiPath: UPDATE_EMPLOYEE_STATUS_TYPE,
    getByIdApiPath: GET_EMPLOYEE_STATUS_TYPE_BY_ID,
    subModuleRef: R_EMPLOYEE_STATUS_TYPE,
    listFormName: "Employee Status Type List",
    addFormName: "Add Employee Status Type",
    perKey: "EmployeeStatusType"
  },
  "employee-document-type": {
    typeLabel: "Employee Document Type",
    typeKey: "employmentDocumentType",
    idKey: "employmentDocumentTypeId",
    getApiPath: GET_ALL_EMPLOYEE_DOCUMENT_TYPE,
    addApiPath: ADD_EMPLOYEE_DOCUMENT_TYPE,
    deleteApiPath: DELETE_EMPLOYEE_DOCUMENT_TYPE,
    updateApiPath: UPDATE_EMPLOYEE_DOCUMENT_TYPE,
    getByIdApiPath: GET_EMPLOYEE_DOCUMENT_TYPE_BY_ID,
    subModuleRef: R_EMPLOYEE_DOCUMENT_TYPE,
    listFormName: "Employee Document Type List",
    addFormName: "Add Employee Document Type",
    perKey: "EmploymentDocumentType"
  },
  "employee-term-type": {
    typeLabel: "Employee Term Type",
    typeKey: "employmentTermType",
    idKey: "employmentTermTypeId",
    getApiPath: GET_ALL_EMPLOYEE_TERM_TYPE,
    addApiPath: ADD_EMPLOYEE_TERM_TYPE,
    deleteApiPath: DELETE_EMPLOYEE_TERM_TYPE,
    updateApiPath: UPDATE_EMPLOYEE_TERM_TYPE,
    getByIdApiPath: GET_EMPLOYEE_TERM_TYPE_BY_ID,
    subModuleRef: R_EMPLOYEE_TERM_TYPE,
    listFormName: "Employee Term Type List",
    addFormName: "Add Employee Term Type",
    perKey: "EmploymentTermType"
  },
  "fringe-payment-method-type": {
    typeLabel: "Fringe Payment Method Type",
    typeKey: "fringePaymentMethodType",
    idKey: "fringePaymentMethodTypeId",
    getApiPath: GET_ALL_FRINGE_PAYMENT_METHOD_TYPE,
    addApiPath: ADD_FRINGE_PAYMENT_METHOD_TYPE,
    deleteApiPath: DELETE_FRINGE_PAYMENT_METHOD_TYPE,
    updateApiPath: UPDATE_FRINGE_PAYMENT_METHOD_TYPE,
    getByIdApiPath: GET_FRINGE_PAYMENT_METHOD_TYPE_BY_ID,
    subModuleRef: R_FRINGE_PAYMENT_METHOD_TYPE,
    listFormName: "Fringe Payment Method Type List",
    addFormName: "Add Fringe Payment Method Type"
  },
  "language-type": {
    typeLabel: "Language Type",
    typeKey: "languageType",
    idKey: "languageTypeId",
    getApiPath: GET_ALL_LANGUAGE_TYPE,
    addApiPath: ADD_LANGUAGE_TYPE,
    deleteApiPath: DELETE_LANGUAGE_TYPE,
    updateApiPath: UPDATE_LANGUAGE_TYPE,
    getByIdApiPath: GET_LANGUAGE_TYPE_BY_ID,
    getWithPostMethod: false,
    subModuleRef: R_LANGUAGE_TYPE,
    listFormName: "Language Type List",
    addFormName: "Add Language Type",
    perKey: "LanguageType"
  },
  "lead-source-type": {
    typeLabel: "Lead Source Type",
    typeKey: "leadSourceType",
    idKey: "leadSourceTypeId",
    getApiPath: GET_ALL_LEAD_SOURCE,
    addApiPath: ADD_LEAD_SOURCE,
    deleteApiPath: DELETE_LEAD_SOURCE,
    updateApiPath: UPDATE_LEAD_SOURCE,
    getByIdApiPath: GET_LEAD_SOURCE_BY_ID,
    getWithPostMethod: false,
    subModuleRef: R_LANGUAGE_TYPE,
    listFormName: "Language Type List",
    addFormName: "Add Language Type",
    perKey: "LanguageType"
  },
  "notification-type": {
    typeLabel: "Notification Type",
    typeKey: "notificationType",
    idKey: "notificationTypeId",
    getApiPath: GET_ALL_NOTIFICATION_TYPE,
    addApiPath: ADD_NOTIFICATION_TYPE,
    deleteApiPath: DELETE_NOTIFICATION_TYPE,
    updateApiPath: UPDATE_NOTIFICATION_TYPE,
    getByIdApiPath: GET_NOTIFICATION_TYPE_BY_ID,
    getWithPostMethod: false,
    subModuleRef: R_NOTIFICATION_TYPE,
    listFormName: "Notification Type List",
    addFormName: "Add Notification Type",
    perKey: "NotificationType"
  },
  "work-type": {
    typeLabel: "Work Type",
    typeKey: "workType",
    idKey: "workTypeId",
    getApiPath: GET_ALL_WORK_TYPE,
    addApiPath: ADD_WORK_TYPE,
    deleteApiPath: DELETE_WORK_TYPE,
    updateApiPath: UPDATE_WORK_TYPE,
    getByIdApiPath: GET_WORK_TYPE_BY_ID,
    getWithPostMethod: false,
    subModuleRef: R_WORK_TYPE,
    listFormName: "Work Type List",
    addFormName: "Add Work Type",
    perKey: "WorkType"
  }
};
