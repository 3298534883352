import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import TabGridAndFormLayout from "../../../Components/ProForms/ProFormsLayout/TabGridAndFormLayout";
import ProGrid from "../../../Components/ProGrid/v2";
import NameCell from "../../../Components/ProGrid/components/NameCell";
import DeleteCell from "../../../Components/ProGrid/components/DeleteCell";
import ProSelectField from "../../../Components/Inputs/SelectField";
import ProCheckbox from "../../../Components/Inputs/CheckboxField";
import ProTextInput from "../../../Components/Inputs/TextField";
import { STAUTES } from "../../../../utils/constant";
import { errorToast, successToast } from "../../../../utils/toastHelper";
import usePageState from "../../../../utils/customHooks/usePageState";
import { prepareDefaultValues, prepareInitialConfig, employeeNotesFormName, changeMode } from "../../../../utils/formHelper";
import { GET_ALL_NOTES, GET_ALL_NOTE_TYPES, ADD_EMPLOYEE_NOTE, UPDATE_EMPLOYEE_NOTE, DELETE_EMPLOYEE_NOTE, GET_NOTES } from "../../../../utils/services/apiPath";
import { deleteApi, postApi } from "../../../../utils/services";
import { employeesNotesPermissionSelector } from "../../../store/features/permissions/permissionsSlice";
import usePermission from "../../../../utils/customHooks/usePermission";
import { defaultDropdownConfig } from "../../../../utils/dropdownHelper";
import useColumn from "../../../../utils/customHooks/useColumns";
import { EmployeeNotesFormConfing } from "../EmployeeData";

export default function EmployeeNotes() {
  let { id } = useParams();
  const [showGrid, setShowGrid] = useState(true);
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(EmployeeNotesFormConfing));
  const [employeeNotesData, setEmployeeNotesData] = useState({});
  const [actionData, setActionData] = useState({});
  const [notesTypes, setNotesTypes] = useState([]);
  const [isAddMode, setIsAddMode] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const {
    currentPage,
    pageSize,
    data: notesData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();
  const { "Add Notes": addNotePermission, "Notes List": notesListPermission } = usePermission(
    employeesNotesPermissionSelector,
    employeeNotesFormName
  );

  const columns = useColumn(
    [
      {
        field: "notes",
        headerName: "Notes Name",
        renderCell: params => <NameCell params={params} onClick={onNoteClick} />
      },
      {
        field: "noteType",
        headerName: "Notes Type"
      },
      {
        field: "employeeNoteId",
        headerName: "Actions",
        isDeleteColumn: true,
        disableExport: true,
        width: 80,
        renderCell: params => <DeleteCell params={params} onDelete={onDelete} />
      }
    ],
    notesListPermission.canDelete
  );

  const { handleSubmit, control, setValue } = useForm({ defaultValues: prepareDefaultValues(EmployeeNotesFormConfing) });
  const isEditModeOn = useMemo(() => Object.values(formConfig).some(({ mode }) => mode === "edit"), [formConfig]);

  const onEdit = () => {
    setFormConfig(prev => Object.keys(prev).reduce((acc, key) => ({ ...acc, [key]: { ...prev[key], mode: "edit" } }), {}));
  };
  const handleReadValueclick = name => {
    setFormConfig(prev => ({ ...prev, [name]: { ...prev[name], mode: "edit" } }));
  };

  const resetForm = () => {
    setActionData({});
    setShowGrid(true);
    setFormConfig(changeMode());
  };

  const onCancel = () => {
    if (actionData?.employeeNoteId) {
      setFormConfig(changeMode("read"));
    } else {
      resetForm();
    }
  };

  const onNoteClick = params => {
    setShowGrid(false);
    setActionData(params.row);
    getEmployeeNotesData(params.row);
  };

  const addButtonAction = () => {
    Object.keys(formConfig).forEach(field => setValue(field, null));
    onEdit();
    setShowGrid(false);
    setIsAddMode(true);
  };

  const onSave = async data => {
    if (isSaving) return; 
    setIsSaving(true);
    const payload = {
      noteTypeId: data?.noteType,
      notes: data?.noteName,
      isPrivate: data?.isPrivate || false,
      employeeId: id,
      employeeNoteId: actionData?.employeeNoteId || null
    };
    const { data: employeeNotes, error } = await postApi(isAddMode ? ADD_EMPLOYEE_NOTE : UPDATE_EMPLOYEE_NOTE, payload);
    setIsSaving(false); 
    if (error) return errorToast(error);
    getNotesById(employeeNotes?.guidId || actionData?.employeeNoteId);
    getAllNotes();
    successToast(`Employee Note ${isAddMode ? "Added" : "Updated"} Successfully`);
    setFormConfig(changeMode("read"));
  };

  const getNotesById = async id => {
    const payload = { employeeNoteId: id };
    const { error, data: employeeNotesByID } = await postApi(GET_NOTES, payload);
    if (error) return errorToast(error);
    const data = {
      noteName: employeeNotesByID.notes,
      noteType: employeeNotesByID.noteTypeId,
      isPrivate: employeeNotesByID.isPrivate
    };
    setActionData(employeeNotesByID);
    setEmployeeNotesData(data);
    setIsAddMode(false);
    Object.keys(formConfig).forEach(field => setValue(field, data[field]));
  };

  const onDelete = async id => {
    const { error } = await deleteApi(DELETE_EMPLOYEE_NOTE, { data: [{ employeeNoteId: id }] });
    if (error) return errorToast(error);
    getAllNotes();
    setShowGrid(true);
    successToast("Note deleted successfully.");
  };

  const getEmployeeNotesData = updateData => {
    const data = {
      noteName: updateData.notes,
      noteType: updateData.noteTypeId,
      isPrivate: updateData.isPrivate
    };
    setEmployeeNotesData(data);
    setIsAddMode(false);
    Object.keys(formConfig).forEach(field => setValue(field, data[field]));
  };

  const getAllNotes = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const payload = {
      pageIndex: searchString ? 1 : currentPage + 1,
      pageSize: pageSize,
      searchString: searchString,
      orderByAscending: true,
      orderCol: "notes",
      ...sortModel
    };
    const { data, totalRecords, error } = await postApi(GET_ALL_NOTES, payload, { params: { employeeId: id } });
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]);

  const getAllNoteTypes = async () => {
    const { data } = await postApi(GET_ALL_NOTE_TYPES, defaultDropdownConfig);
    setNotesTypes(data.map(item => ({ label: item.noteType, value: item.noteTypeId })));
  };

  useEffect(() => {
    getAllNotes();
    getAllNoteTypes();
  }, [getAllNotes]);

  const defaultFormProps = { control, formValues: employeeNotesData, isAddMode, handleReadValueclick };

  return (
    <TabGridAndFormLayout
      showGrid={showGrid}
      title={"Notes"}
      backAction={() => resetForm()}
      backLabel="Go back to Notes List"
      beingEdited={isEditModeOn}
      onEdit={onEdit}
      canView={notesListPermission.canView}
      hideEdit={!addNotePermission.canEdit}
      onSave={handleSubmit(onSave)}
      onCancel={onCancel}
      isSaving={isSaving}
    >
      {showGrid && (
        <ProGrid
          title="Notes"
          loading={status === STAUTES.LOADING}
          options={{
            getRowId: row => row.employeeNoteId,
            rowCount: rowCount,
            paginationMode: "server",
            paginationModel: { pageSize: pageSize, page: currentPage },
            onPaginationModelChange: setPagination,
            sortingMode: "server",
            onSortModelChange: setSortModel
          }}
          columns={columns}
          rows={notesData}
          searchMode={!!searchString}
          searchModel={{ defaultValue: searchString, handleDebounce: setSearchString }}
          addButtonLabel="+ Add Note"
          hideExport={!notesListPermission.canExport}
          hideAddButton={!notesListPermission.canAdd}
          addButtonAction={addButtonAction}
        />
      )}
      {!showGrid && (
        <>
          <ProSelectField
            {...defaultFormProps}
            {...formConfig.noteType}
            options={notesTypes}
            permission={addNotePermission.fieldPerObj[formConfig.noteType.perKey]}
            rules={{ required: "Note Type is required!" }}
          />
          <ProTextInput
            {...defaultFormProps}
            {...formConfig.noteName}
            permission={addNotePermission.fieldPerObj[formConfig.noteName.perKey]}
            rules={{ required: "Note Name is required!" }}
          />
          <ProCheckbox
            {...defaultFormProps}
            {...formConfig.isPrivate}
            permission={addNotePermission.fieldPerObj[formConfig.isPrivate.perKey]}
          />
        </>
      )}
    </TabGridAndFormLayout>
  );
}
