import { configureStore } from "@reduxjs/toolkit";
import employeesReducer from "./features/employees/employeesSlice";
import partnersReducer from "./features/partners/partnersSlice";
import authReducer from "./features/auth/authSlice";
import permissionsReducer from "./features/permissions/permissionsSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    employees: employeesReducer,
    partners: partnersReducer,
    permissions: permissionsReducer
  }
});
