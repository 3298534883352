import React from "react";
import PropTypes from "prop-types";
import { fieldTruePermission } from "../../../../utils/formHelper";
import DateField from "../../../Components/Inputs/DateField";
import { Box } from "@mui/material";

const DateRangeSelect = ({ formConfig, fromDate, toDate, control }) => {
  const disableDate = type => date => {
    if (type === "from") return date.isAfter(toDate);
    else return date.isBefore(fromDate);
  };

  return (
    <div className="date-select" style={{ display: "flex" }}>
      <Box sx={{ pr: 2 }}>
        <DateField control={control} {...formConfig.fromDate} shouldDisableDate={disableDate("from")} permission={fieldTruePermission} />
      </Box>
      <DateField control={control} {...formConfig.toDate} shouldDisableDate={disableDate("to")} permission={fieldTruePermission} />
    </div>
  );
};

DateRangeSelect.propTypes = {
  formConfig: PropTypes.shape({
    fromDate: PropTypes.object,
    toDate: PropTypes.object
  }),
  fromDate: PropTypes.object.isRequired,
  toDate: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired
};

export default DateRangeSelect;
