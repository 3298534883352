import React from "react";
import InputWrapper from "./InputWrapper";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ReadOnlyInput from "./ReadOnlyInput";
import { Controller } from "react-hook-form";
import { DEFAULT_DATE_FORMAT, DEFAULT_SIZE } from "../../../utils/constant";
import PropTypes from "prop-types";
export default function DateField({
  size = DEFAULT_SIZE,
  mode,
  isAddMode,
  label,
  control,
  name,
  rules,
  shouldDisableDate,
  fieldProps = {},
  ...rest
}) {
  const { canView = true, canAdd = true, canEdit = true } = rest.permission || {};
  if (!canView || (isAddMode && !canAdd)) return null;
  const editModeAllowed = isAddMode ? canAdd : canEdit;
  if (mode === "read" || (mode === "edit" && !editModeAllowed)) return <ReadOnlyInput name={name} label={label} size={size} type="date" {...rest} />;
  return (
    <InputWrapper size={size}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Controller
          control={control}
          name={name}
          rules={{
            validate: value => {
              if (!value) return true;
              if (value.isValid && !value.isValid()) return "Enter valid date";
            },
            ...(rules || {})
          }}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <DatePicker
              slotProps={{
                textField: {
                  InputLabelProps: { shrink: true },
                  fullWidth: true,
                  error: !!error,
                  helperText: error?.message || ""
                }
              }}
              inputFormat={DEFAULT_DATE_FORMAT}
              onChange={onChange}
              onBlur={onBlur}
              shouldDisableDate={shouldDisableDate}
              value={value}
              label={label}
              {...fieldProps}
            />
          )}
        />
      </LocalizationProvider>
    </InputWrapper>
  );
}

DateField.propTypes = {
  name: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(["read", "edit"]).isRequired,
  label: PropTypes.string.isRequired,
  rules: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,
  isAddMode: PropTypes.bool,
  size: PropTypes.string,
  shouldDisableDate: PropTypes.func,
  fieldProps: PropTypes.object,
  permission: PropTypes.shape({
    canView: PropTypes.bool,
    canAdd: PropTypes.bool,
    canEdit: PropTypes.bool
  })
};
