import { handleLogout } from "../App/store/features/auth/authSlice";
import { BASE_SOCKET_URI } from "./constant";

let ws;
export const connectSocket = dispatch => {
  try {
    const authToken = localStorage.getItem("accessToken");
    const chatbotUrl = `${BASE_SOCKET_URI}?access_token=${authToken}`;
    if (ws) ws.close();
    ws = new WebSocket(chatbotUrl);
    ws.addEventListener("open", () => {
      ws.send('{"protocol": "json","version": 1}');
    });
    ws.addEventListener("message", event => {
      const eventString = event.data.split("")[0];
      const receivedMessage = JSON.parse(eventString);
      if (receivedMessage?.target === "ReceivePermissionUpdate") {
        dispatch(handleLogout());
      }
    });
    ws.addEventListener("close", () => {
      console.log("WebSocket connection closed");
    });
  } catch (error) {
    console.log("Error in connecting to WebSocket", error);
  }
};
