import React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts";
import PropTypes from "prop-types";

const ProjectWPSStatus = ({ wpsStatusData }) => {
  return (
    <BarChart
      height={600}
      series={[
        { data: wpsStatusData?.map(item => item.otjHours), label: "WPS OTJ", color: "#018749" },
        { data: wpsStatusData?.map(item => item.requiredHours), label: "Required Hours", color: "#FF033E" }
      ]}
      margin={{
        left: 70,
        bottom: 150
      }}
      xAxis={[
        {
          scaleType: "band",
          data: wpsStatusData?.map(item => item.wpsTitle),
          labelStyle: {
            transform: "translateY(100px)"
          },
          tickLabelStyle: {
            angle: -45,
            textAnchor: "end",
            fontSize: 12
          }
        }
      ]}
      yAxis={[
        {
          label: "Hours"
        }
      ]}
      sx={{
        [`.${axisClasses.left} .${axisClasses.label}`]: {
          transform: "translate(-30px, 0)"
        }
      }}
    />
  );
};

ProjectWPSStatus.propTypes = {
  wpsStatusData: PropTypes.arrayOf(
    PropTypes.shape({
      otjHours: PropTypes.number,
      requiredHours: PropTypes.number,
      wpsTitle: PropTypes.string
    })
  )
};

export default ProjectWPSStatus;
