import React, { useCallback, useEffect, useMemo, useState } from "react";
import Layout from "../../../Components/Layout";
import { useNavigate, useParams } from "react-router-dom";
import ProTextInput from "../../../Components/Inputs/TextField";
import { useForm } from "react-hook-form";
import { Grid } from "@mui/material";
import SettingFormsLayout from "../../../Components/ProForms/ProFormsLayout/SettingFormsLayout";
import {
  addJobCategoryFormName,
  changeMode,
  changeModeForField,
  checkEditEnabled,
  prepareDefaultValues,
  prepareInitialConfig
} from "../../../../utils/formHelper";
import { ADD_JOB_CATEGORY, GET_JOB_CATEGORY_ID, UPDATE_JOB_CATEGORY } from "../../../../utils/services/apiPath";
import { getApi, postApi } from "../../../../utils/services";
import { STAUTES } from "../../../../utils/constant";
import { errorToast, successToast } from "../../../../utils/toastHelper";
import usePermission from "../../../../utils/customHooks/usePermission";
import { subModPermissionSelector } from "../../../store/features/permissions/permissionsSlice";
import { R_JOB_CATEGORY, R_SETTINGS } from "../../../../utils/permissionReferenceConfig";

export const jobCategoryFormConfig = {
  jobCategoryName: {
    label: "Job Category Name",
    rules: { required: "Job Category Name is required!" },
    perKey: "JobCategoryName"
  },
  jobCategoryDescription: {
    label: "Job Category Description",
    rules: { required: "Job Category Description is required!" },
    perKey: "JobCategoryDescription"
  }
};

export default function JobCategoryForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const isAddMode = useMemo(() => id === "add", [id]);
  const [isSaving, setIsSaving] = useState(false);
  const [status, setStatus] = useState(STAUTES.IDLE);
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(jobCategoryFormConfig, isAddMode ? "edit" : "read"));
  const { handleSubmit, control, setValue } = useForm({ defaultValues: prepareDefaultValues(jobCategoryFormConfig) });
  const { [addJobCategoryFormName]: formPermission } = usePermission(subModPermissionSelector(R_SETTINGS, R_JOB_CATEGORY), addJobCategoryFormName);

  const { canView, canEdit, canAdd, fieldPerObj } = formPermission;
  const [jobCategoryData, setJobCategoryData] = useState({});

  const isEditModeOn = useMemo(() => checkEditEnabled(isAddMode, formConfig), [isAddMode, formConfig]);

  const backAction = () => navigate("/settings/job-category");

  const getDetailById = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const { data, error } = await getApi(`${GET_JOB_CATEGORY_ID}/${id}`);
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setJobCategoryData(data);
    Object.keys(jobCategoryFormConfig).forEach(field => setValue(field, data[field]));
  }, [setValue, id]);

  const onEdit = () => {
    setFormConfig(changeMode("edit"));
  };

  const onCancel = () => {
    if (isAddMode) backAction();
    else setFormConfig(changeMode());
  };

  const handleReadValueclick = name => setFormConfig(changeModeForField(name, "edit"));

  const onSubmit = async payload => {
    if (isSaving) return; 
    setIsSaving(true);
    setStatus(STAUTES.LOADING);
    const { error } = await postApi(isAddMode ? ADD_JOB_CATEGORY : UPDATE_JOB_CATEGORY, { ...jobCategoryData, ...payload });
    setIsSaving(false); 
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    successToast(`Job Category ${isAddMode ? "added" : "updated"} successfully.`);
    backAction();
  };

  useEffect(() => {
    if (!isAddMode) getDetailById(id);
  }, [id, isAddMode, getDetailById]);

  const defaultFormProps = { control, formValues: jobCategoryData, isAddMode, handleReadValueclick };

  return (
    <Layout status={status} canView={canView && !(isAddMode && !canAdd)}>
      <SettingFormsLayout
        backAction={backAction}
        title={`${isAddMode ? "Add" : "Edit"} Job Category`}
        backLabel="Go Back to All Job Category"
        beingEdited={isEditModeOn}
        hideEdit={!canEdit}
        onEdit={onEdit}
        onCancel={onCancel}
        onSave={handleSubmit(onSubmit)}
        isSaving={isSaving}
      >
        <Grid container spacing={2}>
          <ProTextInput {...defaultFormProps} {...formConfig.jobCategoryName} permission={fieldPerObj[formConfig.jobCategoryName.perKey]} />
          <ProTextInput
            {...defaultFormProps}
            {...formConfig.jobCategoryDescription}
            permission={fieldPerObj[formConfig.jobCategoryDescription.perKey]}
          />
        </Grid>
      </SettingFormsLayout>
    </Layout>
  );
}
