import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import ProButton from "../ProButton";
import PropTypes from "prop-types";

export default function DialogModal({ show, title, message, onConfirm, onCancel, confirmBtnText, cancelBtnText, showCancel = true }) {
  return (
    <Dialog sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }} maxWidth="xs" open={show}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>{message}</DialogContent>
      <DialogActions>
        {showCancel && (
          <ProButton variant="outlined" color="secondary" autoFocus onClick={onCancel}>
            {cancelBtnText || "Cancel"}
          </ProButton>
        )}
        <ProButton onClick={onConfirm}>{confirmBtnText || "Ok"}</ProButton>
      </DialogActions>
    </Dialog>
  );
}

DialogModal.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.node.isRequired,
  message: PropTypes.node.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  confirmBtnText: PropTypes.string,
  cancelBtnText: PropTypes.string,
  showCancel: PropTypes.bool
};
