import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import FormGridInsideSectionLayout from "../ProForms/ProFormsLayout/FormGridInsideSectionLayout.jsx";
import ProGrid from "../ProGrid/v2";
import { STAUTES } from "../../../utils/constant";
import usePageState from "../../../utils/customHooks/usePageState";
import { errorToast, successToast } from "../../../utils/toastHelper.js";
import ProTextInput from "../Inputs/TextField";
import {changeMode, changeModeForField, checkEditEnabled, convertFileToBase64, prepareDefaultValues, prepareInitialConfig } from "../../../utils/formHelper";
import ProFileUploadField from "../Inputs/FileUploadField";
import DeleteCell from "../ProGrid/components/DeleteCell";
import useColumn from "../../../utils/customHooks/useColumns";
import ProSelectField from "../Inputs/SelectField.jsx";
import { deleteApi, postApi } from "../../../utils/services/index.js";
import {
  DELETE_PARTNERS_CONTACT_DOCUMENT,
  GET_ALL_PARTNERS_CONTACTS_DOCUMENTS,
  UPDATE_PARTNERS_CONTACT_DOCUMENT,
  ADD_PARTNERS_CONTACT_DOCUMENT,
  GET_ALL_DOCUMENTS_TYPES
} from "../../../utils/services/apiPath.js";
import { defaultDropdownConfig } from "../../../utils/dropdownHelper.js";
import { dateColumnDef } from "../ProGrid/components/FormatCell.jsx";
import usePermission from "../../../utils/customHooks/usePermission.js";
import { partnersContactPermissionSelector } from "../../store/features/permissions/permissionsSlice.js";
import PropTypes from "prop-types";

export const partnerDocumentFormConfing = {
  documentName: {
    label: "Document Name",
    perKey: "DocumentName",
    rules: { required: "Document Name is required!" }
  },
  clientDocumentTypeId: {
    label: "Document Type",
    perKey: "DocumentType",
    rules: { required: "Document Type is required!" }
  },
  documentFile: {
    label: "Document",
    perKey: "FileInput",
    rules: { required: "Document is required!" }
  }
};

export default function PartnerContactDocument({ clientContactId, clientId, globalEditMode }) {
  const [actionData, setActionData] = useState({});
  const [documentTypesData, setDocumentTypesData] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const { "Partner Contact Documents": partnerContactDocumentPermission } = usePermission(partnersContactPermissionSelector, [
    "Partner Contact Documents"
  ]);
  const { canDelete, canAdd, fieldPerObj } = partnerContactDocumentPermission;

  const { data: documentData, status, setPageState, setStatus } = usePageState();
  const { handleSubmit, control, setValue } = useForm({ defaultValues: prepareDefaultValues(partnerDocumentFormConfing) });
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(partnerDocumentFormConfing));

  const isEditModeOn = useMemo(() => checkEditEnabled(false, formConfig), [formConfig]);

  const getContactDocuments = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const { data, error } = await postApi(
      GET_ALL_PARTNERS_CONTACTS_DOCUMENTS,
      {
        pageIndex: 0,
        pageSize: 100,
        orderByAscending: true,
        searchString: "",
        orderCol: "documentName"
      },
      { params: { clientContactId } }
    );
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [] }));
  }, [clientContactId, setPageState]);

  const handleReadValueclick = name => setFormConfig(changeModeForField(name, "edit"));

  const onEdit = () => setFormConfig(changeMode("edit"));

  const resetForm = keepMode => {
    setActionData({});
    Object.keys(formConfig).forEach(field => setValue(field, null));
    setValue("documentName", "");
    if (!keepMode) setFormConfig(changeMode("read"));
  };

  const onSave = async data => {
    if (isSaving) return; 
    setIsSaving(true);
    const { documentFile, ...rest } = data;
    const base64ProfilePicture = await convertFileToBase64(documentFile);
    const payload = { ...rest, clientContactId, clientId, documentMediaModel: null };
    if (base64ProfilePicture) payload.documentMediaModel = { base64String: base64ProfilePicture, fileName: documentFile.name };
    if (actionData.clientContactDocumentId) payload.clientContactDocumentId = actionData.clientContactDocumentId;
    const { error } = await postApi(actionData.clientContactDocumentId ? UPDATE_PARTNERS_CONTACT_DOCUMENT : ADD_PARTNERS_CONTACT_DOCUMENT, payload);
    setIsSaving(false); 
    if (error) return errorToast(error);
    resetForm(true);
    getContactDocuments();
    successToast(`Contact Document ${actionData.clientContactDocumentId ? "Updated" : "Added"} Successfully`);
  };

  const onDelete = async id => {
    const { error } = await deleteApi(DELETE_PARTNERS_CONTACT_DOCUMENT, { data: { clientContactDocumentId: id } });
    if (error) return errorToast(error);
    getContactDocuments();
    successToast("Contact Document deleted successfully.");
  };

  const getAllDocumentType = async () => {
    const { data } = await postApi(GET_ALL_DOCUMENTS_TYPES, defaultDropdownConfig);
    setDocumentTypesData(data.map(item => ({ label: item.clientDocumentType, value: item.clientDocumentTypeId })));
  };

  useEffect(() => {
    getContactDocuments();
    getAllDocumentType();
  }, [getContactDocuments]);

  useEffect(() => {
    if (globalEditMode) onEdit();
    else resetForm();
  }, [globalEditMode]);

  const columns = useColumn(
    [
      {
        field: "documentName",
        headerName: "Document Name"
      },
      {
        field: "clientDocumentType",
        headerName: "Document Type"
      },
      {
        field: "createdByName",
        headerName: "Uploaded By"
      },
      dateColumnDef({
        field: "createdDate",
        headerName: "Uploaded Date"
      }),
      {
        field: "clientContactDocumentId",
        headerName: "Actions",
        isDeleteColumn: true,
        disableExport: true,
        width: 80,
        renderCell: params => <DeleteCell title={"Delete Contact Documents"} params={params} onDelete={onDelete} />
      }
    ],
    globalEditMode && canDelete
  );

  const defaultFormProps = { control, formValues: {}, handleReadValueclick, isAddMode: true };

  return (
    <div className="proFormGridInsideSectionLayout-wrapper">
      <FormGridInsideSectionLayout
        showGrid={true}
        saveLabel="Upload"
        cancelLabel="Reset"
        beingEdited={isEditModeOn && canAdd}
        onEdit={onEdit}
        onCancel={() => resetForm(true)}
        onSave={handleSubmit(onSave)}
        isSaving={isSaving}
      >
        <Grid container spacing={2}>
          <ProTextInput
            {...defaultFormProps}
            {...formConfig.documentName}
            permission={{ ...fieldPerObj[formConfig.documentName.perKey], canEdit: false }}
          />
          <ProSelectField
            {...defaultFormProps}
            {...formConfig.clientDocumentTypeId}
            options={documentTypesData}
            permission={{ ...fieldPerObj[formConfig.clientDocumentTypeId.perKey], canEdit: false }}
          />
          <ProFileUploadField
            {...defaultFormProps}
            {...formConfig.documentFile}
            inputProps={{ accept: "file/*" }}
            permission={{ ...fieldPerObj[formConfig.documentFile.perKey], canEdit: false }}
          />
        </Grid>
      </FormGridInsideSectionLayout>
      <ProGrid
        loading={status === STAUTES.LOADING}
        options={{
          getRowId: row => row.clientContactDocumentId,
          pagination: false,
          autoHeight: true
        }}
        columns={columns}
        hideExport
        hideSearch={true}
        hideIfNoData={true}
        hideAddButton={true}
        rows={documentData || []}
      />
    </div>
  );
}

PartnerContactDocument.propTypes = {
  clientContactId: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  globalEditMode: PropTypes.bool.isRequired,
};