import React from "react";
import ReadOnlyInput from "./ReadOnlyInput";
import InputWrapper from "./InputWrapper";
import { InputAdornment, TextField } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { Controller } from "react-hook-form";
import { DEFAULT_SIZE } from "../../../utils/constant";
import PropTypes from "prop-types";

export default function ProSSNField({
  name,
  mode,
  label,
  rules,
  control,
  isAddMode,
  fieldProps = {},
  size = DEFAULT_SIZE,
  startAdornment = null,
  ...rest
}) {
  const { canView = false, canAdd = false, canEdit = false } = rest.permission || {};
  if (!canView || (isAddMode && !canAdd)) return null;
  const editModeAllowed = isAddMode ? canAdd : canEdit;
  if (mode === "read" || (mode === "edit" && !editModeAllowed)) return <ReadOnlyInput name={name} label={label} size={size} type="ssn" {...rest} />;
  return (
    <InputWrapper size={size}>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <TextField
            variant="outlined"
            fullWidth
            value={`XXXX-XX-${value ?? ""}`}
            onChange={e => onChange(e.target.value.replace(/\D/g, ""))}
            onBlur={onBlur}
            label={label}
            placeholder={label}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
              inputProps: {
                maxlength: 12
              }
            }}
            InputLabelProps={{ shrink: true }}
            helperText={error?.message || null}
            error={!!error}
            {...fieldProps}
          />
        )}
      />
    </InputWrapper>
  );
}

ProSSNField.propTypes = {
  name: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(["read", "edit"]).isRequired,
  label: PropTypes.string.isRequired,
  rules: PropTypes.object.isRequired,
  startAdornment: PropTypes.node,
  control: PropTypes.object.isRequired,
  isAddMode: PropTypes.bool,
  fieldProps: PropTypes.object,
  permission: PropTypes.shape({
    canView: PropTypes.bool,
    canAdd: PropTypes.bool,
    canEdit: PropTypes.bool
  }),
  formValues: PropTypes.object,
  size: PropTypes.string,
  handleReadValueclick: PropTypes.func
};
