import React, { useEffect, useMemo, useRef, useState } from "react";
import "./Header.scss";
import logo from "../../../Assets/Icons/logo.png";
import logoWhite from "../../../Assets/Icons/logo-white.png";
import lightTheme from "../../../Assets/Icons/light.png";
import darkTheme from "../../../Assets/Icons/dark.png";
import menuopen from "../../../Assets/Icons/menu-open.png";
import menuClose from "../../../Assets/Icons/menu-close.png";
import NotificationIcon from "../../../Assets/IconComponents/Notification";
import SubHeader from "../../SubHeader/SubHeader";
import { useNavigate } from "react-router";
import { useTheme } from "../../../ContextStore/ThemeContext";
import { useDispatch, useSelector } from "react-redux";
import { handleLogout } from "../../../store/features/auth/authSlice";
import { allPermissionSelector, topNavigationPermissionSelector } from "../../../store/features/permissions/permissionsSlice";
import { headerPermissionTransformer } from "../../../store/DataTransformer/permissionTransformer";
import { Avatar, IconButton, Popover, Tooltip } from "@mui/material";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
import ChevronRightTwoToneIcon from "@mui/icons-material/ChevronRightTwoTone";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import VideoPopOver from "./VideoPopOver";
import { getApi } from "../../../../utils/services";
import { GET_ALL_VIDEOS } from "../../../../utils/services/apiPath";
import { v4 as uuidV4 } from "uuid";
import { R_TOP_NAVIGATION_DARK_MODE, R_TOP_NAVIGATION_NOTIFICATIONS, R_TOP_NAVIGATION_VIDEO } from "../../../../utils/permissionReferenceConfig";
import PropTypes from "prop-types";

const MyAccountPopOver = ({ userData, popOverOpen, setPopOverOpen, manageAccount, onLogout, anchorEl }) => {
  return (
    <Popover
      disableScrollLock
      anchorEl={anchorEl}
      onClose={() => setPopOverOpen(false)}
      open={popOverOpen}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <div className="header_user_popover">
        <div className="header_user_popover_profile">
          <Avatar src={userData?.clientUserProfilePicture} alt="user-icon" />
          <div className="header_user_popover_profile_info">
            <div className="header_user_popover_profile_info_name">
              {userData.firstName} {userData.lastName}
            </div>
            <div className="header_user_popover_profile_info_email">{userData.email}</div>
            <div className="header_user_popover_profile_info_email">{userData?.companyName}</div>
          </div>
        </div>

        <button className="header_user_popover_myAccount" onClick={manageAccount} style={{ border: "none", background: "none" }}>
          My Account
          <ChevronRightTwoToneIcon />
        </button>
        <button className="header_user_popover_logout" onClick={onLogout} style={{ border: "none", background: "none" }}>
          <LockOpenTwoToneIcon sx={{ mr: 1 }} /> Sign Out
        </button>
      </div>
    </Popover>
  );
};

const Header = () => {
  const navigate = useNavigate();
  const { theme, toggleTheme } = useTheme();
  const [showMenu, setShowMenu] = useState(false);
  const [showVideoPopOver, setShowVideoPopOver] = useState(false);
  const [popOverOpen, setPopOverOpen] = useState(false);
  const [videos, setVideos] = useState([]);

  const accountButtonRef = useRef(null);
  const videoButtonRef = useRef(null);
  const [userData] = useState(JSON.parse(localStorage.getItem("user")));

  const dispatch = useDispatch();
  const { permissions } = useSelector(allPermissionSelector);
  const { subModulePermissions = {} } = useSelector(topNavigationPermissionSelector);
  const {
    [R_TOP_NAVIGATION_DARK_MODE]: darkModePermission,
    [R_TOP_NAVIGATION_NOTIFICATIONS]: notificationPermission,
    [R_TOP_NAVIGATION_VIDEO]: videoPermission
  } = subModulePermissions;
  const accesibleTabs = useMemo(() => headerPermissionTransformer(permissions), [permissions]);

  const handleTabClick = tab => navigate(`${tab.to}`);
  const handleMenuOpen = () => setShowMenu(status => !status);
  const handleHome = () => navigate("/dashboard");
  const onLogout = () => dispatch(handleLogout());

  const manageAccount = () => {
    setPopOverOpen(false);
    navigate("/myaccount");
  };

  const getVideos = async () => {
    const { error, data } = await getApi(GET_ALL_VIDEOS);
    if (error) console.log(error);
    setVideos(data.map(item => ({ ...item, id: uuidV4() })));
  };

  useEffect(() => {
    getVideos();
  }, []);

  const isLightTheme = theme === "light";

  return (
    <div className="header">
      <div className="header_main">
        <div className="header_container">
          <div className="header_logo">
            <div className="header_logo_menu-open">
              <IconButton onClick={handleMenuOpen}>
                <img src={showMenu ? menuClose : menuopen} alt="menu-open" />
              </IconButton>
            </div>
            <IconButton onClick={handleHome} sx={{ padding: 0 }}>
              <img src={isLightTheme ? logo : logoWhite} alt="logo" />
            </IconButton>
          </div>

          <div className="header_user">
            {videoPermission.canView && (
              <div className="header_user_sec" ref={videoButtonRef}>
                <Tooltip placement="bottom" title="Video" arrow>
                  <IconButton onClick={() => setShowVideoPopOver(true)}>
                    <OndemandVideoIcon />
                  </IconButton>
                </Tooltip>
              </div>
            )}
            <VideoPopOver anchorEl={videoButtonRef.current} open={showVideoPopOver} videos={videos} onClose={() => setShowVideoPopOver(false)} />
            {darkModePermission.canView && (
              <div className="header_user_sec">
                <Tooltip placement="bottom" title="Theme Switcher" arrow>
                  <IconButton onClick={toggleTheme}>
                    <img src={isLightTheme ? darkTheme : lightTheme} alt="mode-icon" />
                  </IconButton>
                </Tooltip>
              </div>
            )}
            {notificationPermission.canView && (
              <div className="header_user_sec">
                <Tooltip placement="bottom" title="Notifications" arrow>
                  <IconButton>
                    <NotificationIcon />
                  </IconButton>
                </Tooltip>
              </div>
            )}
            <div className="header_user_sec" ref={accountButtonRef}>
              <IconButton onClick={setPopOverOpen}>
                <Avatar className="avatarProfile" src={userData.clientUserProfilePicture} alt="user-icon" />
              </IconButton>
            </div>
            <MyAccountPopOver
              userData={userData}
              popOverOpen={popOverOpen}
              setPopOverOpen={setPopOverOpen}
              manageAccount={manageAccount}
              onLogout={onLogout}
              anchorEl={accountButtonRef.current}
            />
          </div>
        </div>
      </div>
      <div className={`header_subheader ${showMenu && "open"}`}>
        <div className="header_subheader_main">{<SubHeader tabs={accesibleTabs} onTabClick={handleTabClick} />}</div>
      </div>
    </div>
  );
};

MyAccountPopOver.propTypes = {
  userData: PropTypes.shape({
    clientUserProfilePicture: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    companyName: PropTypes.string
  }).isRequired,
  popOverOpen: PropTypes.bool.isRequired,
  setPopOverOpen: PropTypes.func.isRequired,
  manageAccount: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  anchorEl: PropTypes.object
};
export default Header;
