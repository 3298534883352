import React from "react";
import "./CradsTopSection.scss";
import PropTypes from "prop-types";

const CradsTopSection = ({ icon, title, value, color, iconComp }) => {
  return (
    <div className="dashboard-top" style={{ backgroundColor: color }}>
      <div className="dashboard-top_left">{iconComp || <img src={icon} alt={title} />}</div>
      <div className="dashboard-top_right">
        <h5>{title}</h5>
        <h2>{value}</h2>
      </div>
    </div>
  );
};

export default CradsTopSection;

CradsTopSection.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string,
  value: PropTypes.number.isRequired,
  color: PropTypes.string,
  iconComp: PropTypes.any
};
